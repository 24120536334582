import { isFileType } from "./typeGuard";

export interface ISelectOption<
  TValue = string | string[],
  TLabel = string | null
> {
  value: TValue;
  label: TLabel;
}

export interface ISignUpFormData {
  email: string;
  password: string;
}

export interface ISignInFormData {
  email: string;
  userExists?: boolean;
}

export interface IResetPasswordEmail {
  email: string;
}

export interface IResetPasswordData {
  new_password: string;
  oobCode: string;
}

export interface ImageType extends File {
  dataUrl?: string;
}

export interface IFileInputComponentProps {
  image: ImageType | string | null;
  onRemove: () => void;
  error?: string[] | string;
  label?: string;
  classNames?: {
    label?: string;
    error?: string;
  };
  className?: string;
  readOnly?: boolean;
  groupStyle?: boolean;
  motivationalStyle?: boolean;
  title?: string;
  required?: boolean;
  userName?: string;
  disabled?: boolean;
  fieldName: string;
  touched: boolean;
  onImageUpload: () => void;
}

// ImageType type guard
export function isImageFileType(data: ImageType): data is ImageType {
  return isFileType(data) && data?.dataUrl !== undefined;
}
