import { Formik } from "formik";
import { memo, MutableRefObject, useCallback, useRef } from "react";

import validationSchema from "./GroupForm.schema";
import { useInitialState, useSubmitHandler } from "./GroupForm.hooks";
import GroupForm from "./GroupForm.component";
import { IManagementProps } from "./GroupForm.types";
import { InitActionGroup } from "./GroupForm.constants";

interface IProps extends IManagementProps {
  className?: string;
  groupFormRef: MutableRefObject<boolean>;
  onSuccess: () => void;
  onClose: () => void;
}

function GroupFormContainer({
  className,
  onSuccess,
  onClose,
  showIssueFields = true,
  showRatings = true,
  groupFormRef,
}: IProps) {
  const similarRef = useRef<boolean>(false);

  const onCloseCallback = useCallback(
    (id?: string) => {
      similarRef.current = false;
      groupFormRef.current = false;
      if (onClose) {
        onClose();
      }
    },
    [groupFormRef, onClose]
  );

  return (
    <Formik<Partial<InitActionGroup>>
      enableReinitialize
      initialValues={useInitialState()}
      validationSchema={validationSchema()}
      onSubmit={useSubmitHandler(onSuccess, {
        showIssueFields,
        showRatings,
        proceedWithSimilar: similarRef.current,
        proceedWithSuggestions: groupFormRef.current,
      })}
    >
      <GroupForm
        className={className}
        onClose={onCloseCallback}
        similarRef={similarRef}
        suggestionsRef={groupFormRef}
        showIssueFields={showIssueFields}
        showRatings={showRatings}
      />
    </Formik>
  );
}

export default memo(GroupFormContainer);
