import { Form, useFormikContext } from "formik";
import classnames from "classnames";

import {
  BorderBadge,
  BorderBadgeRound,
  Button,
  Card,
  FormikInput,
  ProfileBlock,
} from "components";

import { IManagementProps } from "./SolutionForm.types";
import { FIELD_NAMES, SolutionInitial } from "./SolutionForm.constants";
import FormikTextarea from "components/FormikTextarea";

import { MAX_TEXT_LENGTH, MAX_TITLE_LENGTH } from "./SolutionForm.schema";

import Attachments from "modules/Attachments/Attachments";
import FormikTags from "components/FormikTags/FormikTags";
import { useQueries, useQueryClient } from "react-query";
import { MutableRefObject, useEffect, useMemo, useRef, useState } from "react";
import { IAISimilar, IAISuggestions, IFeedbackArea } from "types";
import { twMerge } from "tailwind-merge";
import { APP_ROUTES, CONTENT_QUALITY_LEVELS } from "const";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { ReactComponent as CarouselArrowLeft } from "assets/images/carousel_arrow_left.svg";
import { ReactComponent as CarouselArrowRight } from "assets/images/carousel_arrow_right.svg";
import { AISimilarityLeave } from "components/AISimilarityLeave";
import axios from "axios";
import { findSolution } from "services";
import { useMe, useWindowDimensions } from "hooks";
import { AISimilarityModal } from "components/AISimilarityModal";
import { ReactComponent as RatingStar } from "assets/images/rating-star.svg";
import { isMobile, isTablet } from "react-device-detect";

function Item({ title, content }: { title: string; content: React.ReactNode }) {
  return (
    <>
      <span className=" font-light leading-[30px] lg:text-right text-sm text-[#999999]">
        {title}
      </span>
      <span className=" font-normal leading-[30px] text-[#00000099] flex align-center">
        {content}
      </span>
    </>
  );
}

interface AuthenticationPanelProps extends IManagementProps {
  className?: string;
  similarRef: MutableRefObject<boolean>;
  suggestionsRef: MutableRefObject<boolean>;
  onClose: () => void;
}

function SolutionForm(props: AuthenticationPanelProps) {
  const params = useParams<{ id: string }>();
  const location = useLocation();
  const { errors, values, isSubmitting, submitCount, submitForm } =
    useFormikContext<SolutionInitial>();
  const { data: meData } = useMe();
  const queryClient = useQueryClient();
  const allMutations = queryClient
    .getMutationCache()
    .findAll({ mutationKey: ["createAIAnalyticsSolution"] });

  const similarSolutionMutation = queryClient
    .getMutationCache()
    .findAll({ mutationKey: ["createAISimilarSolution"] });

  const [showSimilarityModal, setShowSimilarityModal] = useState(false);
  const [similarityModalInteracted, setSimilarityModalInteracted] =
    useState(false);

  const latestAIMutation = useMemo(
    () =>
      allMutations && allMutations.length > 0
        ? //@ts-ignore
          allMutations[allMutations.length - 1]?.state?.data?.data
        : //@ts-ignore
          allMutations[0]?.state?.data?.data,
    [allMutations]
  ) as IAISuggestions | undefined;

  const latestSimilarAIMutation = useMemo(
    () =>
      similarSolutionMutation && similarSolutionMutation.length > 0
        ? //@ts-ignore
          similarSolutionMutation[similarSolutionMutation.length - 1]?.state
            ?.data?.data
        : //@ts-ignore
          similarSolutionMutation[0]?.state?.data?.data,
    [similarSolutionMutation]
  ) as IAISimilar | undefined;

  const moreFeedback = latestAIMutation?.more_feedback?.quality;
  const defaultValueForProblemAreaWithMoreFeedback: IFeedbackArea = {
    input_name: "",
    quality: "Poor" as CONTENT_QUALITY_LEVELS,
    user_input: "",
    feedback: "",
    suggested_texts: [],
  };

  const similarMutationRef = useRef<IAISimilar | undefined>(undefined);
  const submitCountRef = useRef<number>(0);
  const updateScenario = useMemo(
    () => !!params.id && location.pathname.includes(APP_ROUTES.SOLUTION),
    [location.pathname, params.id]
  );

  useEffect(() => {
    if (!updateScenario && submitCount > submitCountRef.current) {
      submitCountRef.current = submitCount;
      setSimilarityModalInteracted(false);
    }
  }, [submitCount, updateScenario]);

  useEffect(() => {
    if (
      !updateScenario &&
      !props.similarRef.current &&
      !similarityModalInteracted &&
      latestSimilarAIMutation &&
      latestSimilarAIMutation.potential_similarity_id.length > 0 &&
      !showSimilarityModal
    ) {
      similarMutationRef.current = latestSimilarAIMutation;
      props.similarRef.current = true;
      props.suggestionsRef.current = true;
      setShowSimilarityModal(true);
    }
  }, [
    updateScenario,
    latestSimilarAIMutation,
    props.similarRef,
    showSimilarityModal,
    similarityModalInteracted,
    submitCount,
    props.suggestionsRef,
  ]);

  const solutionQueryIds =
    latestSimilarAIMutation?.potential_similarity_id || [];

  const solutionsQueries = useQueries({
    queries: solutionQueryIds?.map((id) => {
      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();
      return {
        queryKey: ["similarSolution", id],
        queryFn: () => findSolution({ id: id, source: source }),
      };
    }),
  });

  const mappedSolutions = solutionsQueries
    ?.filter((query) => (query?.data?.data as unknown as string) !== "")
    .map((query) => {
      return query.data?.data || undefined;
    });

  const [showLeaveModal, setShowLeaveModal] = useState(false);

  const navigate = useNavigate();
  const dimensions = useWindowDimensions();
  const [currentSelectedSolutionId, setcurrentSelectedSolutionId] = useState<
    string | undefined
  >(undefined);
  return (
    <>
      <Form className=" flex flex-col flex-1 six-sixth-step">
        <div className="flex gap-2 flex-col px-4 sm:px-20 flex-1 ">
          {props.showSolutionFields && (
            <>
              <FormikInput
                disabled={props.suggestionsRef.current}
                checkAIContentQuality={!!latestAIMutation}
                AISuggestionProblemArea={
                  latestAIMutation?.feedback_areas?.find(
                    (problemArea) =>
                      problemArea.input_name.toLocaleLowerCase() === "title"
                  ) ||
                  (moreFeedback
                    ? {
                        ...defaultValueForProblemAreaWithMoreFeedback,
                        input_name: FIELD_NAMES.TITLE,
                      }
                    : undefined)
                }
                AISuggestionFieldName={FIELD_NAMES.SUGGESTED_TITLE}
                autoComplete="nope"
                label="Title"
                name={FIELD_NAMES.TITLE}
                placeholder="Title"
                message={
                  <div className="flex justify-between text-gray-400  font-light text-sm">
                    <span>Please add a descriptive title</span>
                    <span
                      className={classnames({
                        "text-red-400": errors?.title === "max",
                      })}
                    >{`${values?.title?.length}/${MAX_TITLE_LENGTH}`}</span>
                  </div>
                }
              />

              <FormikTextarea
                disabled={props.suggestionsRef.current}
                checkAIContentQuality={!!latestAIMutation}
                AISuggestionProblemArea={
                  latestAIMutation?.feedback_areas?.find(
                    (problemArea) =>
                      problemArea.input_name.toLocaleLowerCase() === "details"
                  ) ||
                  (moreFeedback
                    ? {
                        ...defaultValueForProblemAreaWithMoreFeedback,
                        input_name: FIELD_NAMES.DESCRIPTION,
                      }
                    : undefined)
                }
                AISuggestionFieldName={FIELD_NAMES.SUGGESTED_DESCRIPTION}
                autoComplete="nope"
                label="Details"
                name={FIELD_NAMES.DESCRIPTION}
                placeholder="Details"
                className="mt-1"
                showOnlyCustomMessage={errors?.description === "max"}
                message={
                  <div className="flex justify-between text-gray-400  font-light text-sm">
                    <span>Please add some more details</span>
                    <span
                      className={classnames({
                        "text-red-400": errors?.description === "max",
                      })}
                    >{`${values?.description?.length}/${MAX_TEXT_LENGTH}`}</span>
                  </div>
                }
              />

              <FormikTextarea
                disabled={props.suggestionsRef.current}
                checkAIContentQuality={!!latestAIMutation}
                AISuggestionProblemArea={
                  latestAIMutation?.feedback_areas?.find(
                    (problemArea) =>
                      problemArea.input_name.toLocaleLowerCase() ===
                      "effectiveness"
                  ) ||
                  (moreFeedback
                    ? {
                        ...defaultValueForProblemAreaWithMoreFeedback,
                        input_name: FIELD_NAMES.EFFECTIVENESS,
                      }
                    : undefined)
                }
                AISuggestionFieldName={FIELD_NAMES.SUGGESTED_EFFECTIVENESS}
                autoComplete="nope"
                label="Effectiveness"
                name={FIELD_NAMES.EFFECTIVENESS}
                placeholder="Effectiveness"
                className="mt-1"
                showOnlyCustomMessage={errors?.effectiveness === "max"}
                message={
                  <div className="flex justify-between text-gray-400  font-light text-sm">
                    <span>Why will this solution be effective?</span>
                    <span
                      className={classnames({
                        "text-red-400": errors?.effectiveness === "max",
                      })}
                    >{`${values?.effectiveness?.length}/${MAX_TEXT_LENGTH}`}</span>
                  </div>
                }
              />
            </>
          )}
          <Attachments disabled={props.suggestionsRef.current} />
          <FormikTags
            disabled={props.suggestionsRef.current}
            fieldName={FIELD_NAMES.TAGS}
            label="Labels (Optional)"
          />
        </div>

        {
          //@ts-ignore
          !!errors?.validator! && (
            <div className="w-full px-12">
              <p className=" text-red-400 text-sm font-normal">
                {errors?.validator as string}
              </p>
            </div>
          )
        }
        <div className="border-b border-gray-200 w-full h-[1px] mt-10" />
        <div className="mt-4 flex gap-4 justify-end px-4 sm:px-20">
          <Button
            isReverse
            type="button"
            className=" justify-center border"
            disabled={isSubmitting}
            onClick={props.onClose}
          >
            Cancel
          </Button>
          <button
            type="submit"
            disabled={isSubmitting || !!errors?.validator}
            className={twMerge(
              classnames(
                "w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-3 bg-quality-content-green text-base font-medium text-white hover:bg-quality-content-green focus:outline-none  sm:ml-3 sm:w-auto sm:text-sm"
              )
            )}
          >
            Review and Save
          </button>
        </div>
      </Form>
      {showSimilarityModal && (
        <AISimilarityModal
          showCloseIcon={false}
          setShow={(val) => {
            setSimilarityModalInteracted(true);
            setShowSimilarityModal(val);
            props.similarRef.current = true;
          }}
          header={
            <div className="flex flex-col items-start gap-2 py-4  ">
              <p
                className={twMerge(
                  classnames("font-semibold  leading-0", {
                    "text-2xl":
                      !isMobile && !isTablet && !(dimensions.width < 641),
                    "text-lg": isMobile || isTablet || dimensions.width < 641,
                  })
                )}
              >
                We found a potentially similar post. Is this the same solution?
              </p>
              {(isMobile || isTablet || dimensions.width < 641) && (
                <button
                  type="button"
                  onClick={() => {
                    submitForm();
                  }}
                  disabled={isSubmitting || !!errors?.validator}
                  className={twMerge(
                    classnames(
                      "w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-3 bg-quality-content-green text-base font-medium text-white hover:bg-quality-content-green focus:outline-none  sm:ml-3 sm:w-auto sm:text-sm"
                    )
                  )}
                >
                  Publish My Solution Anyway
                </button>
              )}
            </div>
          }
          content={
            <div className="  flex flex-row gap-4 relative w-full">
              <div
                className={twMerge(
                  classnames("z-[1] absolute right-0 w-[75px] h-full ", {
                    "bg-[linear-gradient(270deg,_#fff,_#fff_42%,_#0083f500)]":
                      mappedSolutions?.length > 1 &&
                      !isMobile &&
                      !isTablet &&
                      !(dimensions.width < 641),
                  })
                )}
              />
              {mappedSolutions?.length > 0 ? (
                <>
                  {isMobile || isTablet || dimensions.width < 641 ? (
                    <>
                      <div className="flex flex-col gap-2 max-h-[350px] overflow-y-scroll">
                        {mappedSolutions?.map((solution) => {
                          const date =
                            solution?.createdAt &&
                            new Date(solution?.createdAt).toLocaleDateString(
                              "en-GB",
                              {
                                year: "numeric",
                                month: "long",
                                day: "2-digit",
                              }
                            );

                          const formattedAddress =
                            solution?.issue?.location?.formattedAddress;

                          const isSoftDeleted = !!solution?.deletedAt;
                          return (
                            <>
                              <Card
                                shouldHover={false}
                                className={classnames(
                                  "flex-1 xs:max-w-[480px] sm:max-w-[616px] justify-between z-40 border border-gray-500",

                                  {
                                    "bg-gray-100 pointer-events-none select-none":
                                      isSoftDeleted,
                                  }
                                )}
                                header={
                                  <div className="flex justify-between items-center pb-1">
                                    <div className="grid grid-flow-row gap-x-2 items-center max-h-[70px] mr-2">
                                      <span className="font-bold leading-6 text-lg line-clamp-2">
                                        {solution?.title}
                                      </span>
                                      <span className="leading-6 text-sm  text-gray-400 line-clamp-1 font-light">
                                        {formattedAddress}
                                      </span>
                                    </div>
                                  </div>
                                }
                                classNames={{
                                  header:
                                    "px-4 sm:px-9  flex-1 sm:max-w-[616px]",
                                  content:
                                    "px-4 sm:px-9  flex-1 sm:max-w-[616px]",
                                  footer:
                                    "px-3 sm:px-9 max-h-[60px] flex-1 sm:max-w-[616px]",
                                }}
                                content={
                                  <div className="grid grid-cols-[1fr] lg:grid-cols-[auto_1fr] gap-y-2 gap-x-6 text-left">
                                    <Item
                                      title="Details"
                                      content={solution?.description}
                                    />
                                    <Item
                                      title="Effectiveness"
                                      content={solution?.effectiveness}
                                    />
                                    <Item
                                      title="Skills Required"
                                      content={
                                        <div
                                          className={classnames(
                                            "flex items-start flex-wrap  gap-4 ",
                                            {
                                              " pt-1 ": !(
                                                (solution?.primaryActionGroup
                                                  ?.skills &&
                                                  solution?.primaryActionGroup
                                                    ?.skills.length < 1) ||
                                                !solution?.primaryActionGroup
                                                  ?.skills
                                              ),
                                            }
                                          )}
                                        >
                                          {solution?.primaryActionGroup?.skills?.map(
                                            (skill) => {
                                              return (
                                                <BorderBadgeRound
                                                  key={skill.id}
                                                  className="flex"
                                                  withNotification
                                                  notificationContent={`${
                                                    skill.timeRequired || 0
                                                  } ${
                                                    Number(
                                                      skill.timeRequired
                                                    ) === 1
                                                      ? "hour"
                                                      : "hours"
                                                  } per week`}
                                                  classificationName={
                                                    skill?.skill?.name
                                                  }
                                                >
                                                  {skill?.skill?.name}
                                                </BorderBadgeRound>
                                              );
                                            }
                                          )}
                                          {((solution?.primaryActionGroup
                                            ?.skills &&
                                            solution?.primaryActionGroup?.skills
                                              .length < 1) ||
                                            !solution?.primaryActionGroup
                                              ?.skills) && (
                                            <div>No skills assigned</div>
                                          )}
                                        </div>
                                      }
                                    />
                                    {solution?.tags &&
                                      solution.tags?.length > 0 && (
                                        <Item
                                          title="Labels"
                                          content={
                                            <div className="flex items-center flex-wrap gap-2 py-1">
                                              {solution.tags?.map((tag) => (
                                                <BorderBadge
                                                  key={tag}
                                                  classificationName={tag}
                                                  styleLess
                                                  classNames={{
                                                    text: "text-gray-500 text-xs underline",
                                                    contend: "flex",
                                                  }}
                                                >
                                                  {tag}
                                                </BorderBadge>
                                              ))}
                                            </div>
                                          }
                                        />
                                      )}
                                  </div>
                                }
                                footer={
                                  <>
                                    <div className="flex flex-row items-center gap-2 sm:gap-10 pr-1 ">
                                      <ProfileBlock
                                        userId={solution?.user?.id}
                                        isModalVariant
                                        firstName={solution?.user?.firstName}
                                        lastName={solution?.user?.lastName}
                                        createdAt={date}
                                        profileImage={solution?.user?.avatar}
                                        email={solution?.user?.email}
                                        score={solution?.user?.reputationScore}
                                      />
                                      <div className="flex flex-row gap-1">
                                        <div
                                          onClick={(e) => {
                                            e.stopPropagation();
                                          }}
                                          className="relative flex flex-row min-w-[36px]"
                                        >
                                          {!!solution?.impactRanking ? (
                                            <span
                                              className={classnames(
                                                "bg-[#EFEFEF]  rounded min-w-[31px] min-h-[18px] text-sm  flex flex-row items-start px-2 text-center",
                                                {
                                                  " cursor-pointer hover:outline outline-[1px] hover:outline-[#8159D7]":
                                                    !!meData?.data.id,

                                                  " cursor-default ":
                                                    !meData?.data.id,
                                                }
                                              )}
                                            >
                                              {solution?.impactRanking}
                                              <RatingStar
                                                height={8}
                                                width={8}
                                                fill="black"
                                              />
                                            </span>
                                          ) : (
                                            <span
                                              className={classnames(
                                                "bg-[#EFEFEF]  rounded min-w-[31px] min-h-[18px] text-sm  flex flex-row items-start px-2 text-center",
                                                {
                                                  " cursor-pointer hover:outline outline-[1px] hover:outline-[#8159D7]":
                                                    !!meData?.data.id,
                                                  " cursor-default ":
                                                    !meData?.data.id,
                                                }
                                              )}
                                            >
                                              {0}
                                              <RatingStar
                                                height={8}
                                                width={8}
                                                fill="black"
                                              />
                                            </span>
                                          )}
                                        </div>
                                      </div>
                                    </div>

                                    {dimensions.width < 641 && (
                                      <Button
                                        inGroupStyle={false}
                                        isStyleless
                                        isReverse
                                        className="!pointer-events-auto seven-fifth-step solution-details-button-to-click sm:hidden shrink-0 text-[black] border hover:border-black border-gray-500 "
                                        onClick={() => {
                                          setcurrentSelectedSolutionId(
                                            solution?.id
                                          );
                                          setShowLeaveModal(true);
                                        }}
                                      >
                                        Join Existing Discussion
                                      </Button>
                                    )}
                                    {dimensions.width > 640 && (
                                      <Button
                                        inGroupStyle={false}
                                        isStyleless
                                        isReverse
                                        className="!pointer-events-auto seven-fifth-step solution-details-button-to-click  maxSm:hidden text-[black] border hover:border-black border-gray-500 "
                                        onClick={() => {
                                          setcurrentSelectedSolutionId(
                                            solution?.id
                                          );
                                          setShowLeaveModal(true);
                                        }}
                                      >
                                        Join Existing Discussion
                                      </Button>
                                    )}
                                  </>
                                }
                              />
                            </>
                          );
                        })}
                      </div>
                    </>
                  ) : (
                    <Carousel
                      className="w-full"
                      showArrows={true}
                      showIndicators={false}
                      showThumbs={false}
                      centerMode
                      showStatus={false}
                      centerSlidePercentage={80}
                      renderArrowPrev={(onClickHandler, hasPrev, label) => (
                        <div
                          onClick={onClickHandler}
                          title={label}
                          className={twMerge(
                            classnames(
                              "absolute left-0 top-1/2 transform -translate-y-1/2 z-50 cursor-pointer  ",
                              {
                                hidden: !hasPrev,
                              }
                            )
                          )}
                        >
                          <CarouselArrowLeft height="32" width="32" />
                        </div>
                      )}
                      renderArrowNext={(onClickHandler, hasNext, label) => (
                        <div
                          onClick={onClickHandler}
                          title={label}
                          className={twMerge(
                            classnames(
                              "absolute right-0 top-1/2 transform -translate-y-1/2 z-50 cursor-pointer  ",
                              {
                                hidden: !hasNext,
                              }
                            )
                          )}
                        >
                          <CarouselArrowRight height="32" width="32" />
                        </div>
                      )}
                    >
                      {mappedSolutions?.map((solution) => {
                        const date =
                          solution?.createdAt &&
                          new Date(solution?.createdAt).toLocaleDateString(
                            "en-GB",
                            {
                              year: "numeric",
                              month: "long",
                              day: "2-digit",
                            }
                          );

                        const formattedAddress =
                          solution?.issue?.location?.formattedAddress;

                        const isSoftDeleted = !!solution?.deletedAt;
                        return (
                          <>
                            <Card
                              shouldHover={false}
                              className={classnames(
                                "flex-1 xs:max-w-[480px] sm:max-w-[616px] justify-between z-40 border border-gray-500 h-full",

                                {
                                  "bg-gray-100 pointer-events-none select-none":
                                    isSoftDeleted,
                                }
                              )}
                              header={
                                <div className="flex justify-between items-center pb-1">
                                  <div className="grid grid-flow-row gap-x-2 items-center max-h-[70px] mr-2">
                                    <span className="font-bold leading-6 text-lg line-clamp-2">
                                      {solution?.title}
                                    </span>
                                    <span className="leading-6 text-sm  text-gray-400 line-clamp-1 font-light">
                                      {formattedAddress}
                                    </span>
                                  </div>
                                </div>
                              }
                              classNames={{
                                header: "px-4 sm:px-9  flex-1 sm:max-w-[616px]",
                                content:
                                  "px-4 sm:px-9  flex-1 sm:max-w-[616px]",
                                footer:
                                  "px-3 sm:px-9 max-h-[60px] flex-1 sm:max-w-[616px]",
                              }}
                              content={
                                <div className="grid grid-cols-[1fr] lg:grid-cols-[auto_1fr] gap-y-2 gap-x-6 text-left">
                                  <Item
                                    title="Details"
                                    content={solution?.description}
                                  />
                                  <Item
                                    title="Effectiveness"
                                    content={solution?.effectiveness}
                                  />
                                  <Item
                                    title="Skills Required"
                                    content={
                                      <div
                                        className={classnames(
                                          "flex items-start flex-wrap  gap-4 ",
                                          {
                                            " pt-1 ": !(
                                              (solution?.primaryActionGroup
                                                ?.skills &&
                                                solution?.primaryActionGroup
                                                  ?.skills.length < 1) ||
                                              !solution?.primaryActionGroup
                                                ?.skills
                                            ),
                                          }
                                        )}
                                      >
                                        {solution?.primaryActionGroup?.skills?.map(
                                          (skill) => {
                                            return (
                                              <BorderBadgeRound
                                                key={skill.id}
                                                className="flex"
                                                withNotification
                                                notificationContent={`${
                                                  skill.timeRequired || 0
                                                } ${
                                                  Number(skill.timeRequired) ===
                                                  1
                                                    ? "hour"
                                                    : "hours"
                                                } per week`}
                                                classificationName={
                                                  skill?.skill?.name
                                                }
                                              >
                                                {skill?.skill?.name}
                                              </BorderBadgeRound>
                                            );
                                          }
                                        )}
                                        {((solution?.primaryActionGroup
                                          ?.skills &&
                                          solution?.primaryActionGroup?.skills
                                            .length < 1) ||
                                          !solution?.primaryActionGroup
                                            ?.skills) && (
                                          <div>No skills assigned</div>
                                        )}
                                      </div>
                                    }
                                  />
                                  {solution?.tags &&
                                    solution.tags?.length > 0 && (
                                      <Item
                                        title="Labels"
                                        content={
                                          <div className="flex items-center flex-wrap gap-2 py-1">
                                            {solution.tags?.map((tag) => (
                                              <BorderBadge
                                                key={tag}
                                                classificationName={tag}
                                                styleLess
                                                classNames={{
                                                  text: "text-gray-500 text-xs underline",
                                                  contend: "flex",
                                                }}
                                              >
                                                {tag}
                                              </BorderBadge>
                                            ))}
                                          </div>
                                        }
                                      />
                                    )}
                                </div>
                              }
                              footer={
                                <>
                                  <div className="flex flex-row items-center gap-2 sm:gap-10 pr-1 ">
                                    <ProfileBlock
                                      userId={solution?.user?.id}
                                      isModalVariant
                                      firstName={solution?.user?.firstName}
                                      lastName={solution?.user?.lastName}
                                      createdAt={date}
                                      profileImage={solution?.user?.avatar}
                                      email={solution?.user?.email}
                                      score={solution?.user?.reputationScore}
                                    />
                                    <div className="flex flex-row gap-1">
                                      <div
                                        onClick={(e) => {
                                          e.stopPropagation();
                                        }}
                                        className="relative flex flex-row min-w-[36px]"
                                      >
                                        {!!solution?.impactRanking ? (
                                          <span
                                            className={classnames(
                                              "bg-[#EFEFEF]  rounded min-w-[31px] min-h-[18px] text-sm  flex flex-row items-start px-2 text-center",
                                              {
                                                " cursor-pointer hover:outline outline-[1px] hover:outline-[#8159D7]":
                                                  !!meData?.data.id,

                                                " cursor-default ":
                                                  !meData?.data.id,
                                              }
                                            )}
                                          >
                                            {solution?.impactRanking}
                                            <RatingStar
                                              height={8}
                                              width={8}
                                              fill="black"
                                            />
                                          </span>
                                        ) : (
                                          <span
                                            className={classnames(
                                              "bg-[#EFEFEF]  rounded min-w-[31px] min-h-[18px] text-sm  flex flex-row items-start px-2 text-center",
                                              {
                                                " cursor-pointer hover:outline outline-[1px] hover:outline-[#8159D7]":
                                                  !!meData?.data.id,
                                                " cursor-default ":
                                                  !meData?.data.id,
                                              }
                                            )}
                                          >
                                            {0}
                                            <RatingStar
                                              height={8}
                                              width={8}
                                              fill="black"
                                            />
                                          </span>
                                        )}
                                      </div>
                                    </div>
                                  </div>

                                  {dimensions.width < 641 && (
                                    <Button
                                      inGroupStyle={false}
                                      isStyleless
                                      isReverse
                                      className="!pointer-events-auto seven-fifth-step solution-details-button-to-click sm:hidden shrink-0 text-[black] border hover:border-black border-gray-500 "
                                      onClick={() => {
                                        setcurrentSelectedSolutionId(
                                          solution?.id
                                        );
                                        setShowLeaveModal(true);
                                      }}
                                    >
                                      Join Existing Discussion
                                    </Button>
                                  )}
                                  {dimensions.width > 640 && (
                                    <Button
                                      inGroupStyle={false}
                                      isStyleless
                                      isReverse
                                      className="!pointer-events-auto seven-fifth-step solution-details-button-to-click  maxSm:hidden text-[black] border hover:border-black border-gray-500 "
                                      onClick={() => {
                                        setcurrentSelectedSolutionId(
                                          solution?.id
                                        );
                                        setShowLeaveModal(true);
                                      }}
                                    >
                                      Join Existing Discussion
                                    </Button>
                                  )}
                                </>
                              }
                            />
                          </>
                        );
                      })}
                    </Carousel>
                  )}
                </>
              ) : (
                <div>No similar solutions found</div>
              )}
            </div>
          }
          classNames={{
            footer: "!justify-center",
            header: " h-max justify-start",
          }}
          footer={
            <div className=" flex gap-4 items-center px-4  ">
              {!isMobile && !isTablet && !(dimensions.width < 641) && (
                <button
                  type="button"
                  onClick={() => {
                    submitForm();
                  }}
                  disabled={isSubmitting || !!errors?.validator}
                  className={twMerge(
                    classnames(
                      "w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-3 bg-quality-content-green text-base font-medium text-white hover:bg-quality-content-green focus:outline-none  sm:ml-3 sm:w-auto sm:text-sm"
                    )
                  )}
                >
                  Publish My Solution Anyway
                </button>
              )}
            </div>
          }
        />
      )}
      {showLeaveModal && (
        <AISimilarityLeave
          setShow={(val) => {
            setShowLeaveModal(val);
          }}
          header={<></>}
          content={
            <div className="flex flex-col items-center gap-4 py-1  ">
              <p className="font-semibold text-xl leading-0">Warning</p>
              <p className="font-light text-sm">
                Your draft will be discarded when you join the existing
                discussion. Continue?
              </p>
            </div>
          }
          footer={
            <div className=" flex gap-4 justify-end ">
              <Button
                isReverse
                type="button"
                className=" justify-center border"
                onClick={() => {
                  setShowLeaveModal(false);
                }}
              >
                No, go back
              </Button>
              <button
                type="button"
                onClick={() => {
                  setShowLeaveModal(false);
                  setShowSimilarityModal(false);
                  props.similarRef.current = false;
                  if (props?.onClose) {
                    props?.onClose();
                    props.similarRef.current = false;
                    navigate(
                      APP_ROUTES.SOLUTION + "/" + currentSelectedSolutionId
                    );
                  }
                }}
                className={twMerge(
                  classnames(
                    "w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-3 bg-quality-content-green text-base font-medium text-white hover:bg-quality-content-green focus:outline-none  sm:ml-3 sm:w-auto sm:text-sm"
                  )
                )}
              >
                Yes, join discussion
              </button>
            </div>
          }
          classNames={{
            content: "text-black",
            footer: "pb-[40px] pt-0",
          }}
        />
      )}
    </>
  );
}

export default SolutionForm;
