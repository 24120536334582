import usePlacesAutocomplete, { getGeocode } from "use-places-autocomplete";

import { AdvancedSelectField, IAdvanceFieldProps } from "components";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  OptionProps,
  ClearIndicatorProps,
  components,
  ValueContainerProps,
  ContainerProps,
} from "react-select";
import classnames from "classnames";
import { useLocation, useSearchParams } from "react-router-dom";
import { ReactComponent as SearchIcon } from "assets/images/search-icon.svg";
import { getDetails } from "use-places-autocomplete";
import { IFeedbackArea } from "types";

enum ERROR_STATUSES {
  INVALID_REQUEST = "Google api invalid request",
  NOT_FOUND = "Not Found",
  OVER_QUERY_LIMIT = "Query limit is used",
  REQUEST_DENIED = "Request denied",
  UNKNOWN_ERROR = "Unknown Error",
}

function Option(props: OptionProps<any>) {
  return (
    <div
      className={classnames("py-1 cursor-pointer", {
        "selected-option-gradient": props.isSelected,
        " hovered-selected-option-gradient px-6": !props.isSelected,
      })}
    >
      <components.Option {...props}>
        <label className=" font-light text-sm cursor-pointer">
          {props.label}
        </label>
      </components.Option>
    </div>
  );
}

function ValueContainer({
  children,

  ...props
}: ValueContainerProps) {
  return (
    components.ValueContainer && (
      <components.ValueContainer {...props}>
        {children}
      </components.ValueContainer>
    )
  );
}

function SearchValueContainer({
  children,

  ...props
}: ValueContainerProps) {
  return (
    components.ValueContainer && (
      <components.ValueContainer {...props}>
        {!!children && <SearchIcon className="absolute ml-1" />}
        <div className="pl-4 grid grid-flow-row items-center"> {children}</div>
      </components.ValueContainer>
    )
  );
}

function Input(props: OptionProps<any>) {
  return <components.Input {...props} isHidden={false} />;
}

function SelectContainer(props: ContainerProps<any>) {
  return <components.SelectContainer {...props} className="w-full " />;
}

export type CommonSelectType = Omit<IAdvanceFieldProps, "message"> & {
  name?: string;
  message?: string;
};

export type SearchableSelectType = CommonSelectType & {
  isSearchable: true;
  makeSearch: (value: string) => void;
  isLoading?: boolean;
};

export type AutoCompleteSelectFieldType<T> = (T extends { isSearchable: true }
  ? SearchableSelectType
  : CommonSelectType) & {
  debounceTime?: number;
  isSearchInput?: boolean;
  showSearchIcon?: boolean;
  readOnly?: boolean;
  square?: boolean;
  returnValueOnly?: boolean;
  allowAutoFill?: boolean;
  checkAIContentQuality?: boolean;
  AISuggestionProblemArea?: IFeedbackArea;
  AISuggestionFieldName?: string;
  disabled?: boolean;
};

function getClearIndicatorComponent(clear: () => void) {
  return function ClearIndicator(props: ClearIndicatorProps) {
    const customProps = {
      ...props,
      innerProps: {
        ...props.innerProps,
        onMouseDown: () => {
          props.clearValue();
          clear();
        },
      },
    };
    return <components.ClearIndicator {...customProps} />;
  };
}

export default function AutoCompleteSelectField<T>({
  debounceTime = 100,
  showSearchIcon,
  isSearchInput,
  square,
  readOnly = false,
  allowAutoFill = true,

  ...rest
}: AutoCompleteSelectFieldType<T>) {
  const {
    ready,
    suggestions: { data, loading, status },
    setValue: setPlacesValue,
  } = usePlacesAutocomplete({
    requestOptions: {
      /* Define search scope here */
    },
    debounce: 300,
  });
  const [inputValue, setInputValue] = useState<{
    value: string;
    label: string;
  } | null>();

  const [searchParams] = useSearchParams();
  //@ts-ignore
  const placeId = searchParams.get("placeId") || rest.value?.value;

  useEffect(() => {
    if (placeId && allowAutoFill) {
      getGeocode({ placeId }).then((result) => {
        const address = result[0].formatted_address;
        getDetails({ placeId }).then((place) => {
          const placeName = typeof place !== "string" && place?.name + ", ";
          setInputValue({
            value: placeName + address,
            label: placeName + address,
          });
        });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (inputValue?.label.length) {
      setPlacesValue(inputValue?.label);
    }
  }, [setPlacesValue, inputValue]);

  type InputOption = {
    label: string;
    value: string;
  };

  const [formattedData, setFormattedData] = useState<InputOption[]>([]);

  useEffect(() => {
    const fetchGeoCodeData = async () => {
      const formattedData: InputOption[] = [];
      for (const loc of data) {
        // const result = await getGeocode({ placeId: loc?.place_id });
        // const formattedAddress = result[0].formatted_address;
        // const placeName = loc?.structured_formatting?.main_text;
        const placeDesc = loc?.description;
        formattedData.push({
          // label:
          //   placeName === formattedAddress
          //     ? formattedAddress
          //     : placeName + ", " + formattedAddress,
          label: placeDesc,
          value: loc?.place_id,
        });
      }
      setFormattedData(formattedData);
    };

    fetchGeoCodeData();
  }, [data]);

  //@ts-ignore
  const error = rest.message || ERROR_STATUSES[status] || "";
  const clear = useCallback(() => {
    setInputValue(null);
    setPlacesValue("", false);
  }, [setPlacesValue]);
  const location = useLocation();
  useEffect(() => {
    clear();
  }, [location.pathname, clear]);
  return (
    <AdvancedSelectField
      readOnly={readOnly}
      placeholder={rest.isDisabled ? "" : "Search"}
      isSearchInput={isSearchInput}
      square={square}
      inputValue={inputValue?.label}
      onInputChange={useCallback(
        (value, action) => {
          if (
            action?.action !== "input-blur" &&
            action?.action !== "menu-close"
          ) {
            setInputValue({ label: value, value });
          }
        },
        [setInputValue]
      )}
      closeMenuOnSelect
      isSearchable
      isClearable={true}
      isLoading={loading}
      isDisabled={!ready}
      components={{
        SelectContainer,
        DropdownIndicator: () => null,
        Option,
        LoadingIndicator: () => null,
        ClearIndicator: getClearIndicatorComponent(clear),
        Input,
        ValueContainer: showSearchIcon ? SearchValueContainer : ValueContainer,
      }}
      options={formattedData}
      message={error}
      isError={!!error}
      classNames={useMemo(() => {
        let classes: AutoCompleteSelectFieldType<T>["classNames"] = {};
        if (rest.classNames) {
          classes = { ...rest.classNames };
        }
        if (error) {
          //@ts-ignore
          classes = {
            ...classes,

            message: classes?.message
              ? classes?.message + " !text-red-500"
              : " !text-red-500",
          };
        }
        return classes;
      }, [error, rest.classNames])}
      value={inputValue}
      {...rest}
      onChange={(value: any) => {
        //@ts-ignore
        rest?.onChange && rest?.onChange(value);
        setInputValue(value);
      }}
    />
  );
}
