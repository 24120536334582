import { AxiosResponse } from "axios";

import {
  useIssue,
  useIssueRatingCategories,
  useIssueRatings,
  useMe,
  useUpdateAttachments,
} from "hooks";
import { useCallback, useMemo } from "react";
import { useMutation, UseMutationOptions, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import {
  IAISimilar,
  IAISuggestions,
  IAsset,
  IAttachment,
  IIssue,
  IIssueRating,
} from "types";
import { IManagementProps, IValues } from "./IssueForm.types";
import {
  createIssue,
  updateIssue,
  bulkUpdateOrCreateIssueRatings,
} from "./IssueForm.services";
import { IssueInitial, IssuePayload } from "./IssueForm.constants";
import { FormikHelpers } from "formik";
import { CONTENT_QUALITY_LEVELS, ENDPOINTS } from "const";
import { showErrorMessage, showSuccessMessage } from "utils";
import {
  createAISimilarIssues,
  createAISuggestionsIssues,
  IAIIssueProps,
} from "services";

export function useInitialState() {
  const { data: meData } = useMe();
  const { id } = useParams<{ id: string }>();
  const { data: categoriesData } = useIssueRatingCategories();
  const { data: issueRate } = useIssueRatings({
    userId: meData?.data.id,
    issueId: id,
    enabled: !!id,
  });
  const { data: issueData } = useIssue({ id });
  return useMemo(() => {
    return new IssueInitial(
      issueData?.data,
      categoriesData?.data,
      issueRate?.data?.items
    );
  }, [issueData?.data, categoriesData?.data, issueRate?.data?.items]);
}

interface IAIAnalyticsIssueProps
  extends Partial<UseMutationOptions<AxiosResponse<IAISuggestions>, any, any>> {
  id?: string | undefined;
}

export function useCreateAIAnalyticsIssue({ ...rest }: IAIAnalyticsIssueProps) {
  const queryClient = useQueryClient();
  return useMutation<AxiosResponse<IAISuggestions>, any, any>(
    (issue: IAIIssueProps) => createAISuggestionsIssues({ ...issue }),
    {
      mutationKey: ["createAIAnalyticsIssue"],
      onSuccess: (data) => {
        queryClient.setQueryData(["createAIAnalyticsIssue"], data.data || {});
      },

      ...rest,
    }
  );
}

interface IAISimilarIssueProps
  extends Partial<UseMutationOptions<AxiosResponse<IAISimilar>, any, any>> {
  id?: string | undefined;
}

export function useCreateAISimilarIssue({ ...rest }: IAISimilarIssueProps) {
  const queryClient = useQueryClient();
  return useMutation<AxiosResponse<IAISimilar>, any, any>(
    (issue: IAIIssueProps) => createAISimilarIssues({ ...issue }),
    {
      mutationKey: ["createAISimilarIssue"],
      onSuccess: (data) => {
        queryClient.setQueryData(["createAISimilarIssue"], data.data || {});
      },

      ...rest,
    }
  );
}

interface IIssueProps
  extends Partial<UseMutationOptions<AxiosResponse<IIssue>, any, any>> {
  id?: string | undefined;
}

export function useCreateIssue({ ...rest }: IIssueProps) {
  return useMutation<AxiosResponse<IIssue>, any, any>(
    (issue: IIssue) => createIssue({ issue }),
    {
      ...rest,
    }
  );
}

export function useUpdateIssue({
  id = "",
  ...rest
}: IIssueProps & {
  id: string | undefined;
}) {
  return useMutation<AxiosResponse<IIssue>, any, any>(
    (issue: IIssue) => updateIssue({ id, issue }),
    {
      ...rest,
    }
  );
}
interface IIssueRatingProps
  extends Partial<
    UseMutationOptions<AxiosResponse<Partial<IIssueRating>[]>, any, any>
  > {}
export function useUpdateOrCreateIssueRating({
  ...rest
}: IIssueRatingProps = {}) {
  return useMutation<AxiosResponse<Partial<IIssueRating>[]>, any, any>(
    async ({
      issueRating,
      issueId,
    }: {
      issueRating: ReturnType<IssuePayload["getIssueRatings"]>;
      issueId: string;
    }) => {
      return bulkUpdateOrCreateIssueRatings(issueRating, issueId);
    },
    {
      ...rest,
    }
  );
}

///
export function useSubmitHandler(
  success: (id?: string) => void,
  manage: IManagementProps,
  scrollSlideoverToTop?: () => void
) {
  const params = useParams<{ id: string }>();

  const { mutateAsync: mutateCreateAISuggestionIssueAsync } =
    useCreateAIAnalyticsIssue({});
  const { mutateAsync: mutateCreateAISimilarIssueAsync } =
    useCreateAISimilarIssue({});
  const { mutateAsync: mutateCreateIssueAsync } = useCreateIssue({});
  const { mutateAsync: mutateUpdateIssueAsync } = useUpdateIssue({
    id: params.id,
  });
  const { mutateAsync: mutateUpdateOrCreateIssueRating } =
    useUpdateOrCreateIssueRating();
  const { data: categories } = useIssueRatingCategories();
  const { mutateAsync: updateAttachments } = useUpdateAttachments();

  const queryClient = useQueryClient();

  return useCallback(
    async (values: IValues, formikHelpers: FormikHelpers<IValues>) => {
      formikHelpers.setSubmitting(true);
      const invalidate = () => {
        queryClient.invalidateQueries([ENDPOINTS.ISSUES]);
        queryClient.resetQueries([ENDPOINTS.ISSUES]);
        queryClient.invalidateQueries([ENDPOINTS.ISSUE]);
        queryClient.invalidateQueries([ENDPOINTS.MAP_LOCATIONS]);
        queryClient.invalidateQueries([ENDPOINTS.ISSUE_RATING]);
      };
      const issue = new IssuePayload(
        { ...values, attachmentsType: "issues" },
        categories?.data
      );
      try {
        let issueId = params.id;

        const issueAnalyticsPayload = {
          issue_title: values.title,
          issue_location: values.location?.label,
          issue_details: values.details,
          issue_origin: values.origin,
          issue_importance: values.importance,
          ratings: values?.issueRating,
          docs: values?.attachments
            ?.filter((a) => a.type === "file")
            ?.map((a) => (a as unknown as IAttachment<IAsset>)?.asset?.url),
          links: values?.attachments
            ?.filter((a) => a.type === "link")
            ?.map((a) => (a as unknown as IAttachment)?.data),
          labels: values?.tags,
        };

        const aiSuggestionsReviewResult =
          manage?.proceedWithSuggestions === true
            ? { data: { feedback_areas: [], more_feedback: { quality: "" } } }
            : await mutateCreateAISuggestionIssueAsync(issueAnalyticsPayload);

        if (
          manage?.proceedWithSuggestions === false &&
          ((aiSuggestionsReviewResult.data.feedback_areas.length > 0 &&
            aiSuggestionsReviewResult.data.feedback_areas.find(
              (area) =>
                area.quality !== CONTENT_QUALITY_LEVELS.GOOD &&
                area.quality !== CONTENT_QUALITY_LEVELS.HIGH
            )) ||
            aiSuggestionsReviewResult.data?.more_feedback?.quality ===
              "Useless" ||
            aiSuggestionsReviewResult.data?.more_feedback?.quality === "Poor")
        ) {
          formikHelpers.setTouched({
            title: false,
            details: false,
            origin: false,
            importance: false,
          });
          document.querySelector("#issue-report")?.scrollTo(0, 0);
          return;
        }

        const aiSimilarIssuesResult =
          manage?.proceedWithSimilar === true
            ? { data: { potential_similarity_id: [] } }
            : await mutateCreateAISimilarIssueAsync(issueAnalyticsPayload);

        if (
          !issueId &&
          manage?.proceedWithSimilar === false &&
          aiSimilarIssuesResult?.data?.potential_similarity_id.length > 0
        ) {
          return;
        }

        if (issueId && manage.showIssueFields) {
          await mutateUpdateIssueAsync(issue.getIssue());
        }
        if (!issueId && manage.showIssueFields) {
          const created = await mutateCreateIssueAsync(issue.getIssue());
          issueId = created.data.id;
        }
        if (issueId && manage.showIssueFields) {
          await updateAttachments(issue.getAttachments(issueId));
        }
        if (issueId && manage.showRatings) {
          await mutateUpdateOrCreateIssueRating({
            issueRating: issue.getIssueRatings(issueId),
            issueId, //add issue dynamically here, because on creating issue we don't have issueId
          });
          showSuccessMessage("Success!");
          showSuccessMessage("Rating is updated!");
        }
        success(issueId);
        invalidate();
        formikHelpers.setSubmitting(false);
        queryClient.getMutationCache().clear();
      } catch (error) {
        formikHelpers.setSubmitting(false);

        if (error?.response?.data) {
          formikHelpers.setErrors(error.response.data);
        }

        showErrorMessage("Error occurred! Check form!");
      }
    },
    [
      categories?.data,
      manage?.proceedWithSimilar,
      manage?.proceedWithSuggestions,
      manage.showIssueFields,
      manage.showRatings,
      mutateCreateAISimilarIssueAsync,
      mutateCreateAISuggestionIssueAsync,
      mutateCreateIssueAsync,
      mutateUpdateIssueAsync,
      mutateUpdateOrCreateIssueRating,
      params.id,
      queryClient,
      success,
      updateAttachments,
    ]
  );
}
