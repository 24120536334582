import { FormikFileDnd } from "components/FormikFileDnd/FormikFileDnd";
import { AttachmentList } from "./AttachmentList/AttachmentList";
import { Label } from "components";

import "./attachments.css";
import { useFormikContext } from "formik";
import { AttachmentsInitial } from "./Attachments.types";
import { IAsset, IAttachment, ImageType } from "types";
import { useCallback } from "react";

interface IAttachmentsProps {
  disabled?: boolean;
}

function Attachments({ disabled }: IAttachmentsProps) {
  //check if exists and if is is number and not NaN
  const { setFieldValue, values, errors, touched, setTouched, setValues } =
    useFormikContext<Partial<AttachmentsInitial>>();
  const attachmentErrors = errors.attachments as string[] | undefined;
  const attachments = values.attachments as
    | (File | ImageType | Partial<IAttachment<IAsset, string>>)[]
    | undefined;

  const onAddLink = useCallback(() => {
    if (
      errors?.attachmentsInput ||
      errors?.attachmentsTitle ||
      !values.attachmentsInput ||
      !values.attachmentsTitle
    ) {
      setTouched({
        ...touched,
        attachmentsInput: true,
        attachmentsTitle: true,
      });
      return;
    }
    if (attachments && typeof attachments === "object") {
      setValues((prev) => ({
        ...prev,
        attachments: [
          ...attachments,
          {
            type: "link",
            data: values.attachmentsInput,
            title: values.attachmentsTitle,
          },
        ],
        attachmentsInput: "",
        attachmentsTitle: "",
      }));
    }
  }, [
    attachments,
    errors.attachmentsInput,
    errors.attachmentsTitle,
    setTouched,
    setValues,
    touched,
    values.attachmentsInput,
    values.attachmentsTitle,
  ]);

  const onDelete = useCallback(
    (index: number) => {
      return () => {
        const filtered = attachments?.filter((_, i) => i !== index);
        setFieldValue("attachments", filtered);
      };
    },
    [attachments, setFieldValue]
  );
  const onEditTextItem = useCallback(
    (index: number, data: string | undefined, title: string | undefined) => {
      return () => {
        setValues((prev) => ({
          ...prev,
          isQuillModalOpen: true,
          attachmentIndexToEdit: index,
          attachmentsQuill: data,
          attachmentsTitle: title,
        }));
      };
    },
    [setValues]
  );

  const onDeleteTextItem = useCallback(
    (index: number) => {
      return () => {
        const filtered = attachments?.filter((_, i) => i !== index);
        setFieldValue("attachments", filtered);
      };
    },
    [attachments, setFieldValue]
  );

  const onOpenQuillModal = useCallback(() => {
    setFieldValue("isQuillModalOpen", true);
  }, [setFieldValue]);

  return (
    <div className="flex flex-col gap-4">
      <Label className="" name="Attach documents (Optional)" />
      <FormikFileDnd disabled={disabled} />
      <AttachmentList
        readOnly={false}
        disabled={disabled}
        onEditTextTitle={onEditTextItem}
        onAddLink={onAddLink}
        onDeleteTextItem={onDeleteTextItem}
        onDelete={onDelete}
        onOpenQuillModal={onOpenQuillModal}
        attachmentsErrors={attachmentErrors}
        attachments={attachments}
        showAttachmentsLinkLabel={false}
        directory="issues"
      />
    </div>
  );
}

export default Attachments;
