/* This example requires Tailwind CSS v2.0+ */
import React, {
  Fragment,
  memo,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import { ColorBorderWrapper } from "components/ColorBorderWrapper";

import classnames from "classnames";

function useScrollOnOpen(open: boolean) {
  const [ref, setRef] = useState<HTMLElement | null>(null);

  const scrollToElement = useCallback(() => {
    ref?.scrollIntoView();
  }, [ref]);
  const prevOpen = useRef(open);
  useEffect(() => {
    if (open && ref && !prevOpen.current) {
      prevOpen.current = open;
      const id = setTimeout(() => {
        scrollToElement();
      }, 100);
      return () => {
        clearTimeout(id);
      };
    }
    if (prevOpen?.current && !open) {
      prevOpen.current = open;
    }
  }, [open, ref, scrollToElement]);
  return setRef;
}

interface IProps {
  open: boolean;
  id?: string;
  close: () => void;
  children: React.ReactNode;
  preventClose?: boolean;
  title?: string;
  headerIcon?: JSX.Element;
  description?: string | React.ReactNode;
  position?: "left" | "right";
  classNames?: {
    headerIcon?: string;
  };
}

function SlideOverLeft({
  open,
  close,
  children,
  title,
  preventClose = false,
  headerIcon,
  description,
  classNames,
  id,
}: IProps) {
  const [localPreventClose, setLocalPreventClose] = useState(false);
  const setRef = useScrollOnOpen(open);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        open={open}
        className="fixed inset-0 overflow-hidden z-30 bg-gray-500 bg-opacity-20"
        onClose={() => {
          if (!preventClose && !localPreventClose) {
            close();
          }
        }}
      >
        <div className="absolute inset-0 overflow-hidden">
          <Dialog.Overlay className="absolute inset-0" />

          <div
            className={classnames(
              "pointer-events-none fixed inset-y-0 left-0 sm:pr-16  flex max-w-full "
            )}
          >
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-500 sm:duration-800"
              enterFrom="translate-x-[-100%]"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-800"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-[-100%]"
            >
              <div
                className={classnames(
                  "pointer-events-auto sm:min-w-[350px] maxSm:w-screen w-[30vw]  sm:max-w-2xl"
                )}
              >
                <ColorBorderWrapper
                  isError={false}
                  isInput={false}
                  noAnimation
                  id={id}
                  className="rounded-md bg-white overflow-y-auto h-full flex flex-col scrollable"
                >
                  <div
                    className="flex  flex-col  bg-white py-6 shadow-xl rounded-md flex-1"
                    ref={(ref) => {
                      setRef(ref);
                    }}
                  >
                    <div
                      className={classnames(
                        classNames?.headerIcon,
                        "px-4 sm:px-12"
                      )}
                    >
                      <div className="flex items-center justify-between">
                        <div className="flex flex-col">
                          {headerIcon}

                          {title && (
                            <Dialog.Title className="font-bold text-black text-3xl leading-6">
                              {title}
                            </Dialog.Title>
                          )}
                          {description && typeof description === "string" ? (
                            <Dialog.Description className="text-sm  text-gray-500 font-light mt-[14px]">
                              {description}
                            </Dialog.Description>
                          ) : (
                            <>{description}</>
                          )}
                        </div>
                        <div className="ml-3 flex h-7 items-center">
                          <button
                            type="button"
                            className="rounded-md bg-white text-gray-400 hover:text-gray-500  "
                            onClick={close}
                          >
                            <span className="sr-only">Close panel</span>
                            <XIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="border-b border-gray-200 w-full h-[1px] mt-6" />
                    <div
                      onMouseOver={() => {
                        setLocalPreventClose(true);
                      }}
                      onMouseOut={() => {
                        setLocalPreventClose(false);
                      }}
                      className="relative mt-6 flex-1  font-semibold flex flex-col"
                    >
                      {/* Replace with your content */}
                      {children}
                      {/* /End replace */}
                    </div>
                  </div>
                </ColorBorderWrapper>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

function SlideOverRight({
  open,
  close,
  children,
  title,
  preventClose = false,
  headerIcon,
  description,
  classNames,
  id,
}: IProps) {
  const [localPreventClose, setLocalPreventClose] = useState(false);
  const setRef = useScrollOnOpen(open);
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        open={open}
        className="fixed inset-0 overflow-hidden z-30 bg-gray-500 bg-opacity-20"
        onClose={() => {
          // if (!preventClose && !localPreventClose) {
          //   close();
          // }
        }}
      >
        <div className="absolute inset-0 overflow-hidden">
          <Dialog.Overlay className="absolute inset-0" />

          <div
            className={classnames(
              "pointer-events-none fixed inset-y-0 right-0 sm:pl-16  flex max-w-full "
            )}
          >
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-500 sm:duration-800"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-800"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div
                className={classnames(
                  "pointer-events-auto w-screen  max-w-2xl"
                )}
              >
                <ColorBorderWrapper
                  isError={false}
                  isInput={false}
                  noAnimation
                  id={id}
                  className="rounded-md bg-white overflow-y-auto h-full flex flex-col scrollable"
                >
                  <div
                    className="flex  flex-col  bg-white py-6 shadow-xl rounded-md flex-1"
                    ref={(ref) => {
                      setRef(ref);
                    }}
                  >
                    <div
                      className={classnames(
                        classNames?.headerIcon,
                        "px-4 sm:px-20"
                      )}
                    >
                      <div className="flex items-center justify-between">
                        <div className="flex flex-col">
                          {headerIcon}

                          {title && (
                            <Dialog.Title className="font-bold text-black text-3xl leading-6">
                              {title}
                            </Dialog.Title>
                          )}
                          {description && typeof description === "string" ? (
                            <Dialog.Description className="text-sm  text-gray-500 font-light mt-[14px]">
                              {description}
                            </Dialog.Description>
                          ) : (
                            <>{description}</>
                          )}
                        </div>
                        <div className="ml-3 flex h-7 items-center">
                          <button
                            type="button"
                            className="rounded-md bg-white text-gray-400 hover:text-gray-500  "
                            onClick={close}
                          >
                            <span className="sr-only">Close panel</span>
                            <XIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="border-b border-gray-200 w-full h-[1px] mt-6" />
                    <div
                      onMouseOver={() => {
                        setLocalPreventClose(true);
                      }}
                      onMouseOut={() => {
                        setLocalPreventClose(false);
                      }}
                      className="relative mt-6 flex-1  font-semibold flex flex-col"
                    >
                      {/* Replace with your content */}
                      {children}
                      {/* /End replace */}
                    </div>
                  </div>
                </ColorBorderWrapper>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

function SlideOver({
  open,
  close,
  children,
  preventClose,
  title,
  headerIcon,
  description,
  position = "right",
  classNames,
  id,
}: IProps) {
  return position === "right" ? (
    <SlideOverRight
      id={id}
      open={open}
      close={close}
      preventClose={preventClose}
      children={children}
      title={title}
      headerIcon={headerIcon}
      classNames={classNames}
      description={description}
    />
  ) : (
    <SlideOverLeft
      id={id}
      open={open}
      close={close}
      children={children}
      preventClose={preventClose}
      title={title}
      headerIcon={headerIcon}
      classNames={classNames}
      description={description}
    />
  );
}

export default memo(SlideOver);
