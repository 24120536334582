import { Formik } from "formik";
import { memo, useCallback, useRef } from "react";

import validationSchema from "./SolutionForm.schema";
import { useInitialState, useSubmitHandler } from "./SolutionForm.hooks";
import SolutionForm from "./SolutionForm.component";
import { IManagementProps } from "./SolutionForm.types";
import { SolutionInitial } from "./SolutionForm.constants";

interface IProps extends IManagementProps {
  className?: string;
  onSuccess: (id?: string) => void;
  onClose: (id?: string) => void;
}

function SolutionFormContainer({
  className,
  onSuccess,
  onClose,
  showSolutionFields = true,
  showSolutionRatings = true,
}: IProps) {
  const similarRef = useRef<boolean>(false);
  const suggestionsRef = useRef<boolean>(false);
  const onCloseCallback = useCallback(
    (id?: string) => {
      similarRef.current = false;
      suggestionsRef.current = false;
      if (onClose) {
        onClose(id);
      }
    },
    [onClose]
  );
  return (
    <Formik<SolutionInitial>
      enableReinitialize
      initialValues={useInitialState()}
      validationSchema={validationSchema({
        showSolutionFields,
        showSolutionRatings,
      })}
      onSubmit={useSubmitHandler(onSuccess, {
        showSolutionFields,
        showSolutionRatings,
        proceedWithSimilar: similarRef.current,
        proceedWithSuggestions: suggestionsRef.current,
      })}
    >
      <SolutionForm
        className={className}
        onClose={onCloseCallback}
        similarRef={similarRef}
        suggestionsRef={suggestionsRef}
        showSolutionFields={showSolutionFields}
        showSolutionRatings={showSolutionRatings}
      />
    </Formik>
  );
}

export default memo(SolutionFormContainer);
