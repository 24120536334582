import {
  IAsset,
  IAttachment,
  ImageType,
  isSavedAttachment,
  isFileType,
} from "types";
import {
  AttachmentFiletItem,
  AttachmentLinkItem,
  AttachmentTextItem,
} from "modules/Attachments/AttachmentItem/AttachmentItem";
import FormikInput from "components/FormikInput";
import { Label } from "components/Label";
// import { AttachmentsQuillModal } from "../AttachmentsQuillModal";
// import { Button } from "components";
import { twMerge } from "tailwind-merge";
import classnames from "classnames";
import { MandatoryAttachments } from "../MandatoryAttachments/MandatoryAttachments";

export function AttachmentList({
  readOnly = false,
  attachmentsErrors,
  attachments,
  inputError,
  onAddLink,
  onDelete,
  onEditTextTitle,
  onOpenQuillModal,
  disabled,
  className,
  classNames,
  showAttachmentsLinkLabel = true,
  directory,
}: {
  classNames?: {
    attachmentsContainer?: string;
  };
  className?: string;
  onOpenQuillModal?: () => void;
  onDeleteTextItem?: (index: number) => () => void;
  onEditTextTitle?: (
    index: number,
    data: string | undefined,
    title: string | undefined
  ) => () => void;
  onDelete?: (index: number) => () => void;
  onAddLink?: () => void;
  disabled?: boolean;
  attachmentsErrors?: string[];
  inputError?: boolean;
  readOnly?: boolean;
  showAttachmentsLinkLabel?: boolean;
  attachments:
    | (File | ImageType | Partial<IAttachment<IAsset, string>>)[]
    | undefined;
  directory: "issues" | "solutions" | "actionGroups";
}) {
  const thereAreLinks = !!attachments?.find((i) => i?.type === "link");

  return (
    <div className={classnames("flex w-full gap-y-2 flex-col", className)}>
      {showAttachmentsLinkLabel && attachments && attachments?.length > 0 && (
        <Label name="Attachments" className="!text-[#999999]" />
      )}
      <div
        className={twMerge(
          classnames(
            " grid grid-cols-4 content-center gap-2",
            classNames?.attachmentsContainer
          )
        )}
      >
        {readOnly && <MandatoryAttachments commonFor={directory} />}
        {attachments?.map((i, index) => {
          const isFile = isFileType(i);
          const isSaved = isSavedAttachment(i);
          const isSavedText = isSaved && i.type === "text";
          const isAddedText = !isFileType(i) && i.type === "text" && !i.id; //is link added by user and not saved
          const renderText = isAddedText || isSavedText;
          const isSavedFile = isSaved && i?.type === "file";
          const renderFile = isFile || isSavedFile;

          return (
            <>
              {renderFile && (
                <AttachmentFiletItem
                  key={isFile ? i?.name : i?.id}
                  error={
                    attachmentsErrors?.length
                      ? attachmentsErrors[index]
                      : undefined
                  }
                  attachment={i}
                  disabled={disabled}
                  onDelete={onDelete && onDelete(index)}
                  readOnly={readOnly}
                />
              )}
              {renderText && (
                <AttachmentTextItem
                  readOnly={readOnly}
                  key={i.data + i?.id}
                  error={
                    attachmentsErrors?.length
                      ? attachmentsErrors[index]
                      : undefined
                  }
                  attachment={i}
                  disabled={disabled}
                  onEdit={
                    onEditTextTitle && onEditTextTitle(index, i.data, i.title)
                  }
                  onDelete={onDelete && onDelete(index)}
                />
              )}
            </>
          );
        })}
      </div>
      {!readOnly && <Label className="" name="Attach links (Optional)" />}
      {readOnly && <MandatoryAttachments commonFor={directory} isLinks />}
      {!!thereAreLinks && (
        <div
          className={classnames("flex flex-col gap-4", {
            "!flex-row flex-wrap": readOnly,
          })}
        >
          {attachments?.map((i, index) => {
            const isSaved = isSavedAttachment(i);
            const isSavedLink = isSaved && i.type === "link";
            const isAddedLink = !isFileType(i) && i.type === "link" && !i.id; //is link added by user and not saved
            const renderItem = isAddedLink || isSavedLink;
            return (
              <>
                {renderItem && (
                  <AttachmentLinkItem
                    readOnly={readOnly}
                    key={i.data + i?.id}
                    disabled={disabled}
                    error={
                      attachmentsErrors?.length
                        ? attachmentsErrors[index]
                        : undefined
                    }
                    value={i}
                    onDelete={onDelete && onDelete(index)}
                  />
                )}
              </>
            );
          })}
        </div>
      )}

      {!readOnly && (
        <>
          <div
            className="flex  cursor-pointer w-full gap-2 "
            tabIndex={1}
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                event.preventDefault();
                event.stopPropagation();
                onAddLink && onAddLink();
              }
            }}
          >
            <div className="flex gap-2 flex-1">
              <FormikInput
                disabled={disabled}
                placeholder="Add Url"
                name="attachmentsInput"
                classNames={{
                  inputFieldContainer: "flex-1",
                  mainContainer: classnames({
                    //"pb-5": !inputError,
                  }),
                }}
                message='Press "Enter" to add '
              />
              <FormikInput
                disabled={disabled}
                placeholder="Add link name"
                name="attachmentsTitle"
                classNames={{
                  inputFieldContainer: "flex-1",
                  mainContainer: classnames({
                    //"pb-5": !inputError,
                  }),
                }}
                message='or click on "Add" button'
              />
            </div>
            <div
              onClick={onAddLink}
              className={classnames("pt-[6px]", {
                "text-blue-400 hover:text-blue-300 flex  ": !inputError,
                "cursor-not-allowed text-gray-300 hover:text-gray-300":
                  inputError || disabled,
              })}
            >
              Add
            </div>
          </div>

          {/* <Label className="" name="Or create new attachment" />

          <div>
            <Button type="button" onClick={onOpenQuillModal}>
              New attachment
            </Button>
          </div>
          <AttachmentsQuillModal
            quillName="attachmentsQuill"
            attachmentsTitleName="attachmentsTitle"
          /> */}
        </>
      )}
    </div>
  );
}
