import { Form, useFormikContext } from "formik";
import classnames from "classnames";
import React, {
  memo,
  MutableRefObject,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import {
  Button,
  FormikImageUploader,
  FormikInput,
  FormikAdvanceSelect,
  FormikImageUploaderContent,
  Card,
  BorderBadgeRound,
  BorderBadge,
  ProfileBlock,
  ActionGroupProfileBlockModal,
} from "components";

import { IManagementProps } from "./GroupForm.types";
import { FIELD_NAMES, InitActionGroup } from "./GroupForm.constants";
import FormikTextarea from "components/FormikTextarea";

import FormikAutoCompleteSelectField from "components/FormikAutoCompleteSelectField";
import { MAX_TEXT_LENGTH, MAX_TITLE_LENGTH } from "./GroupForm.schema";
import { FormikMultiSelectWithTime } from "./GroupFormSkillsField.component";

import Attachments from "modules/Attachments/Attachments";
import FormikTags from "components/FormikTags/FormikTags";
import { useQueries, useQueryClient } from "react-query";
import { IAISimilar, IAISuggestions, IFeedbackArea } from "types";
import { twMerge } from "tailwind-merge";
import { APP_ROUTES, CONTENT_QUALITY_LEVELS } from "const";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { ReactComponent as CarouselArrowLeft } from "assets/images/carousel_arrow_left.svg";
import { ReactComponent as CarouselArrowRight } from "assets/images/carousel_arrow_right.svg";
import { AISimilarityLeave } from "components/AISimilarityLeave";
import axios from "axios";
import { findActionGroup } from "services";
import { useMe, useWindowDimensions } from "hooks";
import { AISimilarityModal } from "components/AISimilarityModal";
import { ReactComponent as Like } from "assets/images/like.svg";
import { ReactComponent as Dislike } from "assets/images/dislike.svg";
import { isNil } from "lodash";
import { isMobile, isTablet } from "react-device-detect";

function Item({
  title,
  content,
  isLink = false,
}: {
  title: string;
  content: React.ReactNode;
  isLink?: boolean;
}) {
  return (
    <>
      <span className="font-light leading-[30px] lg:text-right text-sm text-[#999999]">
        {title}
      </span>
      <span className="font-normal leading-[30px] text-[#00000099] flex align-center">
        {isLink ? (
          <a href={"https://" + content} target="_blank" rel="noreferrer">
            {content}
          </a>
        ) : (
          content
        )}
      </span>
    </>
  );
}

interface AuthenticationPanelProps
  extends React.HTMLAttributes<HTMLDivElement>,
    IManagementProps {
  className?: string;
  similarRef: MutableRefObject<boolean>;
  suggestionsRef: MutableRefObject<boolean>;
  onClose: () => void;
}
const options = [
  {
    value: "Existing Group",
    label: "Existing Group",
  },
  {
    value: "New Group",
    label: "New Group",
  },
];
function GroupForm(props: AuthenticationPanelProps) {
  const params = useParams<{ id: string }>();
  const location = useLocation();
  const { data: meData } = useMe();
  const { errors, values, isSubmitting, submitCount, submitForm } =
    useFormikContext<InitActionGroup>();
  const queryClient = useQueryClient();
  const allMutations = queryClient
    .getMutationCache()
    .findAll({ mutationKey: ["createAIAnalyticsGroups"] });

  const similarGroupMutation = queryClient
    .getMutationCache()
    .findAll({ mutationKey: ["createAISimilarGroup"] });

  console.debug("similarGroupMutation", similarGroupMutation);

  const [showSimilarityModal, setShowSimilarityModal] = useState(false);
  const [similarityModalInteracted, setSimilarityModalInteracted] =
    useState(false);

  const latestAIMutation = useMemo(
    () =>
      allMutations && allMutations.length > 0
        ? //@ts-ignore
          allMutations[allMutations.length - 1]?.state?.data?.data
        : //@ts-ignore
          allMutations[0]?.state?.data?.data,
    [allMutations]
  ) as IAISuggestions | undefined;

  const latestSimilarAIMutation = useMemo(
    () =>
      similarGroupMutation && similarGroupMutation.length > 0
        ? //@ts-ignore
          similarGroupMutation[similarGroupMutation.length - 1]?.state?.data
            ?.data
        : //@ts-ignore
          similarGroupMutation[0]?.state?.data?.data,
    [similarGroupMutation]
  ) as IAISimilar | undefined;

  const moreFeedback = latestAIMutation?.more_feedback?.quality;
  const defaultValueForProblemAreaWithMoreFeedback: IFeedbackArea = {
    input_name: "",
    quality: "Poor" as CONTENT_QUALITY_LEVELS,
    user_input: "",
    feedback: "",
    suggested_texts: [],
  };

  const similarMutationRef = useRef<IAISimilar | undefined>(undefined);
  const submitCountRef = useRef<number>(0);
  const updateScenario = useMemo(
    () => !!params.id && location.pathname.includes(APP_ROUTES.ACTION_GROUP),
    [location.pathname, params.id]
  );

  useEffect(() => {
    if (!updateScenario && submitCount > submitCountRef.current) {
      submitCountRef.current = submitCount;
      setSimilarityModalInteracted(false);
    }
  }, [submitCount, updateScenario]);

  useEffect(() => {
    if (
      !updateScenario &&
      !props.similarRef.current &&
      !similarityModalInteracted &&
      latestSimilarAIMutation &&
      latestSimilarAIMutation?.potential_similarity_id?.length > 0 &&
      !showSimilarityModal
    ) {
      similarMutationRef.current = latestSimilarAIMutation;
      props.suggestionsRef.current = true;
      props.similarRef.current = true;
      setShowSimilarityModal(true);
    }
  }, [
    updateScenario,
    latestSimilarAIMutation,
    props.similarRef,
    showSimilarityModal,
    similarityModalInteracted,
    submitCount,
    props.suggestionsRef,
  ]);

  const groupQueryIds = latestSimilarAIMutation?.potential_similarity_id || [];

  const groupQueries = useQueries({
    queries: groupQueryIds?.map((id) => {
      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();
      return {
        queryKey: ["similarGroup", id],
        queryFn: () => findActionGroup({ id: id, source: source }),
      };
    }),
  });

  const mappedGroups =
    groupQueries && groupQueries.length > 0
      ? groupQueries
          ?.filter((query) => (query?.data?.data as unknown as string) !== "")
          .map((query) => {
            return query.data?.data || undefined;
          })
      : [];

  const [showLeaveModal, setShowLeaveModal] = useState(false);

  const navigate = useNavigate();
  const dimensions = useWindowDimensions();
  const [currentSelectedGroupId, setcurrentSelectedGroupId] = useState<
    string | undefined
  >(undefined);

  return (
    <>
      <Form className="flex flex-col flex-1">
        <div className="px-4 sm:px-20 flex-1 eleven-seventh-step  flex flex-col gap-2">
          {props.showIssueFields && (
            <>
              <FormikAdvanceSelect
                isSearchable={false}
                options={[
                  { label: "Private", value: "true" },
                  { label: "Public", value: "false" },
                ]}
                isDisabled={props?.suggestionsRef.current}
                closeMenuOnSelect
                styles={{
                  indicatorsContainer: () => ({
                    display: "none",
                  }),
                  menuList: (provided) => ({
                    ...provided,
                    padding: "0px",
                  }),
                }}
                placeholder="Select privacy setting"
                label="Privacy Settings"
                name={FIELD_NAMES.IS_PRIVATE}
                message={
                  "Should group discussions be private or open for the public to view? Only group members can comment"
                }
              />
              <FormikInput
                disabled={props?.suggestionsRef.current}
                checkAIContentQuality={!!latestAIMutation}
                AISuggestionProblemArea={
                  latestAIMutation?.feedback_areas?.find(
                    (problemArea) =>
                      problemArea.input_name.toLocaleLowerCase() === "title"
                  ) ||
                  (moreFeedback
                    ? {
                        ...defaultValueForProblemAreaWithMoreFeedback,
                        input_name: FIELD_NAMES.TITLE,
                      }
                    : undefined)
                }
                AISuggestionFieldName={FIELD_NAMES.SUGGESTED_TITLE}
                autoComplete="nope"
                label="Title"
                name={FIELD_NAMES.TITLE}
                placeholder="Title"
                message={
                  <div className="flex justify-between text-gray-400  font-light text-sm">
                    <span>What is the title/name of your group?</span>
                    <span
                      className={classnames({
                        "text-red-400": errors?.title === "max",
                      })}
                    >{`${values?.title?.length}/${MAX_TITLE_LENGTH}`}</span>
                  </div>
                }
              />
              <FormikInput
                forUrlInput
                disabled={props?.suggestionsRef.current}
                name={FIELD_NAMES.CONTACT_URL}
                label="Website"
                placeholder="www.example.com"
                message={"Website, if available"}
              />
              <FormikTextarea
                disabled={props?.suggestionsRef.current}
                checkAIContentQuality={!!latestAIMutation}
                AISuggestionProblemArea={
                  latestAIMutation?.feedback_areas?.find(
                    (problemArea) =>
                      problemArea.input_name.toLocaleLowerCase() === "goals"
                  ) ||
                  (moreFeedback
                    ? {
                        ...defaultValueForProblemAreaWithMoreFeedback,
                        input_name: FIELD_NAMES.DESCRIPTION,
                      }
                    : undefined)
                }
                AISuggestionFieldName={FIELD_NAMES.SUGGESTED_DESCRIPTION}
                autoComplete="nope"
                label="Goals"
                name={FIELD_NAMES.DESCRIPTION}
                placeholder="Goals"
                className="mt-4"
                showOnlyCustomMessage={errors?.description === "max"}
                message={
                  <div className="flex justify-between text-gray-400  font-light text-sm">
                    <span>
                      Please add more details about the goals and objectives of
                      this group
                    </span>
                    <span
                      className={classnames({
                        "text-red-400": errors?.description === "max",
                      })}
                    >{`${values?.description?.length}/${MAX_TEXT_LENGTH}`}</span>
                  </div>
                }
              />
              <FormikTextarea
                disabled={props?.suggestionsRef.current}
                checkAIContentQuality={!!latestAIMutation}
                AISuggestionProblemArea={
                  latestAIMutation?.feedback_areas?.find(
                    (problemArea) =>
                      problemArea.input_name.toLocaleLowerCase() === "schedule"
                  ) ||
                  (moreFeedback
                    ? {
                        ...defaultValueForProblemAreaWithMoreFeedback,
                        input_name: FIELD_NAMES.SCHEDULE,
                      }
                    : undefined)
                }
                AISuggestionFieldName={FIELD_NAMES.SUGGESTED_SCHEDULE}
                autoComplete="nope"
                label="Schedule"
                name={FIELD_NAMES.SCHEDULE}
                placeholder="Schedule"
                className="mt-1"
                showOnlyCustomMessage={errors?.schedule === "max"}
                message={
                  <div className="flex justify-between text-gray-400  font-light text-sm">
                    <span>
                      Please add some info about the schedule of any regular
                      group activities
                    </span>
                    <span
                      className={classnames({
                        "text-red-400": errors?.schedule === "max",
                      })}
                    >{`${values?.schedule?.length}/${MAX_TEXT_LENGTH}`}</span>
                  </div>
                }
              />
              <FormikTextarea
                disabled={props?.suggestionsRef.current}
                checkAIContentQuality={!!latestAIMutation}
                AISuggestionProblemArea={
                  latestAIMutation?.feedback_areas?.find(
                    (problemArea) =>
                      problemArea.input_name.toLocaleLowerCase() === "contact"
                  ) ||
                  (moreFeedback
                    ? {
                        ...defaultValueForProblemAreaWithMoreFeedback,
                        input_name: FIELD_NAMES.CONTACT,
                      }
                    : undefined)
                }
                AISuggestionFieldName={FIELD_NAMES.SUGGESTED_CONTACT}
                autoComplete="nope"
                label="Contact"
                name={FIELD_NAMES.CONTACT}
                placeholder="Contact"
                className="mt-1"
                showOnlyCustomMessage={errors?.contact === "max"}
                message={
                  <div className="flex justify-between text-gray-400  font-light text-sm">
                    <span>
                      Key contact information such as phone number, email,
                      physical address
                    </span>
                    <span
                      className={classnames({
                        "text-red-400": errors?.contact === "max",
                      })}
                    >{`${values?.contact?.length}/${MAX_TEXT_LENGTH}`}</span>
                  </div>
                }
              />
              <FormikAutoCompleteSelectField
                options={options}
                isDisabled={props?.suggestionsRef.current}
                disableOption={false}
                label="Status"
                name={FIELD_NAMES.STAGE}
              />

              <FormikImageUploader
                groupStyle
                disabled={props?.suggestionsRef.current}
                name={FIELD_NAMES.LOGO}
                label="Group logo"
                userName={`${values.title}`}
                Content={FormikImageUploaderContent}
                className="flex flex-col items-start gap-2"
                classNames={{
                  label: "flex justify-center mt-[10px] font-semibold",
                }}
              />

              <FormikMultiSelectWithTime
                disabled={props?.suggestionsRef.current}
                formValues={values.skills}
                name={FIELD_NAMES.SKILLS}
              />

              <Attachments disabled={props?.suggestionsRef.current} />
              <FormikTags
                disabled={props?.suggestionsRef.current}
                fieldName={FIELD_NAMES.TAGS}
                label="Labels (Optional)"
              />
            </>
          )}
        </div>

        {!!errors?.validator! && (
          <div className="w-full px-12">
            <p className=" text-red-400 text-sm font-normal">
              {errors?.validator! as string}
            </p>
          </div>
        )}
        <div className="border-b border-gray-200 w-full h-[1px] mt-10" />
        <div className="mt-4 flex gap-4 justify-end px-4 sm:px-20">
          <Button
            isReverse
            type="button"
            className=" justify-center border"
            disabled={isSubmitting}
            onClick={props.onClose}
          >
            Cancel
          </Button>
          <button
            type="submit"
            disabled={isSubmitting || !!errors?.validator}
            className={twMerge(
              classnames(
                "w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-3 bg-quality-content-green text-base font-medium text-white hover:bg-quality-content-green focus:outline-none  sm:ml-3 sm:w-auto sm:text-sm"
              )
            )}
          >
            Review and Save
          </button>
        </div>
      </Form>
      {showSimilarityModal && (
        <AISimilarityModal
          showCloseIcon={false}
          setShow={(val) => {
            setSimilarityModalInteracted(true);
            setShowSimilarityModal(val);
            props.similarRef.current = true;
          }}
          header={
            <div className="flex flex-col items-start gap-2 py-4  ">
              <p
                className={twMerge(
                  classnames("font-semibold  leading-0", {
                    "text-2xl":
                      !isMobile && !isTablet && !(dimensions.width < 641),
                    "text-lg": isMobile || isTablet || dimensions.width < 641,
                  })
                )}
              >
                We found a potentially similar post. Is this the same group?
              </p>
              {(isMobile || isTablet || dimensions.width < 641) && (
                <button
                  type="button"
                  onClick={() => {
                    submitForm();
                  }}
                  disabled={isSubmitting || !!errors?.validator}
                  className={twMerge(
                    classnames(
                      "w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-3 bg-quality-content-green text-base font-medium text-white hover:bg-quality-content-green focus:outline-none  sm:ml-3 sm:w-auto sm:text-sm"
                    )
                  )}
                >
                  Publish My Action Group Anyway
                </button>
              )}
            </div>
          }
          content={
            <div className="  flex flex-row gap-4 relative w-full">
              <div
                className={twMerge(
                  classnames("z-[1] absolute right-0 w-[75px] h-full ", {
                    "bg-[linear-gradient(270deg,_#fff,_#fff_42%,_#0083f500)]":
                      mappedGroups?.length > 1 &&
                      !isMobile &&
                      !isTablet &&
                      !(dimensions.width < 641),
                  })
                )}
              />
              {mappedGroups?.length > 0 ? (
                <>
                  {isMobile || isTablet || dimensions.width < 641 ? (
                    <>
                      <div className="flex flex-col gap-2 max-h-[350px] overflow-y-scroll">
                        {mappedGroups?.map((group) => {
                          const date =
                            group?.createdAt &&
                            new Date(group?.createdAt).toLocaleDateString(
                              "en-GB",
                              {
                                year: "numeric",
                                month: "long",
                                day: "2-digit",
                              }
                            );

                          const formattedAddress =
                            group?.solutions &&
                            group?.solutions[0] &&
                            group?.solutions[0].issue?.location
                              ?.formattedAddress;

                          const isSoftDeleted = !!group?.deletedAt;
                          const isLikesDislikes =
                            !isNil(group?.likes) && !isNil(group?.dislikes);
                          return (
                            <>
                              <Card
                                shouldHover={false}
                                className={classnames(
                                  "flex-1 xs:max-w-[480px] sm:max-w-[616px] justify-between z-40 border border-gray-500",
                                  {
                                    "bg-gray-100 pointer-events-none select-none":
                                      isSoftDeleted,
                                  }
                                )}
                                header={
                                  <div className="flex justify-between items-center pb-1">
                                    <div className="grid grid-flow-row gap-x-2 items-center max-h-[70px] mr-2">
                                      <span className="font-bold leading-6 text-lg line-clamp-2">
                                        {group?.title}
                                      </span>
                                      <span className="leading-6 text-sm  text-gray-400 line-clamp-1 font-light">
                                        {!!formattedAddress
                                          ? formattedAddress
                                          : "No Address"}{" "}
                                      </span>
                                    </div>
                                  </div>
                                }
                                classNames={{
                                  header:
                                    "px-4 sm:px-9  flex-1 sm:max-w-[616px]",
                                  content:
                                    "px-4 sm:px-9  flex-1 sm:max-w-[616px]",
                                  footer:
                                    "px-3 sm:px-9 h-[60px] flex-1 sm:max-w-[616px]",
                                }}
                                content={
                                  <div className="grid grid-cols-[1fr] lg:grid-cols-[auto_1fr] gap-y-2 gap-x-6 text-left">
                                    <Item
                                      title="Goals"
                                      content={group?.description}
                                    />
                                    <Item
                                      title="Schedule"
                                      content={group?.schedule}
                                    />
                                    <Item
                                      title="Contact"
                                      content={group?.address}
                                    />
                                    <Item
                                      title="Website"
                                      content={group?.contactUrl}
                                      isLink
                                    />
                                    <Item
                                      title="Skills Required"
                                      content={
                                        <>
                                          {group?.skills &&
                                          group?.skills.length < 1 ? (
                                            <div>No skills assigned</div>
                                          ) : (
                                            <div className="flex items-start flex-wrap  gap-4 pt-1">
                                              {group?.skills?.map((skill) => {
                                                return (
                                                  <BorderBadgeRound
                                                    key={skill.id}
                                                    className="flex"
                                                    withNotification
                                                    notificationContent={`${
                                                      skill?.timeRequired || 0
                                                    } ${
                                                      Number(
                                                        skill.timeRequired
                                                      ) === 1
                                                        ? "hour"
                                                        : "hours"
                                                    } per week`}
                                                    classificationName={
                                                      skill?.skill?.name
                                                    }
                                                  >
                                                    {skill?.skill?.name}
                                                  </BorderBadgeRound>
                                                );
                                              })}
                                            </div>
                                          )}
                                        </>
                                      }
                                    />
                                    {group?.tags && group?.tags?.length > 0 && (
                                      <Item
                                        title="Labels"
                                        content={
                                          <div className="flex items-center flex-wrap gap-2 py-1">
                                            {group?.tags?.map((tag) => (
                                              <BorderBadge
                                                key={tag}
                                                classificationName={tag}
                                                styleLess
                                                classNames={{
                                                  text: "text-gray-500 text-xs underline",
                                                  contend: "flex",
                                                }}
                                              >
                                                {tag}
                                              </BorderBadge>
                                            ))}
                                          </div>
                                        }
                                      />
                                    )}
                                  </div>
                                }
                                footer={
                                  <>
                                    <div className="flex flex-row items-center gap-2 sm:gap-10 pr-1 ">
                                      <ActionGroupProfileBlockModal
                                        isModalVariant
                                        showMembers={false}
                                        showCreatedAt={false}
                                        //@ts-ignore
                                        actionGroupUsers={
                                          group?.actionGroupUsers
                                        }
                                        membersCount={String(
                                          group?.membersCount
                                        )}
                                        createdAt={date}
                                        profileImage={group?.user?.avatar}
                                        groupImage={group?.logo}
                                      />
                                      <div className="flex flex-row maxSm:flex-wrap gap-2 items-center nine-fifth-step ">
                                        {isLikesDislikes && (
                                          <>
                                            <div className="flex flex-col gap-0.5">
                                              <span>
                                                <Like stroke={"white"} />
                                              </span>
                                            </div>

                                            <div className="flex flex-col gap-0.5">
                                              <span>
                                                <Dislike stroke={"white"} />
                                              </span>
                                            </div>
                                          </>
                                        )}
                                      </div>
                                    </div>
                                    {
                                      <Button
                                        inGroupStyle={false}
                                        type="button"
                                        onClick={() => {
                                          setcurrentSelectedGroupId(group?.id);
                                          setShowLeaveModal(true);
                                        }}
                                        className="!pointer-events-auto"
                                      >
                                        Join Existing Discussion
                                      </Button>
                                    }
                                  </>
                                }
                              />
                            </>
                          );
                        })}
                      </div>
                    </>
                  ) : (
                    <Carousel
                      className="w-full"
                      showArrows={true}
                      showIndicators={false}
                      showThumbs={false}
                      centerMode
                      showStatus={false}
                      centerSlidePercentage={80}
                      renderArrowPrev={(onClickHandler, hasPrev, label) => (
                        <div
                          onClick={onClickHandler}
                          title={label}
                          className={twMerge(
                            classnames(
                              "absolute left-0 top-1/2 transform -translate-y-1/2 z-50 cursor-pointer  ",
                              {
                                hidden: !hasPrev,
                              }
                            )
                          )}
                        >
                          <CarouselArrowLeft height="32" width="32" />
                        </div>
                      )}
                      renderArrowNext={(onClickHandler, hasNext, label) => (
                        <div
                          onClick={onClickHandler}
                          title={label}
                          className={twMerge(
                            classnames(
                              "absolute right-0 top-1/2 transform -translate-y-1/2 z-50 cursor-pointer  ",
                              {
                                hidden: !hasNext,
                              }
                            )
                          )}
                        >
                          <CarouselArrowRight height="32" width="32" />
                        </div>
                      )}
                    >
                      {mappedGroups?.map((group) => {
                        const date =
                          group?.createdAt &&
                          new Date(group?.createdAt).toLocaleDateString(
                            "en-GB",
                            {
                              year: "numeric",
                              month: "long",
                              day: "2-digit",
                            }
                          );

                        const formattedAddress =
                          group?.solutions &&
                          group?.solutions[0] &&
                          group?.solutions[0].issue?.location?.formattedAddress;

                        const isSoftDeleted = !!group?.deletedAt;
                        const isLikesDislikes =
                          !isNil(group?.likes) && !isNil(group?.dislikes);
                        return (
                          <>
                            <Card
                              shouldHover={false}
                              className={classnames(
                                "flex-1 xs:max-w-[480px] sm:max-w-[616px] justify-between z-40 border border-gray-500 h-full",
                                {
                                  "bg-gray-100 pointer-events-none select-none":
                                    isSoftDeleted,
                                }
                              )}
                              header={
                                <div className="flex justify-between items-center pb-1">
                                  <div className="grid grid-flow-row gap-x-2 items-center max-h-[70px] mr-2">
                                    <span className="font-bold leading-6 text-lg line-clamp-2">
                                      {group?.title}
                                    </span>
                                    <span className="leading-6 text-sm  text-gray-400 line-clamp-1 font-light">
                                      {!!formattedAddress
                                        ? formattedAddress
                                        : "No Address"}{" "}
                                    </span>
                                  </div>
                                </div>
                              }
                              classNames={{
                                header: "px-4 sm:px-9  flex-1 sm:max-w-[616px]",
                                content:
                                  "px-4 sm:px-9  flex-1 sm:max-w-[616px]",
                                footer:
                                  "px-3 sm:px-9 h-[60px] flex-1 sm:max-w-[616px]",
                              }}
                              content={
                                <div className="grid grid-cols-[1fr] lg:grid-cols-[auto_1fr] gap-y-2 gap-x-6 text-left">
                                  <Item
                                    title="Goals"
                                    content={group?.description}
                                  />
                                  <Item
                                    title="Schedule"
                                    content={group?.schedule}
                                  />
                                  <Item
                                    title="Contact"
                                    content={group?.address}
                                  />
                                  <Item
                                    title="Website"
                                    content={group?.contactUrl}
                                    isLink
                                  />
                                  <Item
                                    title="Skills Required"
                                    content={
                                      <>
                                        {group?.skills &&
                                        group?.skills.length < 1 ? (
                                          <div>No skills assigned</div>
                                        ) : (
                                          <div className="flex items-start flex-wrap  gap-4 pt-1">
                                            {group?.skills?.map((skill) => {
                                              return (
                                                <BorderBadgeRound
                                                  key={skill.id}
                                                  className="flex"
                                                  withNotification
                                                  notificationContent={`${
                                                    skill?.timeRequired || 0
                                                  } ${
                                                    Number(
                                                      skill.timeRequired
                                                    ) === 1
                                                      ? "hour"
                                                      : "hours"
                                                  } per week`}
                                                  classificationName={
                                                    skill?.skill?.name
                                                  }
                                                >
                                                  {skill?.skill?.name}
                                                </BorderBadgeRound>
                                              );
                                            })}
                                          </div>
                                        )}
                                      </>
                                    }
                                  />
                                  {group?.tags && group?.tags?.length > 0 && (
                                    <Item
                                      title="Labels"
                                      content={
                                        <div className="flex items-center flex-wrap gap-2 py-1">
                                          {group?.tags?.map((tag) => (
                                            <BorderBadge
                                              key={tag}
                                              classificationName={tag}
                                              styleLess
                                              classNames={{
                                                text: "text-gray-500 text-xs underline",
                                                contend: "flex",
                                              }}
                                            >
                                              {tag}
                                            </BorderBadge>
                                          ))}
                                        </div>
                                      }
                                    />
                                  )}
                                </div>
                              }
                              footer={
                                <>
                                  <div className="flex flex-row items-center gap-2 sm:gap-10 pr-1 ">
                                    <ActionGroupProfileBlockModal
                                      isModalVariant
                                      showMembers={false}
                                      showCreatedAt={false}
                                      //@ts-ignore
                                      actionGroupUsers={group?.actionGroupUsers}
                                      membersCount={String(group?.membersCount)}
                                      createdAt={date}
                                      profileImage={group?.user?.avatar}
                                      groupImage={group?.logo}
                                    />
                                    <div className="flex flex-row maxSm:flex-wrap gap-2 items-center nine-fifth-step ">
                                      {isLikesDislikes && (
                                        <>
                                          <div className="flex flex-col gap-0.5">
                                            <span>
                                              <Like stroke={"white"} />
                                            </span>
                                          </div>

                                          <div className="flex flex-col gap-0.5">
                                            <span>
                                              <Dislike stroke={"white"} />
                                            </span>
                                          </div>
                                        </>
                                      )}
                                    </div>
                                  </div>
                                  {
                                    <Button
                                      inGroupStyle={false}
                                      type="button"
                                      onClick={() => {
                                        setcurrentSelectedGroupId(group?.id);
                                        setShowLeaveModal(true);
                                      }}
                                      className="!pointer-events-auto"
                                    >
                                      Join Existing Discussion
                                    </Button>
                                  }
                                </>
                              }
                            />
                          </>
                        );
                      })}
                    </Carousel>
                  )}
                </>
              ) : (
                <div>No similar groups found</div>
              )}
            </div>
          }
          classNames={{
            footer: "!justify-center",
            header: " h-max justify-start",
          }}
          footer={
            <div className=" flex gap-4 items-center px-4  ">
              {!isMobile && !isTablet && !(dimensions.width < 641) && (
                <button
                  type="button"
                  onClick={() => {
                    submitForm();
                  }}
                  disabled={isSubmitting || !!errors?.validator}
                  className={twMerge(
                    classnames(
                      "w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-3 bg-quality-content-green text-base font-medium text-white hover:bg-quality-content-green focus:outline-none  sm:ml-3 sm:w-auto sm:text-sm"
                    )
                  )}
                >
                  Publish My Action Group Anyway
                </button>
              )}
            </div>
          }
        />
      )}
      {showLeaveModal && (
        <AISimilarityLeave
          setShow={(val) => {
            setShowLeaveModal(val);
          }}
          header={<></>}
          content={
            <div className="flex flex-col items-center gap-4 py-1  ">
              <p className="font-semibold text-xl leading-0">Warning</p>
              <p className="font-light text-sm">
                Your draft will be discarded when you join the existing
                discussion. Continue?
              </p>
            </div>
          }
          footer={
            <div className=" flex gap-4 justify-end ">
              <Button
                isReverse
                type="button"
                className=" justify-center border"
                onClick={() => {
                  setShowLeaveModal(false);
                }}
              >
                No, go back
              </Button>
              <button
                type="button"
                onClick={() => {
                  setShowLeaveModal(false);
                  setShowSimilarityModal(false);
                  props.similarRef.current = false;
                  if (props?.onClose) {
                    props?.onClose();
                    props.similarRef.current = false;
                    navigate(
                      APP_ROUTES.ACTION_GROUP + "/" + currentSelectedGroupId
                    );
                  }
                }}
                className={twMerge(
                  classnames(
                    "w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-3 bg-quality-content-green text-base font-medium text-white hover:bg-quality-content-green focus:outline-none  sm:ml-3 sm:w-auto sm:text-sm"
                  )
                )}
              >
                Yes, join discussion
              </button>
            </div>
          }
          classNames={{
            content: "text-black",
            footer: "pb-[40px] pt-0",
          }}
        />
      )}
    </>
  );
}

export default memo(GroupForm);
