import { useIssue, useMe, useSolutions } from "hooks";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import {
  Button,
  CommentsCard,
  IssueForm,
  PromptModal,
  SolutionForm,
} from "components";
import { useNavigate, useParams } from "react-router-dom";
import { getRating } from "utils";
import { ReactComponent as Location } from "assets/images/location.svg";
import { IssueDetailCard } from "./IssueDetailCard";
import { SlideOver } from "components/SlideOver";
import Heading from "components/Heading/Heading";
import { SolutionsCard } from "./SolutionsCard";
import { APP_ROUTES } from "const";
import { ImpactRankingCard } from "./ImpactRankingCard";
import { Dialog } from "@headlessui/react";
import { useQuery, useQueryClient } from "react-query";
import { IAISuggestions } from "types";

function Issue() {
  const navigate = useNavigate();
  const params = useParams<{ id: string }>();
  const { data: solutionsData } = useSolutions({
    limit: 9999,
    issue: params.id,
  });
  const { data: me } = useMe();
  const { data } = useIssue({ id: params.id, suspense: true });
  //
  const [ratingsForm, setRatingsForm] = useState(false);
  const [issuesForm, setIssuesForm] = useState(false);
  const [solutionsForm, setSolutionsForm] = useState(false);
  const [createdSolutionId, setCreatedSolutionId] = useState<
    string | undefined
  >(undefined);
  const [showSolutionPromptModal, setShowSolutionPromptModal] = useState(false);
  const [showSuccessSolutionPromptModal, setShowSuccessSolutionPromptModal] =
    useState(false);

  const onEditRatings = useCallback(() => {
    if (data?.data.user?.id && me?.data.id) {
      setRatingsForm(true);
    }
  }, [data?.data.user?.id, me?.data.id]);

  const onEditIssues = useCallback(() => {
    if (data?.data.user?.id === me?.data.id) {
      setIssuesForm(true);
    }
  }, [data?.data.user?.id, me?.data.id]);

  const onAddSolutions = useCallback(() => {
    if (me?.data.id) {
      setShowSolutionPromptModal(true);
    }
  }, [me?.data.id]);
  useEffect(() => {
    if (createdSolutionId) {
      setShowSuccessSolutionPromptModal(true);
    }
  }, [createdSolutionId]);

  const formattedAddress = data?.data?.location?.formattedAddress;
  const isSoftDeleted = !!data?.data.deletedAt;

  const [mutationCacheRerenderCounter, setMutationCacheRerenderCounter] =
    useState(0);

  const queryClient = useQueryClient();

  const subscribeCacheCallback = useCallback((queryCacheNotifyEvent) => {
    if (!queryCacheNotifyEvent) {
      return;
    }
    setMutationCacheRerenderCounter((prev) => prev + 1);
  }, []);

  queryClient.getMutationCache().subscribe(subscribeCacheCallback);

  const allAISolutionMutations = queryClient
    .getMutationCache()
    .findAll({ mutationKey: ["createAIAnalyticsSolution"] });
  const allAIIssueMutations = queryClient.getMutationCache().findAll({
    mutationKey: ["createAIAnalyticsIssue"],
  });
  const latestSolutionAIMutation = useMemo(
    () =>
      allAISolutionMutations && allAISolutionMutations.length > 0
        ? //@ts-ignore
          allAISolutionMutations[allAISolutionMutations.length - 1]?.state?.data
            ?.data
        : //@ts-ignore
          allAISolutionMutations[0]?.state?.data?.data,
    [allAISolutionMutations]
  ) as IAISuggestions | undefined;
  const latestIssueAIMutation = useMemo(
    () =>
      allAIIssueMutations && allAIIssueMutations.length > 0
        ? //@ts-ignore
          allAIIssueMutations[allAIIssueMutations.length - 1]?.state?.data?.data
        : //@ts-ignore
          allAIIssueMutations[0]?.state?.data?.data,
    [allAIIssueMutations]
  ) as IAISuggestions | undefined;

  const moreFeedbackQualitySolution = useMemo(() => {
    if (!latestSolutionAIMutation) {
      return undefined;
    }
    return latestSolutionAIMutation.more_feedback;
  }, [latestSolutionAIMutation]);

  const moreFeedbackQualityIssue = useMemo(() => {
    if (!latestIssueAIMutation) {
      return undefined;
    }
    return latestIssueAIMutation.more_feedback;
  }, [latestIssueAIMutation]);

  return (
    <div className=" sm:mx-[5%] pt-8 pb-4 max-w-[1600px]">
      <div className="my-2 flex flex-col sm:flex-row gap-2 flex-wrap">
        <span
          onClick={() => {
            navigate(APP_ROUTES.ISSUES);
          }}
          className="text-[#606060] font-normal text-md cursor-pointer"
        >
          &gt; Issues
        </span>
        <span className="text-[#606060] font-normal text-md cursor-pointer">
          &gt; Issue: {data?.data.title}
        </span>
      </div>
      <Heading type="h1">
        {data?.data.title} {isSoftDeleted && "(This issue was deleted)"}
      </Heading>
      <div className="flex gap-2 mt-2 items-center">
        <Location />
        {!!data?.data?.location && (
          <span className="text-black text-md">
            {formattedAddress || "No location"}
          </span>
        )}
      </div>
      <div className="grid pt-12 lg:grid-cols-2 gap-6">
        <IssueDetailCard
          id={data?.data?.user?.id}
          details={data?.data.details || ""}
          importance={data?.data.importance || ""}
          origin={data?.data.origin || ""}
          profileImage={data?.data?.user?.avatar}
          firstName={data?.data?.user?.firstName}
          lastName={data?.data?.user?.lastName}
          createdAt={data?.data.createdAt}
          rating={getRating(data?.data.rating)}
          primaryClassification={data?.data.primaryClassification}
          secondaryClassification={data?.data.secondaryClassification}
          onEdit={onEditIssues}
          allowEdit={data?.data.user?.id === me?.data.id}
          email={data?.data.user?.email}
          score={data?.data.user?.reputationScore}
          attachments={data?.data.attachments}
          tags={data?.data.tags}
          issueId={data?.data.id}
          isSoftDeleted={isSoftDeleted}
        />

        <ImpactRankingCard
          issue={data?.data.id}
          onEdit={onEditRatings}
          allowEdit={!!data?.data.user?.id && !!me?.data.id}
          isSoftDeleted={isSoftDeleted}
        />
        <SolutionsCard
          solutions={solutionsData?.data.items}
          details={data?.data.details || ""}
          importance={data?.data.importance || ""}
          origin={data?.data.origin || ""}
          profileImage={data?.data?.user?.avatar}
          firstName={data?.data?.user?.firstName}
          lastName={data?.data?.user?.lastName}
          createdAt={data?.data.createdAt}
          primaryClassification={data?.data.primaryClassification}
          secondaryClassification={data?.data.secondaryClassification}
          onEdit={onAddSolutions}
          allowEdit={!!me?.data.id}
          email={data?.data.user?.email}
          score={data?.data.user?.reputationScore}
          isSoftDeleted={isSoftDeleted}
        />
        <CommentsCard
          entityOwnerId={data?.data.user?.id}
          isSoftDeleted={isSoftDeleted}
        />
      </div>
      <SlideOver
        open={!!issuesForm}
        close={useCallback(() => {
          setIssuesForm(false);
          queryClient.getMutationCache().clear();
        }, [queryClient])}
        title="Edit issue"
        description={
          <div className="flex flex-col">
            <Dialog.Description className="text-sm  text-gray-500 font-light mt-[14px]">
              This information will be displayed publicly so be careful what you
              share.
            </Dialog.Description>
            {(latestIssueAIMutation?.feedback_areas?.length || 0) > 0 ? (
              <Dialog.Description className="text-sm  text-red-500 font-light">
                Please review the highlighted sections for suggested
                improvements before we can save your post
              </Dialog.Description>
            ) : null}
            {moreFeedbackQualityIssue &&
            (moreFeedbackQualityIssue.quality === "Useless" ||
              moreFeedbackQualityIssue.quality === "Poor") ? (
              <Dialog.Description className="text-sm  text-red-500 font-light">
                {moreFeedbackQualityIssue?.feedback}
              </Dialog.Description>
            ) : null}
          </div>
        }
      >
        <IssueForm
          onSuccess={useCallback(() => {
            setIssuesForm(false);
            queryClient.getMutationCache().clear();
          }, [queryClient])}
          onClose={useCallback(() => {
            setIssuesForm(false);
            queryClient.getMutationCache().clear();
          }, [queryClient])}
          showIssueFields
          showRatings={false}
        />
      </SlideOver>
      <SlideOver
        id="solution-report"
        open={!!solutionsForm}
        close={useCallback(() => {
          setSolutionsForm(false);
          queryClient.getMutationCache().clear();
        }, [queryClient])}
        title="Propose Solution"
        description={
          <div className="flex flex-col">
            <Dialog.Description className="text-sm  text-gray-500 font-light mt-[14px]">
              This information will be displayed publicly so be careful what you
              share.
            </Dialog.Description>
            {(latestSolutionAIMutation?.feedback_areas?.length || 0) > 0 ? (
              <Dialog.Description className="text-sm  text-red-500 font-light">
                Please review the highlighted sections for suggested
                improvements before we can save your post
              </Dialog.Description>
            ) : null}
            {moreFeedbackQualitySolution &&
            (moreFeedbackQualitySolution.quality === "Useless" ||
              moreFeedbackQualitySolution.quality === "Poor") ? (
              <Dialog.Description className="text-sm  text-red-500 font-light">
                {moreFeedbackQualitySolution?.feedback}
              </Dialog.Description>
            ) : null}
          </div>
        }
      >
        <SolutionForm
          onSuccess={useCallback(
            (id?: string) => {
              setCreatedSolutionId(id);
              setSolutionsForm(false);
              queryClient.getMutationCache().clear();
            },
            [setCreatedSolutionId, setSolutionsForm, queryClient]
          )}
          onClose={useCallback(() => {
            setSolutionsForm(false);
            queryClient.getMutationCache().clear();
          }, [setSolutionsForm, queryClient])}
          showSolutionFields
          showSolutionRatings={false}
        />
      </SlideOver>
      <SlideOver
        open={!!ratingsForm}
        close={useCallback(() => {
          setRatingsForm(false);
        }, [])}
        title="Rate issue"
        description="This information will be displayed publicly so be careful what you share. "
      >
        <IssueForm
          onSuccess={useCallback(() => {
            setRatingsForm(false);
          }, [])}
          onClose={useCallback(() => {
            setRatingsForm(false);
          }, [])}
          showIssueFields={false}
          showRatings={true}
        />
      </SlideOver>
      {showSolutionPromptModal && (
        <PromptModal
          setShow={setShowSolutionPromptModal}
          header="Propose Solution"
          content="Before adding a new solution, please ensure a
          similar solution for this issue does not exist already"
          footer={
            <div className="flex flex-row gap-2 justify-between w-full">
              <Button
                inGroupStyle={false}
                isStyleless
                isReverse
                className=" shrink-0 text-[black] border hover:border-black border-gray-500 "
                onClick={() => {
                  setShowSolutionPromptModal(false);
                  setSolutionsForm(true);
                }}
              >
                Continue, I have checked
              </Button>
              <Button
                inGroupStyle={false}
                isStyleless
                isReverse
                className=" text-[black] border hover:border-black border-gray-500 "
                onClick={() => {
                  setShowSolutionPromptModal(false);
                }}
              >
                Cancel
              </Button>
            </div>
          }
        />
      )}
      {showSuccessSolutionPromptModal && createdSolutionId && (
        <PromptModal
          setShow={setShowSuccessSolutionPromptModal}
          header="Propose Solution"
          content="Will you like to create an action group to implement
          this solution?"
          footer={
            <div className="flex flex-row gap-2 justify-between w-full">
              <Button
                inGroupStyle={false}
                isStyleless
                isReverse
                className=" shrink-0 text-[black] border hover:border-black border-gray-500 "
                onClick={() => {
                  setShowSuccessSolutionPromptModal(false);
                  navigate(APP_ROUTES.SOLUTION + "/" + createdSolutionId);
                }}
              >
                Yes
              </Button>
              <Button
                inGroupStyle={false}
                isStyleless
                isReverse
                className=" text-[black] border hover:border-black border-gray-500 "
                onClick={() => {
                  setShowSuccessSolutionPromptModal(false);
                  setCreatedSolutionId(undefined);
                }}
              >
                Maybe later
              </Button>
            </div>
          }
        />
      )}
    </div>
  );
}

export default memo(Issue);
