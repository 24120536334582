import { memo, useCallback, useEffect, useState } from "react";
import { useField } from "formik";

import { IFileInputComponentProps, ImageType } from "types";
import "./FormikImageUploader.styles.css";
import { Label } from "components/Label";
import classnames from "classnames";
import { twMerge } from "tailwind-merge";

// Props
interface IProps {
  label?: string;
  className?: string;
  groupStyle?: boolean;
  readOnly?: boolean;
  motivationalStyle?: boolean;
  disabled?: boolean;
  title?: string;
  classNames?: { label: string };
  name: string;
  Content: (props: IFileInputComponentProps) => JSX.Element;
  required?: boolean;
  userName?: string;
}

// Formik image uploader
function FormikImageUploader({
  name,
  label,
  userName,
  groupStyle,
  title,
  readOnly = false,
  motivationalStyle,
  disabled,
  Content,
  className,
  ...rest
}: IProps) {
  // Use image filed
  const [{ value }, { error, touched }, { setValue, setTouched }] = useField<
    ImageType | string | null
  >(name);
  // Handle callbacks
  const handleChange = useCallback((value) => setValue(value), [setValue]);
  const [ref, setRef] = useState<HTMLInputElement | null>(null);
  const handleBlur = useCallback(() => {
    return setTouched(true);
  }, [setTouched]);
  const handleRemove = useCallback(() => {
    return setValue(null);
  }, [setValue]);
  // Render

  useEffect(() => {
    const cb = function () {
      const reader = new FileReader();
      //@ts-ignore
      const file = this?.files[0];
      reader.addEventListener("load", function () {
        handleChange(Object.assign(file, { dataUrl: reader.result }));
        // handleChange(Object.assign(file, {}));
      });
      if (file) {
        reader.readAsDataURL(file);
      }
    };
    ref?.addEventListener("change", cb);
    return () => {
      ref?.removeEventListener("change", cb);
    };
  }, [handleChange, ref]);

  useEffect(() => {
    const cb = () => {
      handleBlur();
    };
    ref?.addEventListener("onBlur", cb);
    return () => {
      ref?.removeEventListener("onBlur", cb);
    };
  }, [handleBlur, ref]);

  return (
    <div
      className={twMerge(classnames(" relative ", className))}
      id="file-uploader "
    >
      {label && <Label name={label} />}
      <input
        disabled={disabled}
        type="file"
        id="file"
        className="file absolute cursor-pointer"
        accept="image/png, image/jpg"
        ref={(ref) => {
          setRef(ref);
        }}
        //@ts-ignore
        onClick={(event) => (event.target.value = null)}
      />
      <div>
        <Content
          disabled={disabled}
          readOnly={readOnly}
          title={title}
          motivationalStyle={motivationalStyle}
          image={value}
          error={error}
          touched={touched}
          fieldName={name}
          groupStyle={groupStyle}
          userName={userName}
          onRemove={handleRemove}
          label={label}
          onImageUpload={useCallback(() => {
            ref?.click();
          }, [ref])}
          {...rest}
        />
      </div>
    </div>
  );
}

export default memo(FormikImageUploader);
