import { AxiosResponse } from "axios";
import { APP_ROUTES, CONTENT_QUALITY_LEVELS, ENDPOINTS } from "const";
import { FormikHelpers, useFormikContext } from "formik";
import {
  useBlocker,
  useMe,
  useMotivational,
  useMotivationalTags,
  useTags,
} from "hooks";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useMutation, UseMutationOptions, useQueryClient } from "react-query";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  IAIMotivationalSuggestions,
  IAISuggestions,
  IMotivational,
  IMotivationalTag,
  ITag,
} from "types";
import { showErrorMessage, showSuccessMessage } from "utils";
import {
  createMotivational,
  createMotivationalTags,
  updateMotivational,
  updateMotivationalTags,
} from "./MotivationalForm.services";

import { Transition } from "history";
import _ from "lodash";
import {
  InitialMotivational,
  PayloadMotivational,
} from "./MotivationalForm.constants";
import {
  createAISuggestionsMotivational,
  IAIMotivationalProps,
} from "services";

export function useInitialState() {
  const location = useLocation();
  const params = useParams<{ id: string }>();

  const { data: motivational } = useMotivational({
    id: params.id,
    suspense: true,
    keepPreviousData: true,
    enabled:
      !!params.id && location.pathname.includes(APP_ROUTES.MOTIVATIONAL_EDIT),
  });

  return useMemo(() => {
    return new InitialMotivational(motivational?.data);
  }, [motivational]);
}

interface IAIAnalyticsMotivationalProps
  extends Partial<
    UseMutationOptions<AxiosResponse<IAIMotivationalSuggestions>, any, any>
  > {
  id?: string | undefined;
}

export function useCreateAIAnalyticsMotivational({
  ...rest
}: IAIAnalyticsMotivationalProps) {
  const queryClient = useQueryClient();
  return useMutation<AxiosResponse<IAIMotivationalSuggestions>, any, any>(
    (group: IAIMotivationalProps) =>
      createAISuggestionsMotivational({ ...group }),
    {
      mutationKey: ["createAIAnalyticMotivational"],
      onSuccess: (data) => {
        queryClient.setQueryData(
          ["createAIAnalyticMotivational"],
          data.data || {}
        );
      },
      ...rest,
    }
  );
}

interface IMotivationalProps
  extends Partial<
    UseMutationOptions<
      AxiosResponse<IMotivational<string, IMotivationalTag<ITag>>>,
      any,
      any
    >
  > {
  id?: string | undefined;
}

interface IMotivationalTagsProps
  extends Partial<
    UseMutationOptions<
      AxiosResponse<IMotivationalTag<string, string>[]>,
      any,
      any
    >
  > {}

export function useCreateMotivational({ ...rest }: IMotivationalProps) {
  return useMutation<
    AxiosResponse<IMotivational<string, IMotivationalTag<ITag>>>,
    any,
    any
  >(
    (group: IMotivational<string, IMotivationalTag<ITag>>) =>
      createMotivational({ group }),
    {
      ...rest,
    }
  );
}

export function useUpdateMotivational({
  id = "",
  ...rest
}: IMotivationalProps & {
  id: string | undefined;
}) {
  return useMutation<
    AxiosResponse<IMotivational<string, IMotivationalTag<ITag>>>,
    any,
    any
  >(
    (group: IMotivational<string, IMotivationalTag<ITag>>) =>
      updateMotivational({ id, group }),
    {
      ...rest,
    }
  );
}

export function useCreateMotivationalTag({ ...rest }: IMotivationalTagsProps) {
  return useMutation<
    AxiosResponse<IMotivationalTag<string, string>[]>,
    any,
    any
  >(
    (groupTag: IMotivationalTag<string, string>[]) =>
      createMotivationalTags({ groupTag }),
    {
      ...rest,
    }
  );
}

export function useUpdateMotivationalTag({ ...rest }: IMotivationalTagsProps) {
  return useMutation<AxiosResponse<IMotivationalTag[]>, any, any>(
    (groupTag: IMotivationalTag[]) => updateMotivationalTags({ groupTag }),
    {
      ...rest,
    }
  );
}

export function useSubmitHandler(success: () => void) {
  const params = useParams<{ id: string }>();
  const navigate = useNavigate();

  const { mutateAsync: mutateCreateMotivationalAsync } = useCreateMotivational(
    {}
  );
  const { mutateAsync: mutateUpdateMotivationalAsync } = useUpdateMotivational({
    id: params.id,
  });

  const { mutateAsync: mutateCreateAISuggestionMotivationalAsync } =
    useCreateAIAnalyticsMotivational({
      id: params.id,
    });

  const { mutateAsync: mutateCreateMotivationalTagAsync } =
    useCreateMotivationalTag({});
  const { mutateAsync: mutateUpdateMotivationalTagAsync } =
    useUpdateMotivationalTag({});

  const { data: MotivationalForEdit } = useMotivational({
    id: params.id,
    enabled: !!params.id,
  });

  const queryClient = useQueryClient();
  const { data: meData } = useMe({});
  const user = meData?.data.id;

  const { data } = useTags({
    suspense: false,
    keepPreviousData: true,
  });
  const preparedData = data?.data;

  const options = useMemo(() => {
    return _.compact(
      preparedData?.map((item) => ({
        label: item.name,
        value: item.id || "",
      }))
    );
  }, [preparedData]);

  return useCallback(
    async (
      values: InitialMotivational,
      formikHelpers: FormikHelpers<InitialMotivational>
    ) => {
      formikHelpers.setSubmitting(true);
      const invalidate = () => {
        queryClient.invalidateQueries([ENDPOINTS.MOTIVATIONAL_TAGS]);
        queryClient.invalidateQueries([ENDPOINTS.MOTIVATIONAL]);
      };
      const invalidateAndLeave = (id: string) => {
        queryClient.invalidateQueries([ENDPOINTS.MOTIVATIONAL_TAGS]);
        queryClient.invalidateQueries([ENDPOINTS.MOTIVATIONAL]);
        navigate(APP_ROUTES.MOTIVATIONAL + "/" + id);
      };

      try {
        const payload = new PayloadMotivational(values);
        console.debug("Somet", values, payload);
        const preparedTags = options
          ?.filter((tag) => {
            return tag.value === values?.tags?.find((i) => i === tag.value);
          })
          ?.map((tag) => tag.label);
        const motivationalAnalyticsPayload = {
          content_title: values?.title,
          content_text: values?.description,
          content_video_url: values?.videoLink,
          tags: preparedTags,
        };
        const aiSuggestionsReviewResult =
          await mutateCreateAISuggestionMotivationalAsync(
            motivationalAnalyticsPayload
          );
        if (
          aiSuggestionsReviewResult?.data?.feedback?.quality !==
          CONTENT_QUALITY_LEVELS.GOOD
        ) {
          formikHelpers.setTouched({
            title: false,
            description: false,
            videoLink: false,
          });
          document.querySelector("#motivational-report")?.scrollTo(0, 0);
          formikHelpers.setSubmitting(false);
          return;
        }

        if (params.id) {
          await mutateUpdateMotivationalAsync({
            ...payload.getPayload(),
            user,
          });
          showSuccessMessage(" Motivational is updated!");

          await mutateUpdateMotivationalTagAsync({
            motivational: MotivationalForEdit?.data?.id,
            tag: payload.getTags(),
          });
          showSuccessMessage("Motivational tags are updated!");
          success();
          if (MotivationalForEdit?.data?.id) {
            invalidateAndLeave(MotivationalForEdit?.data?.id);
          }
        } else {
          const created = await mutateCreateMotivationalAsync({
            user,
            ...payload.getPayload(),
          });
          showSuccessMessage("Motivational is created!");
          if (values.tags) {
            await mutateCreateMotivationalTagAsync({
              motivational: created.data.id,
              tag: payload.getTags(),
            });
            showSuccessMessage("Motivational tags are created!");
          }

          success();
          if (created.data.id) {
            invalidateAndLeave(created.data.id);
          }
        }
      } catch (error: any) {
        formikHelpers.setSubmitting(false);
        formikHelpers.setErrors(error.response.data);
        showErrorMessage("Error occurred! Check form!");
      }

      formikHelpers.setSubmitting(false);
      invalidate();
    },
    [
      queryClient,
      navigate,
      options,
      mutateCreateAISuggestionMotivationalAsync,
      params.id,
      mutateUpdateMotivationalAsync,
      user,
      mutateUpdateMotivationalTagAsync,
      MotivationalForEdit?.data?.id,
      success,
      mutateCreateMotivationalAsync,
      mutateCreateMotivationalTagAsync,
    ]
  );
}

function useSaveModal() {
  const params = useParams<{ userId: string }>();
  const { data, isFetched: isMeFetched } = useMe();

  const [prevent, setPrevent] = useState(true);

  const {
    values: { tags, ...values },
  } = useFormikContext<InitialMotivational>();
  const { data: prevUserSkills, isFetched: isUserSkillsFetched } =
    useMotivationalTags({ id: params.userId });

  const prevUserSkillsToUpdate = prevUserSkills?.data.map((i) => i.tag.id);

  const preventAction = useMemo(() => {
    const preparedData = { ...data?.data };
    if (prevent && isMeFetched && isUserSkillsFetched) {
      const isSkillsEq = _.isEqual(tags, prevUserSkillsToUpdate);
      const isUserEq = _.isEqual(values, preparedData);

      if (!isSkillsEq || !isUserEq) {
        return true;
      }
    }
    return false;
  }, [
    data?.data,
    isMeFetched,
    isUserSkillsFetched,
    prevUserSkillsToUpdate,
    prevent,
    tags,
    values,
  ]);

  return {
    preventAction,
    setPrevent,
  };
}

export function usePreventTransition() {
  const { preventAction, setPrevent } = useSaveModal();
  const [transition, setTransition] = useState<Transition | null>(null);
  const navigation = useNavigate();
  useBlocker((transition) => {
    setTransition(transition);
  }, preventAction);

  const closeModal = useCallback(() => {
    setTransition(null);
  }, [setTransition]);

  useEffect(() => {
    if (!preventAction) {
      closeModal();
      transition && navigation(transition.location.pathname);
    }
  }, [closeModal, navigation, preventAction, transition]);
  return {
    leave: useCallback(() => {
      setPrevent(false);
    }, [setPrevent]),
    close: useCallback(() => {
      setTransition(null);
    }, []),

    showModal: !!transition,
  };
}
