import classnames from "classnames";

interface IInputWrapperProps {
  isError: boolean;
  children: React.ReactNode;
  className?: string;
  tabIndex?: number;
  noAnimation?: boolean;
  isInput?: boolean;
  id?: string;
}

export function ColorBorderWrapper({
  isError,
  children,
  noAnimation = false,
  isInput = true,
  className,
  ...rest
}: IInputWrapperProps) {
  return (
    <div
      className={classnames(
        "  p-[1px] rounded-[8px] overflow-hidden ",
        {
          " bg-alert": isError,
          " bg-gray-300": !isError,
          "  ": !noAnimation,
          "focus-within:outline focus-within:outline-[1px]  outline-black ":
            isInput,
        },
        className
      )}
      {...rest}
    >
      {children}
    </div>
  );
}
