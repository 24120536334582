import classnames from "classnames";
import { memo } from "react";
import { twMerge } from "tailwind-merge";

interface IProps {
  children: React.ReactNode;
  onRemove: () => void;
  showRemove?: boolean;
  className?: string;
  classNames?: {
    button?: string;
  };
  disabled?: boolean;
}

function Badge({
  children,
  onRemove,
  showRemove = true,
  className,
  classNames,
  disabled,
}: IProps) {
  return (
    <span
      className={twMerge(
        classnames(
          "inline-flex rounded-full items-center py-0.5 pl-2.5 pr-1 text-sm  bg-white text-gray-500 font-semibold select-none",
          {
            "pr-2": !showRemove,
            "cursor-pointer": !disabled,
            "cursor-not-allowed bg-gray-100 text-gray-300": disabled,
          }
        ),
        className
      )}
    >
      {children}
      {showRemove && !disabled && (
        <button
          onClick={!disabled ? onRemove : undefined}
          type="button"
          className={twMerge(
            classnames(
              "relative cursor-pointer flex-shrink-0 ml-0.5 h-4 w-4 rounded-full inline-flex items-center justify-center hover:bg-gray-400 focus:text-white focus:outline-none ",
              {
                "text-indigo-400  hover:text-indigo-500  focus:bg-indigo-500 ":
                  !classNames?.button,
              },
              classNames?.button
            )
          )}
        >
          <span className="sr-only">Remove</span>
          <svg
            className="h-2 w-2"
            stroke="currentColor"
            fill="none"
            viewBox="0 0 8 8"
          >
            <path
              strokeLinecap="round"
              strokeWidth="1.5"
              d="M1 1l6 6m0-6L1 7"
            />
          </svg>
        </button>
      )}
    </span>
  );
}

export default memo(Badge);
