import { useFormikContext } from "formik";
import { useState, useCallback } from "react";
import { FormikFieldArray } from "components/FormikFieldArray";
import { Badge } from "components/Badge";
import FormikInput from "components/FormikInput";
import { SKILLS_BADGE_COLORS } from "const";
import { Label } from "components/Label";
import classNames from "classnames";

function FormikTags({
  fieldName,
  label,
  disabled,
}: {
  fieldName: string;
  label: string;
  disabled?: boolean;
}) {
  const { values, setFieldValue, errors } = useFormikContext<{
    [key: string]: string[];
  }>();
  const [tagInput, setTagInput] = useState("");

  const onAddTag = useCallback(() => {
    if (tagInput.trim() !== "") {
      if (!values[fieldName].includes(tagInput.trim())) {
        setFieldValue(fieldName, [...values[fieldName], tagInput.trim()]);
      }
      setTagInput("");
    }
  }, [fieldName, setFieldValue, tagInput, values]);

  const handleKeyDown = useCallback(
    (e) => {
      if ((e.key === "Enter" || e.key === ",") && tagInput.trim() !== "") {
        e.preventDefault();
        onAddTag();
      }
    },
    [onAddTag, tagInput]
  );
  return (
    <div className="flex flex-col  relative gap-1 pt-4">
      <Label name={label} htmlFor={fieldName} />
      <div className="flex gap-2 w-full">
        <FormikInput
          name={fieldName}
          value={tagInput}
          disabled={disabled}
          onChange={useCallback((e) => {
            setTagInput(e.target.value);
          }, [])}
          onKeyDown={handleKeyDown}
          placeholder="Add tags..."
          classNames={{ inputFieldContainer: "w-full" }}
          // className="w-full"
        />
        <div
          onClick={onAddTag}
          className={classNames("pt-[6px]", {
            "text-blue-400 hover:text-blue-300 flex  ": !errors[fieldName],
            "cursor-not-allowed text-gray-300 hover:text-gray-300":
              errors[fieldName] || disabled,
          })}
        >
          Add
        </div>
      </div>
      {values[fieldName].length > 0 && (
        <div className="flex gap-4 flex-wrap mt-4">
          <FormikFieldArray<string> name={fieldName}>
            {({ remove, index, value }) => {
              return (
                <Badge
                  disabled={disabled}
                  onRemove={() => {
                    remove(index);
                  }}
                  classNames={{
                    button: SKILLS_BADGE_COLORS[index] || "",
                  }}
                  className="bg-gray-200"
                >
                  {value}
                </Badge>
              );
            }}
          </FormikFieldArray>
        </div>
      )}
    </div>
  );
}

export default FormikTags;
