import { Form, useFormikContext } from "formik";
import {
  memo,
  MutableRefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import {
  BorderBadge,
  Button,
  Card,
  FormikInput,
  FormikRating,
  FormikTextarea,
  Modal,
  ProfileBlock,
} from "components";
import { ISSUE_FIELD_NAMES } from "./IssueForm.constants";
import classnames from "classnames";
import { MAX_TEXT_LENGTH, MAX_TITLE_TEXT_LENGTH } from "./IssueForm.schema";
import FormikAutoCompleteSelectField from "components/FormikAutoCompleteSelectField";
import { IManagementProps, IValues } from "./IssueForm.types";
import {
  IAISuggestions,
  IIssueRatingCategory,
  IFeedbackArea,
  IAISimilar,
} from "types";
import { isMobile, isTablet } from "react-device-detect";
import Attachments from "modules/Attachments/Attachments";
import FormikTags from "components/FormikTags/FormikTags";

import { twMerge } from "tailwind-merge";
import { useQueries, useQueryClient } from "react-query";
import { APP_ROUTES, CONTENT_QUALITY_LEVELS } from "const";
import { AISimilarityModal } from "components/AISimilarityModal";

import axios from "axios";
import { findIssue } from "services";

import { getRating } from "utils";
import { useWindowDimensions } from "hooks";
import { ReactComponent as RatingStar } from "assets/images/rating-star.svg";
import { useNavigate, useParams } from "react-router-dom";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { ReactComponent as CarouselArrowLeft } from "assets/images/carousel_arrow_left.svg";
import { ReactComponent as CarouselArrowRight } from "assets/images/carousel_arrow_right.svg";

import { AISimilarityLeave } from "components/AISimilarityLeave";
export interface IIssueFormProps extends IManagementProps {
  categories: IIssueRatingCategory[] | undefined;
  showIssueFields: boolean;
  showRatings: boolean;
  similarIssuesRef: MutableRefObject<boolean>;
  suggestionsRef: MutableRefObject<boolean>;
  className?: string;
  onClose: (() => void) | undefined;
  scrollSlideoverToTop?: () => void;
}

function Item({ title, content }: { title: string; content: React.ReactNode }) {
  return (
    <>
      <span className="font-light leading-[30px] lg:text-right text-sm text-[#999999]">
        {title}
      </span>
      <span className="font-normal leading-[30px] text-[#00000099] flex align-center">
        {content}
      </span>
    </>
  );
}
interface IRatingBlockProps {
  rating: number;
  showRatingTooltip: boolean;
}
function RatingBlock({ rating, showRatingTooltip }: IRatingBlockProps) {
  const [openPopup, setOpenPopup] = useState(false);
  return (
    <span
      onMouseOver={() => {
        if (showRatingTooltip) {
          setOpenPopup(true);
        }
      }}
      onMouseOut={() => {
        setOpenPopup(false);
      }}
      className={classnames(
        " relative bg-[#EFEFEF] rounded min-w-[31px] min-h-[18px] text-sm font-normal flex flex-row items-start justify-center px-2 text-center  ",
        {
          " cursor-pointer hover:outline outline-[1px] hover:outline-[#8159D7]":
            openPopup,
        }
      )}
    >
      {getRating(rating) || 0}
      <RatingStar height={8} width={8} fill="black" />
      {showRatingTooltip && openPopup && (
        <div className="absolute  text-black gradient-outline-forced-roundless text-center  right-[-30px]  z-10 left-[-470%] xx:left-[-360%] lg:left-[-200%] top-[150%] text-sm font-normal after:tooltiptoparrow">
          <div className="flex flex-col relative text-xs bg-white p-2">
            <p>How relevant is this issue? </p>
            <p className="hidden sm:inline">
              Please click "See Full Details" to rate
            </p>
            <p className="inline sm:hidden">Please click "Details" to rate</p>
          </div>
          <span className="absolute border-[8px] top-[-17px] right-[9%] xx:right-[25%] lg:right-[31%] border-t-transparent border-b-[#8159D7] border-r-transparent border-l-transparent tooltiptoparrow" />
        </div>
      )}
    </span>
  );
}

function IssueForm(props: IIssueFormProps) {
  const params = useParams<{ id: string }>();
  const { errors, values, isSubmitting, submitCount, submitForm } =
    useFormikContext<IValues>();
  const queryClient = useQueryClient();
  const analyticsIssueMutation = queryClient
    .getMutationCache()
    .findAll({ mutationKey: ["createAIAnalyticsIssue"] });

  const similarIssueMutation = queryClient
    .getMutationCache()
    .findAll({ mutationKey: ["createAISimilarIssue"] });

  const [showSimilarityModal, setShowSimilarityModal] = useState(false);
  const [similarityModalInteracted, setSimilarityModalInteracted] =
    useState(false);

  const latestAIMutation = useMemo(
    () =>
      analyticsIssueMutation && analyticsIssueMutation.length > 0
        ? //@ts-ignore

          analyticsIssueMutation[analyticsIssueMutation.length - 1]?.state?.data
            ?.data
        : //@ts-ignore
          analyticsIssueMutation[0]?.state?.data?.data,
    [analyticsIssueMutation]
  ) as IAISuggestions | undefined;

  const latestSimilarAIMutation = useMemo(
    () =>
      similarIssueMutation && similarIssueMutation.length > 0
        ? //@ts-ignore
          similarIssueMutation[similarIssueMutation.length - 1]?.state?.data
            ?.data
        : //@ts-ignore
          similarIssueMutation[0]?.state?.data?.data,
    [similarIssueMutation]
  ) as IAISimilar | undefined;

  const moreFeedback = latestAIMutation?.more_feedback?.quality;
  const defaultValueForProblemAreaWithMoreFeedback: IFeedbackArea = {
    input_name: "",
    quality: "Poor" as CONTENT_QUALITY_LEVELS,
    user_input: "",
    feedback: "",
    suggested_texts: [],
  };

  const similarMutationRef = useRef<IAISimilar | undefined>(undefined);
  const submitCountRef = useRef<number>(0);
  const issueIdforEdit = useMemo(() => params.id, [params.id]);

  useEffect(() => {
    if (!issueIdforEdit && submitCount > submitCountRef.current) {
      submitCountRef.current = submitCount;
      setSimilarityModalInteracted(false);
    }
  }, [submitCount, issueIdforEdit]);

  useEffect(() => {
    if (
      !issueIdforEdit &&
      !props.similarIssuesRef.current &&
      !similarityModalInteracted &&
      latestSimilarAIMutation &&
      latestSimilarAIMutation.potential_similarity_id.length > 0 &&
      !showSimilarityModal
    ) {
      similarMutationRef.current = latestSimilarAIMutation;
      props.suggestionsRef.current = true;
      props.similarIssuesRef.current = true;
      setShowSimilarityModal(true);
    }
  }, [
    issueIdforEdit,
    latestSimilarAIMutation,
    props.similarIssuesRef,
    props.suggestionsRef,
    showSimilarityModal,
    similarityModalInteracted,
    submitCount,
  ]);

  const issueQueryIds = latestSimilarAIMutation?.potential_similarity_id || [];

  const issuesQueries = useQueries({
    queries: issueQueryIds?.map((id) => {
      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();
      return {
        queryKey: ["similarIssue", id],
        queryFn: () => findIssue({ id: id, source: source }),
      };
    }),
  });

  const mappedIssues = issuesQueries
    ?.filter((query) => (query?.data?.data as unknown as string) !== "")
    .map((query) => {
      return query.data?.data || undefined;
    });

  const [showLeaveModal, setShowLeaveModal] = useState(false);

  const navigate = useNavigate();
  const dimensions = useWindowDimensions();
  const [currentSelectedIssueId, setCurrentSelectedIssueId] = useState<
    string | undefined
  >(undefined);

  return (
    <>
      <Form className=" two-third-step flex flex-col flex-1 ">
        <div className="  px-4 sm:px-20 flex-1 flex flex-col gap-4">
          {props.showIssueFields && (
            <>
              <FormikInput
                checkAIContentQuality={!!latestAIMutation}
                AISuggestionProblemArea={
                  latestAIMutation?.feedback_areas?.find(
                    (problemArea) =>
                      problemArea.input_name.toLocaleLowerCase() === "title"
                  ) ||
                  (moreFeedback
                    ? {
                        ...defaultValueForProblemAreaWithMoreFeedback,
                        input_name: ISSUE_FIELD_NAMES.TITLE,
                      }
                    : undefined)
                }
                AISuggestionFieldName={ISSUE_FIELD_NAMES.SUGGESTED_TITLE}
                autoComplete="nope"
                label="Title"
                name={ISSUE_FIELD_NAMES.TITLE}
                placeholder="Title"
                disabled={props?.suggestionsRef.current}
                showOnlyCustomMessage={errors?.title === "max"}
                message={
                  <div className="flex justify-between text-gray-400  font-light text-sm">
                    <span>Please add a descriptive title</span>
                    <span
                      className={classnames({
                        "text-red-400": errors?.title === "max",
                      })}
                    >{`${values?.title?.length}/${MAX_TITLE_TEXT_LENGTH}`}</span>
                  </div>
                }
              />
              <FormikAutoCompleteSelectField
                allowAutoFill={true}
                isDisabled={props?.suggestionsRef.current}
                name={ISSUE_FIELD_NAMES.LOCATION}
                label="Location"
                //className="mt-6"
              />
              <FormikTextarea
                checkAIContentQuality={!!latestAIMutation}
                AISuggestionProblemArea={
                  latestAIMutation?.feedback_areas?.find(
                    (problemArea) =>
                      problemArea.input_name.toLocaleLowerCase() === "details"
                  ) ||
                  (moreFeedback
                    ? {
                        ...defaultValueForProblemAreaWithMoreFeedback,
                        input_name: ISSUE_FIELD_NAMES.DETAILS,
                      }
                    : undefined)
                }
                disabled={props?.suggestionsRef.current}
                AISuggestionFieldName={ISSUE_FIELD_NAMES.SUGGESTED_DETAILS}
                autoComplete="nope"
                label="Details"
                name={ISSUE_FIELD_NAMES.DETAILS}
                placeholder="Details"
                showOnlyCustomMessage={errors?.details === "max"}
                message={
                  <div className="flex justify-between text-gray-400  font-light text-sm">
                    <span>Please add some more details</span>
                    <span
                      className={classnames({
                        "text-red-400": errors?.details === "max",
                      })}
                    >{`${values?.details?.length}/${MAX_TEXT_LENGTH}`}</span>
                  </div>
                }
              />
              <FormikTextarea
                checkAIContentQuality={!!latestAIMutation}
                AISuggestionProblemArea={
                  latestAIMutation?.feedback_areas?.find(
                    (problemArea) =>
                      problemArea.input_name.toLocaleLowerCase() === "origin"
                  ) ||
                  (moreFeedback
                    ? {
                        ...defaultValueForProblemAreaWithMoreFeedback,
                        input_name: ISSUE_FIELD_NAMES.ORIGIN,
                      }
                    : undefined)
                }
                disabled={props?.suggestionsRef.current}
                AISuggestionFieldName={ISSUE_FIELD_NAMES.SUGGESTED_ORIGIN}
                autoComplete="nope"
                label="Origin"
                name={ISSUE_FIELD_NAMES.ORIGIN}
                placeholder="Origin"
                showOnlyCustomMessage={errors?.origin === "max"}
                message={
                  <div className="flex justify-between text-gray-400  font-light text-sm">
                    <span>How did this issue start?</span>{" "}
                    <span
                      className={classnames({
                        "text-red-400": errors?.origin === "max",
                      })}
                    >{`${values?.origin?.length}/${MAX_TEXT_LENGTH}`}</span>
                  </div>
                }
              />
              <FormikTextarea
                checkAIContentQuality={!!latestAIMutation}
                AISuggestionProblemArea={
                  latestAIMutation?.feedback_areas?.find(
                    (problemArea) =>
                      problemArea.input_name.toLocaleLowerCase() ===
                      "importance"
                  ) ||
                  (moreFeedback
                    ? {
                        ...defaultValueForProblemAreaWithMoreFeedback,
                        input_name: ISSUE_FIELD_NAMES.IMPORTANCE,
                      }
                    : undefined)
                }
                disabled={props?.suggestionsRef.current}
                AISuggestionFieldName={ISSUE_FIELD_NAMES.SUGGESTED_IMPORTANCE}
                autoComplete="nope"
                label="Importance"
                name={ISSUE_FIELD_NAMES.IMPORTANCE}
                placeholder="Importance"
                showOnlyCustomMessage={errors?.importance === "max"}
                message={
                  <div className="flex justify-between text-gray-400  font-light text-sm">
                    <span>Why is this issue important?</span>{" "}
                    <span
                      className={classnames({
                        "text-red-400": errors?.importance === "max",
                      })}
                    >{`${values?.importance?.length}/${MAX_TEXT_LENGTH}`}</span>
                  </div>
                }
              />
            </>
          )}

          {props.showRatings && (
            <>
              {props.showIssueFields && (
                <>
                  <div className="  leading-6 font-semibold pt-8">
                    Rate your issue
                  </div>
                </>
              )}
              <div className="three-seventh-step grid grid-cols-2 mt-5 gap-4 flex-1 auto-rows-min">
                {props.categories?.map((category) => (
                  <FormikRating
                    disabled={props?.suggestionsRef.current}
                    key={category.id}
                    name={category.category}
                    title={category.title}
                    classNames={{ input: "mt-2 !gap-3" }}
                  />
                ))}
              </div>

              {!!errors?.validator! && (
                <div className="w-full">
                  <p className=" text-red-400 text-sm font-normal">
                    {errors?.validator as string}
                  </p>
                </div>
              )}
            </>
          )}
          {props.showIssueFields && (
            <>
              <Attachments disabled={props?.suggestionsRef.current} />
              <FormikTags
                disabled={props?.suggestionsRef.current}
                fieldName={ISSUE_FIELD_NAMES.TAGS}
                label="Labels (Optional)"
              />
            </>
          )}
        </div>

        <div className="border-b border-gray-200 w-full h-[1px] mt-10" />
        <div className="mt-4 flex gap-4 justify-end px-4 sm:px-20">
          <Button
            isReverse
            type="button"
            className=" justify-center border"
            disabled={isSubmitting}
            onClick={props.onClose}
          >
            Cancel
          </Button>
          <button
            type="submit"
            disabled={isSubmitting || !!errors?.validator}
            className={twMerge(
              classnames(
                "w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-3 bg-quality-content-green text-base font-medium text-white hover:bg-quality-content-green focus:outline-none  sm:ml-3 sm:w-auto sm:text-sm",
                {
                  "ring-2 ring-offset-2 ring-red-500":
                    values?.suggestedResult &&
                    values?.suggestedResult?.feedback_areas?.length > 0,
                }
              )
            )}
          >
            Review and Save
          </button>
        </div>
      </Form>
      {showSimilarityModal && (
        <AISimilarityModal
          showCloseIcon={false}
          setShow={(val) => {
            setSimilarityModalInteracted(true);
            setShowSimilarityModal(val);
            props.similarIssuesRef.current = true;
          }}
          header={
            <div className="flex flex-col items-start gap-2 py-4  ">
              <p
                className={twMerge(
                  classnames("font-semibold  leading-0", {
                    "text-2xl":
                      !isMobile && !isTablet && !(dimensions.width < 641),
                    "text-lg": isMobile || isTablet || dimensions.width < 641,
                  })
                )}
              >
                We found a potentially similar post. Is this the same issue?
              </p>
              {(isMobile || isTablet || dimensions.width < 641) && (
                <button
                  type="button"
                  onClick={() => {
                    submitForm();
                  }}
                  disabled={isSubmitting || !!errors?.validator}
                  className={twMerge(
                    classnames(
                      "w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-3 bg-quality-content-green text-base font-medium text-white hover:bg-quality-content-green focus:outline-none  sm:ml-3 sm:w-auto sm:text-sm",
                      {
                        "ring-2 ring-offset-2 ring-red-500":
                          values?.suggestedResult &&
                          values?.suggestedResult?.feedback_areas?.length > 0,
                      }
                    )
                  )}
                >
                  Publish My Issue Anyway
                </button>
              )}
            </div>
          }
          content={
            <div className="  flex flex-row gap-4 relative w-full">
              <div
                className={twMerge(
                  classnames("z-[1] absolute right-0 w-[75px] h-full ", {
                    "bg-[linear-gradient(270deg,_#fff,_#fff_42%,_#0083f500)]":
                      mappedIssues?.length > 1 &&
                      !isMobile &&
                      !isTablet &&
                      !(dimensions.width < 641),
                  })
                )}
              />
              {mappedIssues?.length > 0 ? (
                <>
                  {isMobile || isTablet || dimensions.width < 641 ? (
                    <>
                      <div className="flex flex-col gap-2 max-h-[350px] overflow-y-scroll">
                        {mappedIssues?.map((issue) => {
                          const date =
                            issue?.createdAt &&
                            new Date(issue?.createdAt).toLocaleDateString(
                              "en-GB",
                              {
                                year: "numeric",
                                month: "long",
                                day: "2-digit",
                              }
                            );

                          const formattedAddress =
                            issue?.location?.formattedAddress;

                          const isSoftDeleted = !!issue?.deletedAt;
                          return (
                            <>
                              <Card
                                key={issue?.id}
                                shouldHover={false}
                                className={classnames(
                                  "flex-1 xs:max-w-[480px] sm:max-w-[616px] z-40 border border-gray-500 ",
                                  {
                                    "bg-gray-100 pointer-events-none select-none":
                                      isSoftDeleted,
                                  }
                                )}
                                header={
                                  <div className="flex justify-between items-center pb-1">
                                    <div className="grid grid-flow-row gap-x-2 items-center max-h-[70px] mr-2">
                                      <span className="font-bold leading-6 text-lg line-clamp-2">
                                        {issue?.title}
                                      </span>
                                      <span className="leading-6 text-sm  text-gray-400 line-clamp-1 font-light">
                                        {formattedAddress}
                                      </span>
                                    </div>
                                  </div>
                                }
                                classNames={{
                                  header:
                                    "px-4 sm:px-9  flex-1 sm:max-w-[616px]",
                                  content:
                                    "px-4 sm:px-9  flex-1 sm:max-w-[616px]",
                                  footer:
                                    "px-3 sm:px-9 h-[60px] flex-1 sm:max-w-[616px]",
                                }}
                                content={
                                  <div className="grid grid-cols-[1fr] lg:grid-cols-[auto_1fr] gap-y-2 gap-x-6 text-left">
                                    <Item
                                      title="Details"
                                      content={issue?.details}
                                    />
                                    <Item
                                      title="Origin"
                                      content={issue?.origin}
                                    />
                                    <Item
                                      title="Importance"
                                      content={issue?.importance}
                                    />
                                    <Item
                                      title="Category"
                                      content={
                                        <div className="flex items-start flex-wrap  gap-4 py-1">
                                          {!!issue?.primaryClassification && (
                                            <BorderBadge
                                              classificationName={
                                                issue?.primaryClassification
                                                  ?.category
                                              }
                                              classificationDescription={
                                                issue?.primaryClassification
                                                  ?.description
                                              }
                                              tooltipId={issue?.id}
                                            >
                                              {
                                                issue?.primaryClassification
                                                  ?.title
                                              }
                                            </BorderBadge>
                                          )}
                                          {!!issue?.secondaryClassification && (
                                            <BorderBadge
                                              classificationName={
                                                issue?.secondaryClassification
                                                  .category
                                              }
                                              classificationDescription={
                                                issue?.secondaryClassification
                                                  .description
                                              }
                                              tooltipId={issue?.id}
                                            >
                                              {
                                                issue?.secondaryClassification
                                                  .title
                                              }
                                            </BorderBadge>
                                          )}
                                        </div>
                                      }
                                    />
                                    {issue?.tags && issue?.tags?.length > 0 && (
                                      <Item
                                        title="Labels"
                                        content={
                                          <div className="flex items-center flex-wrap gap-2 py-1">
                                            {issue?.tags?.map((tag) => (
                                              <BorderBadge
                                                key={tag}
                                                classificationName={tag}
                                                styleLess
                                                classNames={{
                                                  text: "text-gray-500 text-xs underline",
                                                  contend: "flex",
                                                }}
                                              >
                                                {tag}
                                              </BorderBadge>
                                            ))}
                                          </div>
                                        }
                                      />
                                    )}
                                  </div>
                                }
                                footer={
                                  <>
                                    <div className="flex flex-row items-center gap-2 sm:gap-14 pr-1 ">
                                      <ProfileBlock
                                        userId={issue?.user?.id}
                                        isModalVariant
                                        firstName={issue?.user?.firstName}
                                        lastName={issue?.user?.lastName}
                                        createdAt={date}
                                        profileImage={issue?.user?.avatar}
                                        email={issue?.user?.email}
                                        score={issue?.user?.reputationScore}
                                      />
                                      <div className="flex flex-row gap-1 h-full items-center  rounded shrink-0">
                                        {(!!issue?.rating ||
                                          issue?.rating === 0) && (
                                          <RatingBlock
                                            rating={issue?.rating}
                                            showRatingTooltip
                                          />
                                        )}
                                      </div>
                                    </div>

                                    {dimensions.width < 641 && (
                                      <Button
                                        inGroupStyle={false}
                                        isStyleless
                                        isReverse
                                        className="!pointer-events-auto three-fifth-step five-fifth-step six-fourth-step ten-fifth-step eleven-fourth-step issue-full-details-button-to-click  sm:hidden shrink-0 text-[black] border hover:border-black border-gray-500 "
                                        onClick={() => {
                                          setCurrentSelectedIssueId(issue?.id);
                                          setShowLeaveModal(true);
                                        }}
                                      >
                                        Join Existing Discussion
                                      </Button>
                                    )}
                                    {dimensions.width > 640 && (
                                      <Button
                                        inGroupStyle={false}
                                        isStyleless
                                        isReverse
                                        className="!pointer-events-auto three-fifth-step five-fifth-step six-fourth-step ten-fifth-step eleven-fourth-step issue-full-details-button-to-click  !px-2 maxSm:hidden text-[black] border hover:border-black border-gray-500  "
                                        onClick={() => {
                                          setCurrentSelectedIssueId(issue?.id);
                                          setShowLeaveModal(true);
                                        }}
                                      >
                                        Join Existing Discussion
                                      </Button>
                                    )}
                                  </>
                                }
                              />
                            </>
                          );
                        })}
                      </div>
                    </>
                  ) : (
                    <Carousel
                      className="w-full"
                      showArrows={true}
                      showIndicators={false}
                      showThumbs={false}
                      centerMode
                      showStatus={false}
                      centerSlidePercentage={80}
                      renderArrowPrev={(onClickHandler, hasPrev, label) => (
                        <div
                          onClick={onClickHandler}
                          title={label}
                          className={twMerge(
                            classnames(
                              "absolute left-0 top-1/2 transform -translate-y-1/2 z-50 cursor-pointer  ",
                              {
                                hidden: !hasPrev,
                              }
                            )
                          )}
                        >
                          <CarouselArrowLeft height="32" width="32" />
                        </div>
                      )}
                      renderArrowNext={(onClickHandler, hasNext, label) => (
                        <div
                          onClick={onClickHandler}
                          title={label}
                          className={twMerge(
                            classnames(
                              "absolute right-0 top-1/2 transform -translate-y-1/2 z-50 cursor-pointer  ",
                              {
                                hidden: !hasNext,
                              }
                            )
                          )}
                        >
                          <CarouselArrowRight height="32" width="32" />
                        </div>
                      )}
                    >
                      {mappedIssues?.map((issue) => {
                        const date =
                          issue?.createdAt &&
                          new Date(issue?.createdAt).toLocaleDateString(
                            "en-GB",
                            {
                              year: "numeric",
                              month: "long",
                              day: "2-digit",
                            }
                          );

                        const formattedAddress =
                          issue?.location?.formattedAddress;

                        const isSoftDeleted = !!issue?.deletedAt;
                        return (
                          <>
                            <Card
                              key={issue?.id}
                              shouldHover={false}
                              className={classnames(
                                "flex-1 xs:max-w-[480px] sm:max-w-[616px] z-40 border border-gray-500 h-full",
                                {
                                  "bg-gray-100 pointer-events-none select-none":
                                    isSoftDeleted,
                                }
                              )}
                              header={
                                <div className="flex justify-between items-center pb-1">
                                  <div className="grid grid-flow-row gap-x-2 items-center max-h-[70px] mr-2">
                                    <span className="font-bold leading-6 text-lg line-clamp-2">
                                      {issue?.title}
                                    </span>
                                    <span className="leading-6 text-sm  text-gray-400 line-clamp-1 font-light">
                                      {formattedAddress}
                                    </span>
                                  </div>
                                </div>
                              }
                              classNames={{
                                header: "px-4 sm:px-9  flex-1 sm:max-w-[616px]",
                                content:
                                  "px-4 sm:px-9  flex-1 sm:max-w-[616px]",
                                footer:
                                  "px-3 sm:px-9 h-[60px] flex-1 sm:max-w-[616px]",
                              }}
                              content={
                                <div className="grid grid-cols-[1fr] lg:grid-cols-[auto_1fr] gap-y-2 gap-x-6 text-left">
                                  <Item
                                    title="Details"
                                    content={issue?.details}
                                  />
                                  <Item
                                    title="Origin"
                                    content={issue?.origin}
                                  />
                                  <Item
                                    title="Importance"
                                    content={issue?.importance}
                                  />
                                  <Item
                                    title="Category"
                                    content={
                                      <div className="flex items-start flex-wrap  gap-4 py-1">
                                        {!!issue?.primaryClassification && (
                                          <BorderBadge
                                            classificationName={
                                              issue?.primaryClassification
                                                ?.category
                                            }
                                            classificationDescription={
                                              issue?.primaryClassification
                                                ?.description
                                            }
                                            tooltipId={issue?.id}
                                          >
                                            {
                                              issue?.primaryClassification
                                                ?.title
                                            }
                                          </BorderBadge>
                                        )}
                                        {!!issue?.secondaryClassification && (
                                          <BorderBadge
                                            classificationName={
                                              issue?.secondaryClassification
                                                .category
                                            }
                                            classificationDescription={
                                              issue?.secondaryClassification
                                                .description
                                            }
                                            tooltipId={issue?.id}
                                          >
                                            {
                                              issue?.secondaryClassification
                                                .title
                                            }
                                          </BorderBadge>
                                        )}
                                      </div>
                                    }
                                  />
                                  {issue?.tags && issue?.tags?.length > 0 && (
                                    <Item
                                      title="Labels"
                                      content={
                                        <div className="flex items-center flex-wrap gap-2 py-1">
                                          {issue?.tags?.map((tag) => (
                                            <BorderBadge
                                              key={tag}
                                              classificationName={tag}
                                              styleLess
                                              classNames={{
                                                text: "text-gray-500 text-xs underline",
                                                contend: "flex",
                                              }}
                                            >
                                              {tag}
                                            </BorderBadge>
                                          ))}
                                        </div>
                                      }
                                    />
                                  )}
                                </div>
                              }
                              footer={
                                <>
                                  <div className="flex flex-row items-center gap-2 sm:gap-14 pr-1 ">
                                    <ProfileBlock
                                      userId={issue?.user?.id}
                                      isModalVariant
                                      firstName={issue?.user?.firstName}
                                      lastName={issue?.user?.lastName}
                                      createdAt={date}
                                      profileImage={issue?.user?.avatar}
                                      email={issue?.user?.email}
                                      score={issue?.user?.reputationScore}
                                    />
                                    <div className="flex flex-row gap-1 h-full items-center  rounded shrink-0">
                                      {(!!issue?.rating ||
                                        issue?.rating === 0) && (
                                        <RatingBlock
                                          rating={issue?.rating}
                                          showRatingTooltip
                                        />
                                      )}
                                    </div>
                                  </div>

                                  {dimensions.width < 641 && (
                                    <Button
                                      inGroupStyle={false}
                                      isStyleless
                                      isReverse
                                      className="!pointer-events-auto three-fifth-step five-fifth-step six-fourth-step ten-fifth-step eleven-fourth-step issue-full-details-button-to-click  sm:hidden shrink-0 text-[black] border hover:border-black border-gray-500 "
                                      onClick={() => {
                                        setCurrentSelectedIssueId(issue?.id);
                                        setShowLeaveModal(true);
                                      }}
                                    >
                                      Join Existing Discussion
                                    </Button>
                                  )}
                                  {dimensions.width > 640 && (
                                    <Button
                                      inGroupStyle={false}
                                      isStyleless
                                      isReverse
                                      className="!pointer-events-auto three-fifth-step five-fifth-step six-fourth-step ten-fifth-step eleven-fourth-step issue-full-details-button-to-click  !px-2 maxSm:hidden text-[black] border hover:border-black border-gray-500  "
                                      onClick={() => {
                                        setCurrentSelectedIssueId(issue?.id);
                                        setShowLeaveModal(true);
                                      }}
                                    >
                                      Join Existing Discussion
                                    </Button>
                                  )}
                                </>
                              }
                            />
                          </>
                        );
                      })}
                    </Carousel>
                  )}
                </>
              ) : (
                <div>No similar issues found</div>
              )}
            </div>
          }
          classNames={{
            footer: "!justify-center",
            header: " h-max justify-start",
          }}
          footer={
            <div className=" flex gap-4 items-center justify-center px-4  ">
              {!isMobile && !isTablet && !(dimensions.width < 641) && (
                <button
                  type="button"
                  onClick={() => {
                    submitForm();
                  }}
                  disabled={isSubmitting || !!errors?.validator}
                  className={twMerge(
                    classnames(
                      "w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-3 bg-quality-content-green text-base font-medium text-white hover:bg-quality-content-green focus:outline-none  sm:ml-3 sm:w-auto sm:text-sm",
                      {
                        "ring-2 ring-offset-2 ring-red-500":
                          values?.suggestedResult &&
                          values?.suggestedResult?.feedback_areas?.length > 0,
                      }
                    )
                  )}
                >
                  Publish My Issue Anyway
                </button>
              )}
            </div>
          }
        />
      )}
      {showLeaveModal && (
        <AISimilarityLeave
          setShow={(val) => {
            setShowLeaveModal(val);
          }}
          header={<></>}
          content={
            <div className="flex flex-col items-center gap-4 py-1  ">
              <p className="font-semibold text-xl leading-0">Warning</p>
              <p className="font-light text-sm">
                Your draft will be discarded when you join the existing
                discussion. Continue?
              </p>
            </div>
          }
          footer={
            <div className=" flex gap-4 justify-end ">
              <Button
                isReverse
                type="button"
                className=" justify-center border"
                onClick={() => {
                  setShowLeaveModal(false);
                }}
              >
                No, go back
              </Button>
              <button
                type="button"
                onClick={() => {
                  setShowLeaveModal(false);
                  setShowSimilarityModal(false);
                  props.similarIssuesRef.current = false;
                  if (props?.onClose) {
                    props?.onClose();
                    props.similarIssuesRef.current = false;
                    navigate(APP_ROUTES.ISSUE + "/" + currentSelectedIssueId);
                  }
                }}
                className={twMerge(
                  classnames(
                    "w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-3 bg-quality-content-green text-base font-medium text-white hover:bg-quality-content-green focus:outline-none  sm:ml-3 sm:w-auto sm:text-sm"
                  )
                )}
              >
                Yes, join discussion
              </button>
            </div>
          }
          classNames={{
            content: "text-black",
            footer: "pb-[40px] pt-0",
          }}
        />
      )}
    </>
  );
}

export default memo(IssueForm);
