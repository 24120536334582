import { memo, useState } from "react";
import AvatarPlaceholder from "assets/images/avatar.png";
import classnames from "classnames";
import { IActionGroup, IActionGroupUsers, IAsset, IUser } from "types";
import { XIcon } from "@heroicons/react/outline";

interface IProps {
  profileImage?: string | undefined;
  groupImage?: string | undefined;
  createdAt: string | undefined;
  showAvatar?: boolean;
  showCreatedAt?: boolean;
  isModalVariant?: boolean;
  className?: string;
  showMembers?: boolean;
  classNames?: { avatar?: string; name?: string; dotSeparator?: string };
  membersCount?: string;
  actionGroupUsers?:
    | Partial<
        IActionGroupUsers<
          Partial<IUser<IAsset>>,
          string | Partial<IActionGroup>
        >
      >[]
    | undefined;
}

interface IMembersPopupProps {
  membersList:
    | Partial<
        IActionGroupUsers<
          Partial<IUser<IAsset>>,
          string | Partial<IActionGroup>
        >
      >[]
    | undefined;
  membersCount: string;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
}

function MembersPopup({
  membersList,
  membersCount,

  setShow,
}: IMembersPopupProps) {
  return (
    <div className="absolute flex flex-col min-w-[200px] min-h-[200px] bottom-[100%] z-[100] w-full h-full bg-white border shadow-md rounded-lg ">
      <div className="flex flex-row justify-between px-2 pt-2">
        <div className="flex flex-row items-center gap-2">
          <p className="font-bold text-black">{`Members`}</p>
          <span className="h-1 w-1 rounded-full bg-gray-400" />
          <p>{`${membersCount}`}</p>
        </div>
        <div>
          <XIcon
            onClick={() => {
              setShow(false);
            }}
            className="h-6 w-6 shrink-0 cursor-pointer"
            aria-hidden="true"
          />
        </div>
      </div>
      <div
        className={classnames("border-b border-[#D1D5DB] w-full h-[1px] py-1 ")}
      />
      <div className=" py-1 overflow-auto">
        {membersList &&
          membersList.length > 0 &&
          membersList.map((member) => {
            return (
              <div className="flex flex-col py-1">
                <div className="flex flex-row items-center justify-left gap-3 px-2">
                  <div className="rounded-full w-[24px] h-[24px] outline outline-[1px] outline-gray-200">
                    <img
                      className="  h-full w-full rounded-full object-cover"
                      alt="avatar"
                      src={member?.user?.avatar?.url}
                    />
                  </div>
                  <p>{`${member?.user?.firstName} ${member?.user?.lastName}`}</p>
                </div>
                <div
                  className={classnames(
                    "border-b border-[#E5E7EB] w-full h-[1px] py-1 "
                  )}
                />
              </div>
            );
          })}
      </div>
    </div>
  );
}

function ActionGroupProfileBlock({
  profileImage,
  groupImage,
  createdAt,
  className,
  classNames,
  actionGroupUsers,
  showMembers = true,
  showAvatar = true,
  showCreatedAt = true,
  isModalVariant = false,
  membersCount,
}: IProps) {
  const [showMembersPopup, setShowMembersPopup] = useState(false);

  return (
    <div
      className={classnames(
        "flex items-center justify-center gap-1",
        className
      )}
    >
      {showAvatar && (
        <div
          className={classnames(
            "relative rounded-full py-3 pr-3 overflow-hidden flex justify-center items-center shrink-0",
            classNames?.avatar
          )}
        >
          <img
            decoding="async"
            height={isModalVariant ? 40 : 32}
            width={isModalVariant ? 40 : 32}
            src={!!groupImage ? groupImage : AvatarPlaceholder}
            alt="avatar"
            className={classnames(" object-cover rounded-full", {
              "h-10 w-10": isModalVariant,
              "h-8 w-8": !isModalVariant,
            })}
          />
          <img
            decoding="async"
            height={isModalVariant ? 20 : 12}
            width={isModalVariant ? 20 : 12}
            src={!!profileImage ? profileImage : AvatarPlaceholder}
            alt="avatar"
            className={classnames(
              "absolute object-contain ml-6 mt-5 rounded-full border border-white",
              {
                "h-6 w-6": isModalVariant,
                "h-4 w-4": !isModalVariant,
              }
            )}
          />
        </div>
      )}

      <div
        className={classnames(
          "ml-3 min-w-0 flex flex-col gap-1 items-center ",
          classNames?.name
        )}
      >
        {showMembers && membersCount !== undefined && (
          <div className=" relative self-start">
            <p
              onClick={() => {
                if (Number(membersCount) > 0) setShowMembersPopup(true);
              }}
              className="  font-normal text-[#999999] hover:text-black cursor-pointer line-clamp-2"
            >{`(${membersCount} ${
              Number(membersCount) === 1 ? "member" : "members"
            })`}</p>
            {showMembersPopup && (
              <MembersPopup
                membersCount={membersCount}
                membersList={actionGroupUsers || []}
                setShow={setShowMembersPopup}
              />
            )}
          </div>
        )}

        {showCreatedAt && (
          <span className="text-sm  text-gray-400 group-hover:text-gray-700 font-light leading-5 overflow-ellipsis overflow-hidden whitespace-nowrap ">
            {createdAt}
          </span>
        )}
      </div>
    </div>
  );
}

export default memo(ActionGroupProfileBlock);
