import { memo, useEffect } from "react";
import ReactDOM from "react-dom";

import { Card } from "components";

import classnames from "classnames";

import { XIcon } from "@heroicons/react/outline";
import React from "react";
import { twMerge } from "tailwind-merge";
import { Popover } from "@headlessui/react";

interface IAISimilarityModal {
  setShow: (value: boolean) => void;

  className?: string | undefined;
  showFooterDivider?: boolean;
  disableScrollOnOpen?: boolean;
  actionCallback?: any;
  header: React.ReactNode | string;
  content: React.ReactNode | string;
  footer?: React.ReactNode | string;
  classNames?: {
    footer?: string;
    content?: string;
    header?: string;
  };
}

function AISimilarityPopover({
  className,

  setShow,
  header,
  content,
  footer,
  classNames,
  showFooterDivider,
  disableScrollOnOpen,
}: IAISimilarityModal) {
  useEffect(() => {
    if (disableScrollOnOpen) {
      document.body.style.overflow = "hidden";
    }
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [disableScrollOnOpen]);

  return (
    <Popover className="relative">
      <div
        className={classnames(
          className,
          "z-[50] top-0 fixed  left-0 w-full bg-gray-500 bg-opacity-30 h-full outline-none flex justify-center  items-center overflow-y-hidden p-4"
        )}
      >
        <Card
          showFooterDivider={false}
          showHeaderDivider={false}
          shouldHover={false}
          className="flex-1 max-w-[450px] py-0 z-40"
          header={
            <div className="flex justify-between items-start pb-1">
              <div className="grid grid-flow-row gap-x-2 items-center  mr-2 ">
                <span className="font-bold leading-6 text-lg line-clamp-2">
                  {header}
                </span>
              </div>
            </div>
          }
          classNames={{
            header: twMerge(
              classnames(
                "px-4 sm:px-9 py-2 justify-center sm:max-w-[900px]  ",
                classNames?.header
              )
            ),
            content: twMerge(
              classnames("px-4 sm:px-9  flex-1 sm:max-w-[900px]")
            ),
            footer: twMerge(
              classnames(
                "px-3 sm:px-9 h-[60px] py-2 flex-1 !justify-center sm:max-w-[900px]",
                classNames?.footer
              )
            ),
          }}
          content={
            <div
              className={twMerge(
                classnames(
                  "flex flex-col items-center py-4 text-lg",
                  classNames?.content
                )
              )}
            >
              {content}
            </div>
          }
          footer={<>{footer}</>}
        />
      </div>
    </Popover>
  );
}

export default memo(AISimilarityPopover);
