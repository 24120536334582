/* eslint-disable jsx-a11y/no-access-key */
import { Form, useFormikContext } from "formik";
import classnames from "classnames";
import React, { memo, useEffect, useMemo, useState } from "react";

import {
  Button,
  FormikInput,
  FormikQuill,
  RegularModal,
  Modal,
} from "components";
import { ExclamationIcon } from "@heroicons/react/outline";
import { IManagementProps } from "./MotivationalForm.types";
import { FIELD_NAMES, InitialMotivational } from "./MotivationalForm.constants";

import { MAX_DESC_LENGTH, MAX_TEXT_LENGTH } from "./MotivationalForm.schema";
import { ReactComponent as Dismiss } from "assets/images/dismiss.svg";
import UnsplashReact, { InsertIntoApplicationUploader } from "unsplash-react";

import { useLocation, useNavigate } from "react-router-dom";
import { APP_ROUTES, CONTENT_QUALITY_LEVELS } from "const";

import { Dialog } from "@headlessui/react";
import { usePreventTransition } from "./MotivationalForm.hooks";
import { getYouTubeVideoId } from "utils";
import { MotivationalTags } from "./MotivationalFormTagsField.component";
import YouTube from "react-youtube";
import { useQueryClient } from "react-query";
import { IAIMotivationalSuggestions } from "types";

interface AuthenticationPanelProps
  extends React.HTMLAttributes<HTMLDivElement>,
    IManagementProps {
  className?: string;
  onClose: () => void;
}

const MY_ACCESS_KEY = "E_qwNI-BZcMfNnb5TQjd0b-lMvfdwuqUy1vSMfQ8axA";

function MotivationalForm(props: AuthenticationPanelProps) {
  const { values, dirty, errors } = useFormikContext<InitialMotivational>();
  const navigate = useNavigate();
  const location = useLocation();
  const { setFieldValue, setErrors } = useFormikContext();
  const [showYoutubeInput, setShowYoutubeInput] = useState(false);
  const [showImageInput, setShowImageInput] = useState(false);
  const { close } = usePreventTransition();

  const [leaveModalToShow, setLeaveModalToShow] = useState(false);
  const [showLeaveModal, setShowLeaveModal] = useState(false);
  const defaultTagsValue = values.tags;
  useEffect(() => {
    if (location.pathname.includes(APP_ROUTES.MOTIVATIONAL_CREATE)) {
      if (dirty) {
        setLeaveModalToShow(true);
      }
      if (values.tags.length > 0) {
        setLeaveModalToShow(true);
      }
    }
    if (location.pathname.includes(APP_ROUTES.MOTIVATIONAL_EDIT)) {
      if (dirty) {
        setLeaveModalToShow(true);
      }
      if (defaultTagsValue.length !== values.tags.length) {
        setLeaveModalToShow(true);
      }
    }
  }, [location.pathname, dirty, values.tags, defaultTagsValue]);

  const youtubeId = getYouTubeVideoId(values[FIELD_NAMES.VIDEO_LINK]);
  useEffect(() => {
    if (youtubeId) {
      setFieldValue(
        FIELD_NAMES.THUMBNAIL_LINK,
        `https://img.youtube.com/vi/${youtubeId}/mqdefault.jpg`
      );
    } else {
      setFieldValue(FIELD_NAMES.THUMBNAIL_LINK, "");
    }
  }, [setFieldValue, youtubeId]);

  const queryClient = useQueryClient();
  const allMutations = queryClient
    .getMutationCache()
    .findAll({ mutationKey: ["createAIAnalyticMotivational"] });
  const latestAIMutation = useMemo(
    () =>
      allMutations && allMutations.length > 0
        ? //@ts-ignore
          allMutations[allMutations.length - 1]?.state?.data?.data
        : //@ts-ignore
          allMutations[0]?.state?.data?.data,
    [allMutations]
  ) as IAIMotivationalSuggestions | undefined;
  console.debug("latestAIMutation", latestAIMutation);
  return (
    <Form
      className={classnames(
        props.className,
        "flex flex-col md:items-center flex-1"
      )}
    >
      <div className="flex w-full gap-2 sm:gap-4 justify-end">
        <Button
          type="button"
          className={classnames("justify-center")}
          onClick={() => {
            if (leaveModalToShow) {
              setShowLeaveModal(true);
            } else {
              navigate(APP_ROUTES.MOTIVATIONAL_LIST);
            }
          }}
        >
          Cancel
        </Button>
        <Button
          type="submit"
          className={classnames("justify-center", {
            "bg-gray-100 pointer-events-none":
              //@ts-ignore
              props.isSubmitting || props?.errors?.validator,
          })}
          disabled={
            //@ts-ignore
            props.isSubmitting ||
            //@ts-ignore
            props?.errors?.validator
          }
        >
          Publish
        </Button>
      </div>
      <div className="flex flex-col   max-w-[830px]  flex-1">
        <MotivationalTags
          error={errors[FIELD_NAMES.TAGS] as string}
          tags={values[FIELD_NAMES.TAGS]}
          setValue={(tags: string[]) => {
            setFieldValue(FIELD_NAMES.TAGS, tags);
          }}
        />
        {latestAIMutation?.feedback?.quality !==
          CONTENT_QUALITY_LEVELS.GOOD && (
          <div className="w-full px-12 justify-center items-center flex pb-2">
            <p className=" text-red-400 text-sm font-normal">
              {latestAIMutation?.feedback?.comment}
            </p>
          </div>
        )}
        <div className="flex flex-col justify-center items-center min-h-[140px] bg-[#F8F8F8] rounded-lg mb-4">
          <div className="flex flex-row justify-center items-center w-full gap-2 fourteen-fourth-step ">
            {!showYoutubeInput &&
              //@ts-ignore
              values.image && (
                <div className="flex relative  justify-end items-start  max-h-[306px]   w-full   aspect-w-16  flex-row  ">
                  <img
                    alt="banner"
                    className="w-full aspect-[21/9] object-cover max-h-[306px]"
                    src={(values.image as string | undefined) || ""}
                  />
                  <Dismiss
                    onClick={() => {
                      setFieldValue(FIELD_NAMES.VIDEO_LINK, "");
                      setFieldValue(FIELD_NAMES.THUMBNAIL_LINK, "");
                      setFieldValue(FIELD_NAMES.IMAGE, "");
                      setErrors((errors) => ({
                        ...errors,
                        validator: "Please choose a video link or an image",
                      }));
                    }}
                    className="absolute top-0 right-0 mr-5 mt-5 cursor-pointer "
                  />
                </div>
              )}
            {!showYoutubeInput &&
              //@ts-ignore
              !values.image &&
              !values.videoLink && (
                <>
                  <p
                    className="font-bold cursor-pointer"
                    onClick={() => {
                      setShowYoutubeInput(false);
                      setShowImageInput(true);
                      setErrors({
                        validator: "Please choose a video link or an image",
                      });
                    }}
                  >
                    Add Cover
                  </p>

                  <p>or</p>
                  <p
                    className="font-bold cursor-pointer"
                    onClick={() => {
                      setShowImageInput(false);
                      setShowYoutubeInput(true);
                      setErrors({
                        validator: "Please choose a video link or an image",
                      });
                    }}
                  >
                    Youtube Link
                  </p>
                </>
              )}

            {showYoutubeInput && !youtubeId && (
              <div className="flex flex-col w-full gap-4 px-5">
                <div className="w-full">
                  <FormikInput
                    isMotivationalStyle
                    autoComplete="nope"
                    name={FIELD_NAMES.VIDEO_LINK}
                    className="w-full rounded-lg pr-4 text-md px-3"
                    classNames={{ input: " w-full rounded-lg " }}
                  />
                </div>
                <div className=" flex flex-row gap-2 ">
                  <p>or</p>
                  <p
                    className="font-bold cursor-pointer"
                    onClick={() => {
                      setShowYoutubeInput(false);
                      setShowImageInput(true);
                    }}
                  >
                    Add Cover
                  </p>
                </div>
              </div>
            )}
          </div>
          {
            //@ts-ignore
            !!props?.errors?.validator! && showYoutubeInput ? (
              <div className="w-full px-12 justify-center items-center flex">
                <p className=" text-red-400 text-sm font-normal">
                  {"Please enter a valid link for Youtube video"}
                </p>
              </div>
            ) : (
              <div className="w-full px-12 justify-center items-center flex">
                <p className=" text-red-400 text-sm font-normal">
                  {
                    //@ts-ignore
                    props?.errors?.validator!
                  }
                </p>
              </div>
            )
          }

          {values.videoLink && !errors.videoLink && (
            <div className="flex relative  justify-end items-start min-h-[566px] max-h-[566px]  rounded-lg overflow-hidden w-full   aspect-w-16  flex-row  ">
              <div style={{ padding: "100% 100% 0 0" }}>
                <YouTube
                  videoId={youtubeId || ""}
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                  }}
                  opts={{
                    width: "100%",
                    height: "100%",
                  }}
                  title="Sequence Builder Tutorial.mov"
                />
                <Dismiss
                  onClick={() => {
                    setShowYoutubeInput(false);

                    setFieldValue(FIELD_NAMES.VIDEO_LINK, "");
                    setFieldValue(FIELD_NAMES.THUMBNAIL_LINK, "");
                    setFieldValue(FIELD_NAMES.IMAGE, "");
                    setErrors((errors) => ({
                      ...errors,
                      validator: "Please choose a video link or an image",
                    }));
                  }}
                  className="absolute top-0 right-0 mr-5 mt-5 cursor-pointer "
                />
              </div>
            </div>
          )}
        </div>
        <div className="bg-white flex flex-col rounded-lg">
          <div className="p-8 fourteen-fifth-step ">
            <FormikInput
              isMotivationalStyle
              autoComplete="nope"
              name={FIELD_NAMES.TITLE}
              placeholder="Title"
              className="w-full pr-4 text-xl"
              message={
                <div className="flex justify-between text-gray-400  font-light text-sm">
                  <span />
                  <span
                    className={classnames({
                      "text-red-400": errors?.title === "max",
                    })}
                  >{`${values.title.length}/${MAX_TEXT_LENGTH}`}</span>
                </div>
              }
            />
          </div>
          <span className="w-full h-[1px] border border-b border-b-gray-200" />
          <div className="  p-4 h-full w-full flex-1 min-h-[400px] fourteen-sixth-step ">
            <FormikQuill
              name={FIELD_NAMES.DESCRIPTION}
              placeholder="Tell your story"
              className=" h-full "
              classNames={{
                inputContainer: "h-full w-full ",
                input:
                  " w-full h-full focus:placeholder-opacity-0 min-h-[400px] border-0 block   ",
              }}
              showOnlyCustomMessage={errors?.description === "max" || false}
              message={
                <div className="flex justify-between text-gray-400   font-light text-sm">
                  <span />
                  <span
                    className={classnames({
                      "text-red-400": errors?.description === "max",
                    })}
                  >{`${values.description.length}/${MAX_DESC_LENGTH}`}</span>
                </div>
              }
            />
          </div>
        </div>
      </div>

      {showImageInput && (
        <RegularModal
          headerChildren={<p>Choose an image</p>}
          contentChildren={
            <div className="w-full h-full overflow-auto max-h-[500px]">
              <UnsplashReact
                preferredSize={{ width: 1600, height: 900 }}
                orientation="landscape"
                onFinishedUploading={(url) => {
                  setFieldValue(FIELD_NAMES.IMAGE, url);

                  setShowImageInput(false);
                }}
                photoRatio={16 / 9}
                accessKey={MY_ACCESS_KEY}
                Uploader={InsertIntoApplicationUploader}
              />
            </div>
          }
          footerChildren={
            <>
              <Button
                inGroupStyle={false}
                isStyleless
                isReverse
                className=" sm:hidden shrink-0 text-[black] border hover:border-black border-gray-500 "
                onClick={() => {
                  setShowImageInput(false);
                }}
              >
                Cancel
              </Button>
              <Button
                inGroupStyle={false}
                isStyleless
                isReverse
                className="  maxSm:hidden text-[black] border hover:border-black border-gray-500 "
                onClick={() => {
                  setShowImageInput(false);
                }}
              >
                Cancel
              </Button>
            </>
          }
          setShow={setShowImageInput}
        />
      )}
      <Modal isOpen={showLeaveModal} close={close}>
        <div className="flex flex-col">
          <div className="sm:flex sm:items-start">
            <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
              <ExclamationIcon
                className="h-6 w-6 text-red-600"
                aria-hidden="true"
              />
            </div>
            <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
              <Dialog.Title
                as="h3"
                className="text-lg leading-6 font-medium text-gray-900"
              >
                Save changes
              </Dialog.Title>
              <div className="mt-2">
                <p className="text-sm text-gray-500">
                  Are you sure you want to leave without changes?
                </p>
              </div>
            </div>
          </div>
          <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
            <button
              type="button"
              className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
              onClick={() => {
                navigate(APP_ROUTES.MOTIVATIONAL_LIST);
              }}
            >
              Leave
            </button>
            <button
              type="button"
              className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
              onClick={() => {
                setShowLeaveModal(false);
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      </Modal>
    </Form>
  );
}

export default memo(MotivationalForm);
