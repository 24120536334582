import Heading from "components/Heading/Heading";
import {
  useActionGroup,
  useActionGroupUsers,
  useMe,
  useQueryOptionsSetter,
} from "hooks";
import { memo, useCallback, useMemo, useRef, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import ChevronDown from "assets/images/chevron-down.svg";
import { StaticMapCard } from "./StaticMapCard";
import { SlideOver } from "components/SlideOver";
import { Card, CommentsCard, GroupForm, SolutionForm } from "components";
import { ActionGroupCard } from "./ActionGroupCard";
import { UsersCard } from "./UsersCard";

import classnames from "classnames";
import { APP_ROUTES } from "const";
import { FullIssueType, FullSolutionType, IAISuggestions } from "types";
import { useQuery, useQueryClient } from "react-query";
import { Dialog } from "@headlessui/react";

function ActionGroupBreadCrumbs({
  actionGroup,
  solutions,
  issues,
  actionGroupTitle,
}: {
  actionGroup: string | undefined;
  actionGroupTitle: string | undefined;
  solutions: (Partial<FullSolutionType> | undefined)[] | undefined;
  issues: (Partial<FullIssueType> | undefined)[] | undefined;
}) {
  const navigate = useNavigate();
  return (
    <div className="flex flex-col  md:flex-row md:flex-wrap gap-2 md:gap-0 pb-2">
      <span
        onClick={() => {
          navigate(APP_ROUTES.ISSUES);
        }}
        className="text-[#606060] font-normal text-md cursor-pointer  "
      >
        &gt; Issues
      </span>
      {!!issues && !!issues?.length && (
        <span
          onClick={() => {
            if (issues && issues?.length > 1) {
              navigate(APP_ROUTES.ISSUES + "/?actionGroup=" + actionGroup);
            }
            if (issues && issues?.length === 1) {
              navigate(APP_ROUTES.ISSUE + "/" + issues[0]?.id);
            }
          }}
          className="text-[#606060] font-normal text-md cursor-pointer"
        >
          &gt;
          {issues && issues?.length > 1
            ? `Issues targeted by ${actionGroupTitle}`
            : "Issue: " + issues[0]?.title}
        </span>
      )}
      <span
        onClick={() => {
          navigate(APP_ROUTES.SOLUTION_LIST);
        }}
        className="text-[#606060] font-normal text-md cursor-pointer"
      >
        &gt; Solutions
      </span>
      {!!solutions && !!solutions?.length && (
        <span
          onClick={() => {
            if (solutions && solutions?.length > 1) {
              navigate(
                APP_ROUTES.SOLUTION_LIST + "/?actionGroup=" + actionGroup
              );
            }
            if (solutions && solutions?.length === 1) {
              navigate(APP_ROUTES.SOLUTION + "/" + solutions[0]?.id);
            }
          }}
          className="text-[#606060] font-normal text-md cursor-pointer"
        >
          &gt;{" "}
          {solutions && solutions?.length > 1
            ? `Solutions targeted by ${actionGroupTitle}`
            : "Solution: " + solutions[0]?.title || ""}
        </span>
      )}
      <span
        onClick={() => {
          navigate(APP_ROUTES.ACTION_GROUP_LIST);
        }}
        className="text-[#606060] font-normal text-md cursor-pointer"
      >
        &gt; Action groups
      </span>
      <span className="text-[#606060] font-normal text-md cursor-pointer">
        &gt; Action group: {actionGroupTitle}
      </span>
    </div>
  );
}

function ActionGroup() {
  const params = useParams<{ id: string }>();
  const { data: me } = useMe();
  const { data: actionGroupData } = useActionGroup({
    id: params.id,
    suspense: true,
    user: me?.data.id,
  });
  const { data: isMemberList } = useActionGroupUsers({
    actionGroup: params.id,
    user: me?.data.id,
    suspense: true,
  });

  const isMemberOfTheGroup = isMemberList?.data.items?.[0];

  //breadcrumbs
  //const solution = actionGroupData?.data.solution;
  const issue =
    actionGroupData?.data?.solutions &&
    actionGroupData?.data?.solutions[0]?.issue;
  const user = actionGroupData?.data.user;

  const [actionGroupForm, setActionGroupForm] = useState(false);
  const [groupForm, setGroupForm] = useState(false);

  const onAddGroup = useCallback(() => {
    if (me?.data.id) {
      setGroupForm(true);
    }
  }, [me?.data.id]);

  const [searchParams, setSearchParams] = useSearchParams();
  const setQueryOptions = useQueryOptionsSetter(searchParams, setSearchParams);

  const [cardCollapsed, setDescriptionCollapsed] = useState(false);
  const solutions = actionGroupData?.data?.solutions;
  const issues = actionGroupData?.data?.solutions?.map((s) => s?.issue);
  const allLocations = actionGroupData?.data?.solutions?.map(
    (s) => s?.issue?.location
  );
  const uniqueLocations = allLocations?.filter(
    (v, i, a) =>
      a.findIndex((t) => t?.formattedAddress === v?.formattedAddress) === i
  );

  const isSoftDeleted = !!actionGroupData?.data?.deletedAt;

  const [mutationCacheRerenderCounter, setMutationCacheRerenderCounter] =
    useState(0);

  const queryClient = useQueryClient();

  const subscribeCacheCallback = useCallback((queryCacheNotifyEvent) => {
    if (!queryCacheNotifyEvent) {
      return;
    }
    setMutationCacheRerenderCounter((prev) => prev + 1);
  }, []);

  queryClient.getMutationCache().subscribe(subscribeCacheCallback);
  const allAISolutionMutations = queryClient
    .getMutationCache()
    .findAll({ mutationKey: ["createAIAnalyticsSolution"] });
  const allAIGroupsMutations = queryClient.getMutationCache().findAll({
    mutationKey: ["createAIAnalyticsGroups"],
  });
  const latestSolutionAIMutation = useMemo(
    () =>
      allAISolutionMutations && allAISolutionMutations.length > 0
        ? //@ts-ignore
          allAISolutionMutations[allAISolutionMutations.length - 1]?.state?.data
            ?.data
        : //@ts-ignore
          allAISolutionMutations[0]?.state?.data?.data,
    [allAISolutionMutations]
  ) as IAISuggestions | undefined;
  const latestGroupsAIMutation = useMemo(
    () =>
      allAIGroupsMutations && allAIGroupsMutations.length > 0
        ? //@ts-ignore
          allAIGroupsMutations[allAIGroupsMutations.length - 1]?.state?.data
            ?.data
        : //@ts-ignore
          allAIGroupsMutations[0]?.state?.data?.data,
    [allAIGroupsMutations]
  ) as IAISuggestions | undefined;

  const moreFeedbackQualitySolution = useMemo(() => {
    if (!latestSolutionAIMutation) {
      return undefined;
    }
    return latestSolutionAIMutation.more_feedback;
  }, [latestSolutionAIMutation]);

  const moreFeedbackQualityGroup = useMemo(() => {
    if (!latestGroupsAIMutation) {
      return undefined;
    }
    return latestGroupsAIMutation.more_feedback;
  }, [latestGroupsAIMutation]);
  const groupFormRef = useRef<boolean>(false);
  return (
    <div className=" sm:mx-[5%] pt-8 pb-4 max-w-[1600px]">
      <div className="my-2 flex flex-col sm:flex-row gap-2 flex-wrap" />
      <ActionGroupBreadCrumbs
        actionGroupTitle={actionGroupData?.data.title}
        solutions={solutions}
        issues={issues}
        actionGroup={actionGroupData?.data.id}
      />
      <Heading type="h1">
        {actionGroupData?.data.title}{" "}
        {isSoftDeleted && "(This action group was deleted)"}
      </Heading>
      <div className="grid pt-12 lg:grid-cols-2 gap-6">
        <ActionGroupCard
          actionGroup={actionGroupData?.data}
          onEdit={onAddGroup}
        />
        <StaticMapCard
          issue={issue}
          allLocations={uniqueLocations}
          isSoftDeleted={!!actionGroupData?.data?.deletedAt}
        />
        <UsersCard
          actionGroup={actionGroupData?.data}
          allowEdit={user?.id === me?.data.id}
          isSoftDeleted={!!actionGroupData?.data?.deletedAt}
        />
        {actionGroupData?.data?.isPrivate === true ? (
          <>
            {!!isMemberOfTheGroup &&
              (isMemberOfTheGroup.status === "pending" ||
                isMemberOfTheGroup.status === "declined") && (
                <Card
                  className={classnames("bg-white flex-1", {
                    "bg-gray-100 pointer-events-none select-none":
                      !!actionGroupData?.data?.deletedAt,
                  })}
                  shouldHover={false}
                  collapse={cardCollapsed}
                  header={
                    <div className="flex justify-between items-center pb-1">
                      <div className="grid grid-flow-col gap-x-2 items-center h-[46px]">
                        <img
                          alt="chevron-down"
                          className={classnames("cursor-pointer lg:hidden", {
                            "rotate-180": cardCollapsed,
                          })}
                          onClick={() => {
                            setDescriptionCollapsed((val) => !val);
                          }}
                          src={ChevronDown}
                        />
                        <span className="font-bold leading-6 text-lg">
                          Group Discussions
                        </span>
                      </div>
                    </div>
                  }
                  classNames={{
                    header: "px-6 sm:px-16 ",
                    content: "!px-0 flex-1",
                    footer: "px-6 sm:px-16 h-[60px]",
                  }}
                  content={
                    <div className="flex flex-col max-h-[400px] overflow-y-auto overflow-x-hidden items-center justify-center py-10 px-4">
                      <p>
                        Your request to join the group is pending. Once you are
                        accepted, you will be able to participate in group
                        discussions
                      </p>
                    </div>
                  }
                />
              )}
            {!!isMemberOfTheGroup &&
              isMemberOfTheGroup?.status === "approved" && (
                <CommentsCard
                  ruleSet="ActionGroup"
                  status={isMemberOfTheGroup?.status}
                  membership={isMemberOfTheGroup?.role}
                  isPrivate={actionGroupData?.data.isPrivate}
                  entityOwnerId={user?.id}
                />
              )}
            {!isMemberOfTheGroup && (
              <Card
                className={classnames("bg-white flex-1", {
                  "bg-gray-100 pointer-events-none select-none":
                    !!actionGroupData?.data?.deletedAt,
                })}
                shouldHover={false}
                collapse={cardCollapsed}
                header={
                  <div className="flex justify-between items-center pb-1">
                    <div className="grid grid-flow-col gap-x-2 items-center h-[46px]">
                      <img
                        alt="chevron-down"
                        className={classnames("cursor-pointer lg:hidden", {
                          "rotate-180": cardCollapsed,
                        })}
                        onClick={() => {
                          setDescriptionCollapsed((val) => !val);
                        }}
                        src={ChevronDown}
                      />
                      <span className="font-bold leading-6 text-lg">
                        Group Discussions
                      </span>
                    </div>
                  </div>
                }
                classNames={{
                  header: "px-6 sm:px-16 ",
                  content: "!px-0 flex-1",
                  footer: "px-6 sm:px-16 h-[60px]",
                }}
                content={
                  <div className="flex flex-col max-h-[400px] overflow-y-auto overflow-x-hidden items-center justify-center py-10 px-4">
                    <p>
                      Only members of this action group can see and participate
                      in discussions. If you are interested, please request to
                      join this group.
                    </p>
                  </div>
                }
              />
            )}
          </>
        ) : (
          <CommentsCard
            ruleSet="ActionGroup"
            status={isMemberOfTheGroup?.status}
            membership={isMemberOfTheGroup?.role}
            isPrivate={actionGroupData?.data.isPrivate}
            entityOwnerId={user?.id}
          />
        )}
      </div>
      <SlideOver
        id="solution-report"
        open={!!actionGroupForm}
        close={useCallback(() => {
          setActionGroupForm(false);
          queryClient.getMutationCache().clear();
        }, [queryClient])}
        title="Add Solution"
        description={
          <div className="flex flex-col">
            <Dialog.Description className="text-sm  text-gray-500 font-light mt-[14px]">
              This information will be displayed publicly so be careful what you
              share.
            </Dialog.Description>
            {(latestSolutionAIMutation?.feedback_areas?.length || 0) > 0 ? (
              <Dialog.Description className="text-sm  text-red-500 font-light">
                Please review the highlighted sections for suggested
                improvements before we can save your post
              </Dialog.Description>
            ) : null}
            {moreFeedbackQualitySolution &&
            (moreFeedbackQualitySolution.quality === "Useless" ||
              moreFeedbackQualitySolution.quality === "Poor") ? (
              <Dialog.Description className="text-sm  text-red-500 font-light">
                {moreFeedbackQualitySolution?.feedback}
              </Dialog.Description>
            ) : null}
          </div>
        }
      >
        <SolutionForm
          onClose={useCallback(() => {
            setActionGroupForm(false);
            queryClient.getMutationCache().clear();
          }, [queryClient])}
          onSuccess={useCallback(() => {
            setActionGroupForm(false);
            queryClient.getMutationCache().clear();
          }, [queryClient])}
          showSolutionFields
          showSolutionRatings={false}
        />
      </SlideOver>
      <SlideOver
        open={!!groupForm}
        close={useCallback(() => {
          setGroupForm(false);
          setQueryOptions((val) => ({
            ...val,
            groupId: "",
          }));
          queryClient.getMutationCache().clear();
        }, [queryClient, setQueryOptions])}
        title="Edit Group"
        description={
          <div className="flex flex-col">
            <Dialog.Description className="text-sm  text-gray-500 font-light mt-[14px]">
              This information will be displayed publicly so be careful what you
              share.
            </Dialog.Description>
            {(latestGroupsAIMutation?.feedback_areas?.length || 0) > 0 ? (
              <Dialog.Description className="text-sm  text-red-500 font-light">
                Please review the highlighted sections for suggested
                improvements before we can save your post
              </Dialog.Description>
            ) : null}
            {moreFeedbackQualityGroup &&
            (moreFeedbackQualityGroup.quality === "Useless" ||
              moreFeedbackQualityGroup.quality === "Poor") ? (
              <Dialog.Description className="text-sm  text-red-500 font-light">
                {moreFeedbackQualityGroup?.feedback}
              </Dialog.Description>
            ) : null}
          </div>
        }
      >
        <GroupForm
          groupFormRef={groupFormRef}
          onClose={useCallback(() => {
            setGroupForm(false);
            setQueryOptions((val) => ({
              ...val,
              groupId: "",
            }));
            groupFormRef.current = false;
            queryClient.getMutationCache().clear();
          }, [queryClient, setQueryOptions])}
          onSuccess={useCallback(() => {
            setGroupForm(false);
            setQueryOptions((val) => ({
              ...val,
              groupId: "",
            }));
            groupFormRef.current = false;
            queryClient.getMutationCache().clear();
          }, [queryClient, setQueryOptions])}
        />
      </SlideOver>
    </div>
  );
}

export default memo(ActionGroup);
