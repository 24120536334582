/* This example requires Tailwind CSS v2.0+ */
import {
  Fragment,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Dialog, Menu, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import Avatar from "react-avatar";

import { APP_ROUTES } from "const";
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import classnames from "classnames";
import { ReactComponent as Help } from "assets/images/help.svg";
import { ReactComponent as WeLogo } from "assets/images/weatt-wide-logo-v1.svg";
import { ReactComponent as NotificationIcon } from "assets/images/notification.svg";
import { ReactComponent as MenuBurger } from "assets/images/menu-burger.svg";

import {
  Button,
  IssueForm,
  NotificationPopup,
  ProductTourComponent,
  PromptModal,
  ToggleButton,
  ToggleButtonCategoriesGroup,
  ToggleButtonMotivationalTagsGroup,
  ToggleButtonSkillsGroup,
  WarningModal,
} from "components";
import { getAuth } from "firebase/auth";
import {
  useActionGroupsHandouts,
  useCreateHandout,
  useDebouncedHandler,
  useFindUnreadActivitiesAmount,
  useMe,
  useQueryOptionsSetter,
  useSkills,
  useTags,
  useUserSkills,
  useWindowDimensions,
} from "hooks";
import { IAISuggestions, IUser } from "types";
import { computeCenterAndRadius, getRating, logout } from "utils";
import { useHandleJoyrideCallback } from "./MenuBar.hooks";
import { SlideOver } from "components/SlideOver";
import AutoCompleteSelectField from "components/AutoCompleteSelectField";
import { getGeocode } from "use-places-autocomplete";
import _, { isNumber } from "lodash";
import { useSetState } from "react-use";
import Joyride, { Step } from "react-joyride";
import { firstScenarioSteps, fullProductTour } from "const/tourSteps";
import SlideMenuList from "./SlideMenuList";
import { SkillsSelectField } from "./SkillsSelectField";
import { default as ReactSelect } from "react-select";
import { queryClient } from "index";
import { useQuery, useQueryClient } from "react-query";

export interface State {
  run: boolean;
  stepIndex: number;
  steps: Step[];
}

const userNavigation = [
  { name: "Your Profile", href: APP_ROUTES.PROFILE },
  { name: "Settings", href: APP_ROUTES.PROFILE },
  { name: "Sign out", href: "#" },
];

const random = Math.round(Math.random() * 10);
const color =
  random < 4
    ? "lightCoral"
    : random > 4 && random < 7
    ? "lightGreen"
    : "mediumPurple";

interface IMenu {
  isAuth?: boolean;
  setPdfUrl?: React.Dispatch<React.SetStateAction<string | undefined>>;
  setHandoutsNoGroupsFound?:
    | React.Dispatch<React.SetStateAction<"NoGroups" | "Groups" | "NoSearch">>
    | undefined;
  open?: boolean;
  navigation: { current: boolean; href: string; name: string }[];
  profile?: IUser;
  isFetching: boolean;
  close?: () => void;
  isScrolled: boolean;
}

interface IQueryOptions {
  placeId?: string;
  category?: string[] | null;
  user?: string | null;
  lat?: string | null;
  skills?: string[] | null;
  lng?: string | null;
  zoom?: string | null;
}

function DesktopMenu({
  isAuth,
  setPdfUrl,
  setHandoutsNoGroupsFound,
  profile,
  isScrolled,
  isFetching,
}: IMenu) {
  const navigate = useNavigate();

  const [showGoSignInModal, setShowGoSignInModal] = useState(false);
  const { data: unreadActivitiesAmount } = useFindUnreadActivitiesAmount({
    enabled: !!isAuth,
  });

  const location = useLocation();
  const auth = getAuth();
  const [openProductTourPopup, setOpenProductTourPopup] = useState(false);
  const [openNotifications, setOpenNotifications] = useState(false);
  const { data: meData } = useMe();
  const { data: userSkillsData } = useUserSkills({
    id: meData?.data.id,
  });

  const [searchParams, setSearchParams] = useSearchParams();

  const setQueryOptions = useQueryOptionsSetter(searchParams, setSearchParams);
  const onChange = useCallback(
    (value: any) => {
      const placeId = value?.value as string;
      setQueryOptions((values: IQueryOptions) => {
        const value: IQueryOptions = { ...values, placeId };
        return value;
      });
    },
    [setQueryOptions]
  );
  const values = useMemo(() => {
    return {
      category: searchParams.get("category") || "",
      skills: searchParams.getAll("skills") || "",
      user: searchParams.get("user") || "",
    };
  }, [searchParams]);
  const [isOpen, setIsOpen] = useState(false);
  const [openIssueModal, setOpenIssueModal] = useState(false);

  const [skillSearchParam, setSkillSearchParam] = useState("");
  useEffect(() => {
    if (values.skills.length < 1) {
      setCurrentSelectedSkills([]);
    }
  }, [location.pathname, values.skills]);

  const skills = useSkills({
    search: skillSearchParam,
    enabled:
      !!location.pathname.includes(APP_ROUTES.SOLUTION_LIST) ||
      !!location.pathname.includes(APP_ROUTES.ACTION_GROUP_LIST),
  });

  const skillOptions = useMemo(() => {
    return skills?.data?.data.map((skill) => ({
      value: skill.id!,
      label: skill.name,
    }));
  }, [skills?.data?.data]);

  const skillValue = useMemo(() => {
    return values?.skills?.map((item) => {
      return {
        value: item || "",
        label:
          skillOptions?.find((option) => {
            return option.value === item;
          })?.label || "",
      };
    });
  }, [skillOptions, values?.skills]);

  const [currentSelectedSkills, setCurrentSelectedSkills] =
    useState(skillValue);

  const useEffectRef = useRef<boolean>(false);
  useEffect(() => {
    if (
      skillValue.find((item) => {
        return item.label;
      }) &&
      !useEffectRef.current
    ) {
      useEffectRef.current = true;

      setCurrentSelectedSkills(skillValue);
    }
  }, [skillValue]);

  const [userLocationCoordinates, setUserLocationCoordinates] = useState<{
    lat: string;
    lng: string;
    type: string;
    viewport: google.maps.LatLngBounds | null;
  }>({
    lat: "",
    lng: "",
    type: "",
    viewport: null,
  });
  const [searchLocationName, setSearchLocationName] = useState("");
  const placeId = searchParams.get("placeId") || "";
  useEffect(() => {
    if (placeId) {
      getGeocode({ placeId }).then((result) => {
        const latlng = result[0].geometry.location;
        const lat = String(latlng?.lat());
        const lng = String(latlng?.lng());
        const type = result[0].types.includes("continent")
          ? "continent"
          : result[0].types.includes("locality")
          ? "locality"
          : "country";
        setSearchLocationName(result[0].formatted_address);
        setUserLocationCoordinates({
          lat: lat,
          lng: lng,
          type: type,
          viewport: result[0].geometry.viewport,
        });
      });
    }
    if (!placeId) {
      setUserLocationCoordinates({
        lat: "",
        lng: "",
        type: "",
        viewport: null,
      });
      setSearchLocationName("");
    }
  }, [placeId]);
  //

  let rangeResult = 0;

  if (userLocationCoordinates?.viewport) {
    rangeResult = computeCenterAndRadius(
      userLocationCoordinates?.viewport
    ).radius;
  }
  let enabled = false;
  if (
    placeId &&
    userLocationCoordinates.lat &&
    userLocationCoordinates.lng &&
    location.pathname === APP_ROUTES.HANDOUTS &&
    rangeResult
  ) {
    enabled = true;
  }

  const { data: groupData } = useActionGroupsHandouts({
    limit: 999,
    range: rangeResult,
    lat: userLocationCoordinates.lat,
    lng: userLocationCoordinates.lng,
    keepPreviousData: true,
    enabled,
    cacheTime: 0,
    staleTime: Infinity,
  });
  //
  const prepareActionGroupData = useMemo(() => {
    return {
      action_group_info: groupData?.data.map((item) => {
        const result = {
          title: [item.title],
          telegram_url: [item.address],
          description: [item.description],
          schedule: [item.schedule],
          contact: [item.contactUrl],
          stage: [item.stage],
          logo: [item.logo],
          issue_title: [item.significantIssueTitle],
          issue_location: [item.significantAddress],
          issue_rating: [getRating(item.significantIssueRating).toString()],
          issue_category: item.issueRatings,
        };
        return result;
      }),
      search_location: searchLocationName || "",
    };
  }, [groupData, searchLocationName]);

  const groupDataLength = groupData?.data.length;
  useEffect(() => {
    if (setHandoutsNoGroupsFound && groupData?.data.length === 0) {
      setHandoutsNoGroupsFound("NoGroups");
    }
    if (
      setHandoutsNoGroupsFound &&
      groupData?.data &&
      Number(groupDataLength) > 0
    ) {
      setHandoutsNoGroupsFound("Groups");
    }

    if (setHandoutsNoGroupsFound && !placeId) {
      setHandoutsNoGroupsFound("NoSearch");
    }
  }, [groupData?.data, groupDataLength, placeId, setHandoutsNoGroupsFound]);

  const { mutate: mutateHandoutSheet, data } = useCreateHandout({});
  useEffect(() => {
    if (data?.data[0]) {
      //@ts-ignore
      setPdfUrl(data?.data[0] as string);
    }
  }, [data, setPdfUrl]);

  const { data: dataTags } = useTags({});

  const preparedData = dataTags?.data.map((tag) => tag);

  const options = useMemo(() => {
    return _.compact(
      preparedData?.map((item) => ({
        label: item.name,
        value: item.id || "",
      }))
    );
  }, [preparedData]);

  const [currentSelectedTags, setCurrentSelectedTags] = useState(options);

  const useEffectMotivationalRef = useRef<boolean>(false);
  useEffect(() => {
    if (
      options.find((item) => {
        return item.label;
      }) &&
      !useEffectMotivationalRef.current
    ) {
      useEffectMotivationalRef.current = true;
      setCurrentSelectedTags(options);
    }
  }, [options]);
  const [openWarningModal, setOpenWarningModal] = useState(false);
  useEffect(() => {
    setOpenWarningModal(false);
  }, [location.pathname]);

  const [scenarioSteps, setScenarioSteps] =
    useState<Step[]>(firstScenarioSteps);
  const [currentScenario, setCurrentScenario] = useState(1);

  const [{ run, stepIndex }, setState] = useSetState<State>({
    run: false,
    stepIndex: 0,
    steps: scenarioSteps,
  });

  const [preventClose, setPreventClose] = useState(false);
  const handleClickStart = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      event.preventDefault();
      window.scrollTo(0, 0);
      setState((prevState) => ({
        ...prevState,
        run: true,
        stepIndex: 0,
      }));
    },
    [setState]
  );

  const handleJoyrideCallback = useHandleJoyrideCallback({
    setJoyState: setState,
    setPreventClose,
    setIsOpen,
    setOpenIssueModal,
    currentScenario,
  });
  const dimensions = useWindowDimensions();
  const [openNotificationSlideover, setOpenNotificationSlideover] =
    useState(false);
  useEffect(() => {
    if (openNotificationSlideover === false) {
      setIsOpen(false);
    }
  }, [openNotificationSlideover]);

  const [showReportPromptModal, setShowReportPromptModal] = useState(false);

  const [createdIssueId, setCreatedIssueId] = useState<string | undefined>(
    undefined
  );
  const [showSuccessReportPromptModal, setShowSuccessReportPromptModal] =
    useState(false);
  useEffect(() => {
    if (createdIssueId) {
      setShowSuccessReportPromptModal(true);
    }
  }, [createdIssueId]);

  const onCloseIssueReport = useCallback(() => {
    setCreatedIssueId(undefined);
    setOpenIssueModal(false);
    queryClient.getMutationCache().clear();
  }, [setOpenIssueModal, setCreatedIssueId]);

  const onSuccessIssueReport = useCallback(
    (id?: string) => {
      setCreatedIssueId(id);
      setOpenIssueModal(false);
      queryClient.getMutationCache().clear();
    },
    [setOpenIssueModal, setCreatedIssueId]
  );

  const notEnoughPersonalData =
    (!meData?.data.firstName && !meData?.data.firstName) ||
    !userSkillsData?.data ||
    userSkillsData?.data.length < 1;

  const amount = +(unreadActivitiesAmount?.data || 0);
  const notificationsAreMoreThanZero =
    isNumber(amount) && !isNaN(amount || 0) && amount > 0;

  const onSkillsChange = useCallback(
    (value: any) => {
      //ISelectOption | ISelectOption[]
      if (value && Array.isArray(value)) {
        setCurrentSelectedSkills(() => [...value]);
        setQueryOptions((values: IQueryOptions) => {
          const val: IQueryOptions = {
            ...values,
            skills: [
              ...value.map((item) => {
                return item.value as string;
              }),
            ],
          };
          return val;
        });
      }
      if (value && !Array.isArray(value)) {
        setCurrentSelectedSkills((prev) => [...prev, value]);
        setQueryOptions((values: IQueryOptions) => {
          let skills: string[] = [];
          if (values && values?.skills && value?.value) {
            skills = [...values?.skills, value.value];
          }
          return {
            ...values,
            skills,
          };
        });
      }
    },
    [setQueryOptions]
  );

  const [searchBy, setSearchBy] = useState<"location" | "tag">("location");

  useEffect(() => {
    setSearchBy("location");
  }, [location.pathname]);

  const searchByOptions = [
    { value: "location", label: "Search by location" },
    { value: "tag", label: "Search by tag" },
  ];

  const onInputChange = useDebouncedHandler(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const params = new URLSearchParams(location.search);
      if (e.target.value) {
        params.set("tags", e.target.value);
      } else {
        params.delete("tags");
      }
      navigate({ search: params.toString() });
    }
  );

  const [mutationCacheRerenderCounter, setMutationCacheRerenderCounter] =
    useState(0);

  const queryClient = useQueryClient();

  const subscribeCacheCallback = useCallback((queryCacheNotifyEvent) => {
    if (!queryCacheNotifyEvent) {
      return;
    }
    setMutationCacheRerenderCounter((prev) => prev + 1);
  }, []);

  queryClient.getMutationCache().subscribe(subscribeCacheCallback);

  const allMutations = queryClient
    .getMutationCache()
    .findAll({ mutationKey: ["createAIAnalyticsIssue"] });
  const latestAIMutation = useMemo(
    () =>
      allMutations && allMutations.length > 0
        ? //@ts-ignore
          allMutations[allMutations.length - 1]?.state?.data?.data
        : //@ts-ignore
          allMutations[0]?.state?.data?.data,
    [allMutations]
  ) as IAISuggestions | undefined;

  const moreFeedbackQuality = useMemo(() => {
    if (!latestAIMutation) {
      return undefined;
    }
    return latestAIMutation.more_feedback;
  }, [latestAIMutation]);

  return (
    <div className={classnames("layout-width px-[5%] sm:px-0")}>
      <Joyride
        stepIndex={stepIndex}
        callback={handleJoyrideCallback}
        continuous
        hideBackButton
        disableScrolling
        hideCloseButton
        run={run}
        scrollToFirstStep
        showProgress
        showSkipButton
        steps={scenarioSteps}
        styles={{
          options: {
            zIndex: 10000,
          },
        }}
      />
      <div className="flex justify-between   pt-3 pb-3">
        <div
          className={classnames(
            "sm:flex maxSm:flex-row-reverse gap-4 maxSm:w-[85%] w-[95%] items-center hidden"
          )}
        >
          <div
            className={classnames(
              "hidden sm:-my-px  sm:flex sm:gap-7 max-w-[800px]",
              {
                "flex-1": location.pathname !== APP_ROUTES.MOTIVATIONAL_LIST,
              }
            )}
          >
            <div className="flex shrink-0 -mr-2 items-center">
              <button
                onClick={() => {
                  setIsOpen((val) => !val);
                }}
                className="  inline-flex items-center justify-center p-2 rounded-md text-black hover:text-gray-700 "
              >
                <span className="sr-only">Open main menu</span>
                {isOpen ? (
                  <XIcon
                    className={classnames("block h-6 w-6  ", {
                      "text-black": isScrolled,
                    })}
                    aria-hidden="true"
                  />
                ) : (
                  <>
                    <MenuBurger className="block h-6 w-6" aria-hidden="true" />
                  </>
                )}
              </button>
            </div>

            <div className=" flex-shrink-0 flex items-center maxSm:hidden">
              <WeLogo
                onClick={() => {
                  navigate(APP_ROUTES.DASHBOARD);
                }}
                className="h-[44px] w-[190px] cursor-pointer"
              />
            </div>
            {dimensions.width > 640 &&
              (location.pathname === "/" ||
                //location.pathname === APP_ROUTES.DASHBOARD ||
                location.pathname === APP_ROUTES.ISSUES ||
                location.pathname === APP_ROUTES.SOLUTION_LIST ||
                location.pathname === APP_ROUTES.ACTION_GROUP_LIST ||
                location.pathname === APP_ROUTES.HANDOUTS) && (
                <>
                  <ReactSelect
                    value={searchByOptions.filter(
                      (option) => option.value === searchBy
                    )}
                    onChange={(search) => {
                      setSearchBy(search?.value as "location" | "tag");
                    }}
                    options={searchByOptions}
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        height: "42px",
                        width: "132px",
                        fontSize: "14px",
                        cursor: "pointer",
                      }),
                      menu: (provided) => ({
                        ...provided,
                        margin: "0px",
                        zIndex: "10",
                      }),
                      option: (provided, state) => ({
                        ...provided,
                        cursor: "pointer",
                        fontSize: "12px",
                        backgroundColor: state.isSelected
                          ? "#E5E5E5"
                          : "transparent",
                        color: state.isSelected ? "#000000" : "#000000",
                        "&:hover": {
                          backgroundColor: "#E5E5E5",
                          color: "#000000",
                        },
                      }),
                      menuList: (provided) => ({
                        ...provided,
                        padding: "0px",
                        margin: "0px",
                      }),
                    }}
                    className={classnames({
                      hidden: location.pathname === APP_ROUTES.HANDOUTS,
                    })}
                  />
                  {searchBy === "location" ? (
                    <AutoCompleteSelectField
                      square
                      isSearchInput
                      showSearchIcon
                      filterOption={() => {
                        return true;
                      }}
                      onChange={onChange}
                      placeholder="Search by location"
                      className="one-second-step three-second-step four-second-step five-second-step six-second-step seven-second-step eight-second-step nine-second-step ten-second-step eleven-second-step twelve-second-step  w-full max-w-[700px] !rounded-none  !min-w-[115px] !flex-row !items-center !pb-0  border-b focus-within:border-black border-transparent"
                    />
                  ) : (
                    <input
                      className="w-full max-w-[700px] rounded-none min-w-[125px] h-[42px] border border-gray-300 pl-4 text-sm"
                      onChange={onInputChange}
                      placeholder="Search by tag"
                    />
                  )}
                </>
              )}
          </div>

          {(location.pathname === APP_ROUTES.SOLUTION_LIST ||
            location.pathname === APP_ROUTES.ACTION_GROUP_LIST) && (
            <div className="   hidden sm:flex gap-[2rem] items-center maxSm:flex-wrap md:flex-nowrap">
              <SkillsSelectField
                onSkillsChange={onSkillsChange}
                skillOptions={skillOptions}
                currentSelectedSkills={currentSelectedSkills}
                setSkillSearchParam={setSkillSearchParam}
              />
            </div>
          )}

          {(location.pathname === APP_ROUTES.ISSUES ||
            location.pathname === APP_ROUTES.SOLUTION_LIST ||
            location.pathname === APP_ROUTES.ACTION_GROUP_LIST ||
            location.pathname === APP_ROUTES.MOTIVATIONAL_LIST) &&
            !!meData?.data.id && (
              <ToggleButton
                className="px-2 thirteen-third-step fifteen-third-step "
                enabled={!!meData?.data.id && !!values.user}
                setEnabled={() => {
                  setQueryOptions((values: IQueryOptions) => {
                    const user = values?.user ? "" : meData?.data.id || "";
                    return {
                      ...values,
                      user,
                    };
                  });
                }}
                label={
                  location.pathname === APP_ROUTES.ISSUES
                    ? "My issues"
                    : location.pathname === APP_ROUTES.SOLUTION_LIST
                    ? "My solutions"
                    : location.pathname === APP_ROUTES.ACTION_GROUP_LIST
                    ? "My groups"
                    : location.pathname === APP_ROUTES.MOTIVATIONAL_LIST
                    ? "My contents"
                    : ""
                }
              />
            )}
          {dimensions.width > 640 &&
            location.pathname === APP_ROUTES.ISSUES &&
            isAuth && (
              <div className=" two-second-step flex gap-[2rem] items-center maxSm:flex-wrap md:flex-nowrap">
                <Button
                  className="h-[42px]  w-[96px]   justify-center flex  shrink-0"
                  onClick={() => {
                    if (isAuth) {
                      setShowReportPromptModal(true);
                    }
                  }}
                >
                  Report
                </Button>
              </div>
            )}

          {location.pathname === APP_ROUTES.SOLUTION_LIST && isAuth && (
            <div className=" flex gap-[2rem] items-center maxSm:flex-wrap md:flex-nowrap">
              <Button
                className="h-[42px]  w-[96px]   justify-center flex  shrink-0"
                onClick={() => {
                  if (isAuth) {
                    setOpenWarningModal(true);
                  }
                }}
              >
                Propose
              </Button>
            </div>
          )}
          {location.pathname === APP_ROUTES.ACTION_GROUP_LIST && isAuth && (
            <div className=" flex gap-[2rem] items-center maxSm:flex-wrap md:flex-nowrap">
              <Button
                className="h-[42px]  w-[96px]   justify-center flex  shrink-0"
                onClick={() => {
                  if (isAuth) {
                    setOpenWarningModal(true);
                  }
                }}
              >
                Create
              </Button>
            </div>
          )}
          {location.pathname === APP_ROUTES.MOTIVATIONAL_LIST &&
            isAuth &&
            dimensions.width > 640 && (
              <div className=" flex gap-[2rem] items-center maxSm:flex-wrap md:flex-nowrap fourteen-second-step ">
                <Button
                  className="h-[42px]  w-[96px]   justify-center flex  shrink-0 create-motivational-button-to-click"
                  onClick={() => {
                    if (isAuth) {
                      navigate(APP_ROUTES.MOTIVATIONAL_CREATE);
                    }
                  }}
                >
                  Create
                </Button>
              </div>
            )}
          {location.pathname === APP_ROUTES.HANDOUTS && (
            <Button
              disabled={!groupData?.data?.length || !placeId}
              className="px-2"
              onClick={() => {
                mutateHandoutSheet(prepareActionGroupData);
              }}
            >
              Find resources
            </Button>
          )}
        </div>

        {isAuth && (
          <div className="hidden sm:ml-6 gap-2 sm:flex sm:items-center">
            {dimensions.width > 640 && (
              <div className="relative">
                <button
                  type="button"
                  onClick={() => {
                    setOpenProductTourPopup((val) => !val);
                  }}
                  className={classnames("relative p-1 rounded-full  ")}
                >
                  <Help className="h-6 w-6" aria-hidden="true" />
                </button>
                {openProductTourPopup && (
                  <ProductTourComponent
                    setIsOpen={setIsOpen}
                    setOpenNotificationSlideover={setOpenNotificationSlideover}
                    setOpenProductTourPopup={setOpenProductTourPopup}
                    setCurrentScenario={setCurrentScenario}
                    setScenarioSteps={setScenarioSteps}
                    handleClickStart={handleClickStart}
                    fullProductTour={fullProductTour}
                  />
                )}
              </div>
            )}

            <div className="relative ">
              <button
                onClick={() => {
                  setOpenNotifications((val) => !val);
                }}
                type="button"
                className={classnames(
                  "relative p-1 rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 ",
                  {
                    " !ring-4 !ring-offset-4 !ring-red-500":
                      notEnoughPersonalData,
                  }
                  // {
                  //   "!bg-black !text-white ring-offset-2 ring-2 outline-none !ring-red-500 ":
                  //     unreadNotifications,
                  // },
                  // { "bg-black text-white focus:ring-white": !isScrolled },
                  // { "bg-white text-black focus:ring-black": isScrolled }
                )}
              >
                {notificationsAreMoreThanZero && (
                  <span
                    className={classnames(
                      " absolute flex  bg-black rounded-full bottom-[60%] left-[60%] text-white outline outline-2 outline-white text-xs font-black  text-white-stroke-sm text-center items-center justify-center",
                      {
                        "w-[22px] h-[22px]": amount > 99,
                      },
                      {
                        "w-[18px] h-[18px]": amount > 9,
                      },
                      {
                        "w-[14px] h-[14px]": amount < 10,
                      }
                    )}
                  >
                    {amount < 100 ? amount : "99+"}
                  </span>
                )}
                <span className="sr-only">View notifications</span>

                <NotificationIcon className="h-6 w-6" aria-hidden="true" />
              </button>
              {openNotifications && (
                <NotificationPopup
                  key="NotificationPopup"
                  setOpenNotifications={setOpenNotifications}
                />
              )}
            </div>

            {/* Profile dropdown */}
            <Menu as="div" className="ml-3 relative">
              <div>
                <Menu.Button className="max-w-xs bg-white flex items-center text-sm rounded-full focus:outline-none">
                  <span className="sr-only">Open user menu</span>
                  <div className="h-8 w-8 rounded-full overflow-hidden bg-gray-300 flex justify-center items-center">
                    {profile?.avatar && !isFetching && (
                      <img src={profile?.avatar} alt="" />
                    )}
                    {(!profile?.avatar || isFetching) && (
                      <Avatar
                        size="32"
                        className="block group-hover:hidden"
                        color={color}
                        name={`${profile?.firstName} ${profile?.lastName}`}
                      />
                    )}
                  </div>
                </Menu.Button>
              </div>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-200"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="z-10 origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                  {userNavigation.map((item) => (
                    <Menu.Item key={item.name}>
                      {({ active }) => (
                        <Link
                          to={item.href}
                          onClick={() => {
                            if (item.name === "Sign out") {
                              auth.signOut().then(() => {
                                logout();
                              });
                            }
                          }}
                          className={classnames(
                            "block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100",
                            {
                              "bg-gray-100": active,
                            }
                          )}
                        >
                          {item.name}
                        </Link>
                      )}
                    </Menu.Item>
                  ))}
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
        )}
        {!isAuth && (
          <div className="hidden sm:ml-6 sm:flex sm:items-center gap-4">
            {dimensions.width > 640 && (
              <div className="relative">
                <button
                  type="button"
                  onClick={() => {
                    setShowGoSignInModal((val) => !val);
                  }}
                  className={classnames("relative p-1 rounded-full  ")}
                >
                  <Help className="h-6 w-6" aria-hidden="true" />
                </button>
                {openProductTourPopup && (
                  <ProductTourComponent
                    setIsOpen={setIsOpen}
                    setOpenNotificationSlideover={setOpenNotificationSlideover}
                    setOpenProductTourPopup={setOpenProductTourPopup}
                    setCurrentScenario={setCurrentScenario}
                    setScenarioSteps={setScenarioSteps}
                    handleClickStart={handleClickStart}
                    fullProductTour={fullProductTour}
                  />
                )}
              </div>
            )}
            <Link state={{ from: location.pathname }} to={APP_ROUTES.SIGN_IN}>
              <Button isStyleless>
                <span
                  className={classnames(
                    { "text-black": isScrolled },
                    "whitespace-nowrap "
                  )}
                >
                  Sign in
                </span>
              </Button>
            </Link>
            <Link to={APP_ROUTES.SIGN_UP}>
              <Button isReverse={isScrolled}>Register</Button>
            </Link>
          </div>
        )}

        <div className="-mr-2 flex flex-col gap-4 items-center sm:hidden flex-1">
          <div className="flex flex-row justify-end w-full gap-4">
            {dimensions.width < 641 &&
              (location.pathname === "/" ||
                //location.pathname === APP_ROUTES.DASHBOARD ||
                location.pathname === APP_ROUTES.ISSUES ||
                location.pathname === APP_ROUTES.SOLUTION_LIST ||
                location.pathname === APP_ROUTES.ACTION_GROUP_LIST ||
                location.pathname === APP_ROUTES.HANDOUTS) && (
                <>
                  <ReactSelect
                    value={searchByOptions.filter(
                      (option) => option.value === searchBy
                    )}
                    onChange={(search) => {
                      setSearchBy(search?.value as "location" | "tag");
                    }}
                    options={searchByOptions}
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        height: "42px",
                        width: "132px",
                        fontSize: "14px",
                        cursor: "pointer",
                      }),
                      menu: (provided) => ({
                        ...provided,
                        margin: "0px",
                        zIndex: "10",
                      }),
                      option: (provided, state) => ({
                        ...provided,
                        cursor: "pointer",
                        fontSize: "12px",
                        backgroundColor: state.isSelected
                          ? "#E5E5E5"
                          : "transparent",
                        color: state.isSelected ? "#000000" : "#000000",
                        "&:hover": {
                          backgroundColor: "#E5E5E5",
                          color: "#000000",
                        },
                      }),
                      menuList: (provided) => ({
                        ...provided,
                        padding: "0px",
                        margin: "0px",
                      }),
                    }}
                    className={classnames({
                      hidden: location.pathname === APP_ROUTES.HANDOUTS,
                    })}
                  />
                  {searchBy === "location" ? (
                    <AutoCompleteSelectField
                      square
                      isSearchInput
                      showSearchIcon
                      filterOption={() => {
                        return true;
                      }}
                      onChange={onChange}
                      placeholder="Search by location"
                      className="one-second-step three-second-step four-second-step five-second-step six-second-step seven-second-step eight-second-step nine-second-step ten-second-step eleven-second-step twelve-second-step  w-full max-w-[700px] !rounded-none  !min-w-[115px] !flex-row !items-center !pb-0  border-b focus-within:border-black border-transparent"
                    />
                  ) : (
                    <input
                      className="w-full max-w-[700px] rounded-none min-w-[125px] h-[42px] border border-gray-300 pl-4 text-sm"
                      onChange={onInputChange}
                      placeholder="Search by tag"
                    />
                  )}
                </>
              )}
            <button
              onClick={() => {
                setIsOpen((val) => !val);
              }}
              className=" relative inline-flex items-center justify-center p-2 rounded-md text-black hover:text-gray-700 "
            >
              {notificationsAreMoreThanZero && (
                <span
                  className={classnames(
                    " absolute flex  bg-black rounded-full bottom-[60%] left-[60%] text-white outline outline-2 outline-white text-xs font-black  text-white-stroke-sm text-center items-center justify-center",
                    {
                      "w-[22px] h-[22px]": amount > 99,
                    },
                    {
                      "w-[18px] h-[18px]": amount > 9,
                    },
                    {
                      "w-[14px] h-[14px]": amount < 10,
                    }
                  )}
                >
                  {amount < 100 ? amount : "99+"}
                </span>
              )}
              <span className="sr-only">Open main menu</span>
              {isOpen ? (
                <XIcon className="block h-6 w-6" aria-hidden="true" />
              ) : (
                <MenuBurger className="block h-6 w-6" aria-hidden="true" />
              )}
            </button>
          </div>
          <div className="flex flex-row gap-2 justify-between w-full">
            {dimensions.width < 641 &&
              location.pathname === APP_ROUTES.ISSUES &&
              isAuth && (
                <>
                  <Button
                    className="two-second-step h-[42px]  w-[96px]  justify-center flex  shrink-0"
                    onClick={() => {
                      if (isAuth) {
                        setOpenIssueModal(true);
                      }
                    }}
                  >
                    Report
                  </Button>
                </>
              )}

            {(location.pathname === APP_ROUTES.SOLUTION_LIST ||
              location.pathname === APP_ROUTES.ACTION_GROUP_LIST) && (
              <SkillsSelectField
                onSkillsChange={onSkillsChange}
                skillOptions={skillOptions}
                currentSelectedSkills={currentSelectedSkills}
                setSkillSearchParam={setSkillSearchParam}
                mainClassName="max-w-[132px]"
              />
            )}

            {(location.pathname === APP_ROUTES.ISSUES ||
              location.pathname === APP_ROUTES.SOLUTION_LIST ||
              location.pathname === APP_ROUTES.ACTION_GROUP_LIST ||
              location.pathname === APP_ROUTES.MOTIVATIONAL_LIST) &&
              !!meData?.data.id && (
                <>
                  {location.pathname === APP_ROUTES.SOLUTION_LIST && isAuth && (
                    <Button
                      className="h-[42px]  w-[96px]   justify-center flex  shrink-0"
                      onClick={() => {
                        if (isAuth) {
                          setOpenWarningModal(true);
                        }
                      }}
                    >
                      Propose
                    </Button>
                  )}
                  {location.pathname === APP_ROUTES.ACTION_GROUP_LIST &&
                    isAuth && (
                      <div className=" flex gap-[2rem] items-center maxSm:flex-wrap md:flex-nowrap">
                        <Button
                          className="h-[42px]  w-[96px]   justify-center flex  shrink-0"
                          onClick={() => {
                            if (isAuth) {
                              setOpenWarningModal(true);
                            }
                          }}
                        >
                          Create
                        </Button>
                      </div>
                    )}
                  {location.pathname === APP_ROUTES.MOTIVATIONAL_LIST &&
                    isAuth &&
                    dimensions.width < 641 && (
                      <Button
                        className="h-[42px]  w-[96px]   justify-center flex  shrink-0 fourteen-second-step create-motivational-button-to-click "
                        onClick={() => {
                          if (isAuth) {
                            navigate(APP_ROUTES.MOTIVATIONAL_CREATE);
                          }
                        }}
                      >
                        Create
                      </Button>
                    )}
                  <ToggleButton
                    className="px-2 thirteen-third-step "
                    enabled={!!meData?.data.id && !!values.user}
                    setEnabled={() => {
                      setQueryOptions((values: IQueryOptions) => {
                        const user = values?.user ? "" : meData?.data.id || "";
                        return {
                          ...values,
                          user,
                        };
                      });
                    }}
                    label={
                      location.pathname === APP_ROUTES.ISSUES
                        ? "My issues"
                        : location.pathname === APP_ROUTES.SOLUTION_LIST
                        ? "My solutions"
                        : location.pathname === APP_ROUTES.ACTION_GROUP_LIST
                        ? "My groups"
                        : location.pathname === APP_ROUTES.MOTIVATIONAL_LIST
                        ? "My contents"
                        : ""
                    }
                  />
                </>
              )}
            {location.pathname === APP_ROUTES.HANDOUTS && (
              <Button
                disabled={!groupData?.data.length || !placeId}
                className="px-2"
                onClick={() => {
                  mutateHandoutSheet(prepareActionGroupData);
                }}
              >
                Find resources
              </Button>
            )}
          </div>
          {/* Mobile menu button */}
        </div>
      </div>
      <SlideMenuList
        isOpen={isOpen}
        preventClose={preventClose}
        setIsOpen={setIsOpen}
        isScrolled={isScrolled}
        isAuth={!!isAuth}
        profile={profile}
        notEnoughPersonalData={notEnoughPersonalData}
        setOpenNotificationSlideover={setOpenNotificationSlideover}
        setOpenProductTourPopup={setOpenProductTourPopup}
        openProductTourPopup={openProductTourPopup}
        setCurrentScenario={setCurrentScenario}
        setScenarioSteps={setScenarioSteps}
        handleClickStart={handleClickStart}
        setShowGoSignInModal={setShowGoSignInModal}
      />
      <SlideOver
        open={openNotificationSlideover}
        close={() => {
          setOpenNotificationSlideover(false);
        }}
        title="Notifications"
      >
        <div className="flex max-h-[calc(100vh-143px)]">
          <NotificationPopup
            mobileVersion={true}
            setOpenNotifications={setOpenNotificationSlideover}
          />
        </div>
      </SlideOver>
      <SlideOver
        id="issue-report"
        open={openIssueModal}
        close={onCloseIssueReport}
        title="Report Issue"
        description={
          <div className="flex flex-col">
            <Dialog.Description className="text-sm  text-gray-500 font-light mt-[14px]">
              This information will be displayed publicly so be careful what you
              share.
            </Dialog.Description>
            {(latestAIMutation?.feedback_areas?.length || 0) > 0 ? (
              <Dialog.Description className="text-sm  text-red-500 font-light">
                Please review the highlighted sections for suggested
                improvements before we can save your post
              </Dialog.Description>
            ) : null}
            {moreFeedbackQuality &&
            (moreFeedbackQuality.quality === "Useless" ||
              moreFeedbackQuality.quality === "Poor") ? (
              <Dialog.Description className="text-sm  text-red-500 font-light">
                {latestAIMutation?.more_feedback?.feedback}
              </Dialog.Description>
            ) : null}
          </div>
        }
      >
        <IssueForm
          onSuccess={onSuccessIssueReport}
          onClose={onCloseIssueReport}
        />
      </SlideOver>
      {!!showSuccessReportPromptModal && createdIssueId && (
        <PromptModal
          setShow={setShowSuccessReportPromptModal}
          header="Report Issue"
          content="Will you like to propose a solution for this issue?"
          footer={
            <div className="flex flex-row gap-2 justify-between w-full">
              <Button
                inGroupStyle={false}
                isStyleless
                isReverse
                className=" shrink-0 text-[black] border hover:border-black border-gray-500 "
                onClick={() => {
                  setShowSuccessReportPromptModal(false);
                  navigate(APP_ROUTES.ISSUE + "/" + createdIssueId);
                }}
              >
                Yes
              </Button>
              <Button
                inGroupStyle={false}
                isStyleless
                isReverse
                className=" text-[black] border hover:border-black border-gray-500 "
                onClick={() => {
                  setShowSuccessReportPromptModal(false);
                  setCreatedIssueId(undefined);
                }}
              >
                Maybe later
              </Button>
            </div>
          }
        />
      )}

      {(location.pathname === APP_ROUTES.SOLUTION_LIST ||
        location.pathname === APP_ROUTES.ACTION_GROUP_LIST) && (
        <ToggleButtonSkillsGroup
          setCurrentSelectedSkills={setCurrentSelectedSkills}
          setQueryOptions={setQueryOptions}
          currentSelectedSkills={currentSelectedSkills}
        />
      )}

      {location.pathname === APP_ROUTES.ISSUES && (
        <ToggleButtonCategoriesGroup />
      )}
      {location.pathname === APP_ROUTES.MOTIVATIONAL_LIST && (
        <ToggleButtonMotivationalTagsGroup
          setCurrentSelectedTags={setCurrentSelectedTags}
          setQueryOptions={setQueryOptions}
          tags={currentSelectedTags}
        />
      )}

      {openWarningModal && <WarningModal setShow={setOpenWarningModal} />}
      {showReportPromptModal && (
        <PromptModal
          setShow={setShowReportPromptModal}
          header="Report Issue"
          content="Before adding a new issue, please ensure a similar
issue for this location does not exist already"
          footer={
            <div className="flex flex-row gap-2 justify-between w-full">
              <Button
                inGroupStyle={false}
                isStyleless
                isReverse
                className=" shrink-0 text-[black] border hover:border-black border-gray-500 "
                onClick={() => {
                  setShowReportPromptModal(false);
                  setOpenIssueModal(true);
                }}
              >
                Continue, I have checked
              </Button>
              <Button
                inGroupStyle={false}
                isStyleless
                isReverse
                className=" text-[black] border hover:border-black border-gray-500 "
                onClick={() => {
                  setShowReportPromptModal(false);
                }}
              >
                Cancel
              </Button>
            </div>
          }
        />
      )}
      {showGoSignInModal && (
        <PromptModal
          setShow={setShowGoSignInModal}
          header="Warning"
          content="You have to sign in to perform this action"
          footer={
            <div className="flex flex-row gap-2 justify-between w-full">
              <Button
                inGroupStyle={false}
                isStyleless
                isReverse
                className=" shrink-0 text-[black] border hover:border-black border-gray-500 "
                onClick={() => {
                  setShowGoSignInModal(false);
                  setTimeout(() => {
                    navigate(APP_ROUTES.SIGN_IN);
                  }, 100);
                }}
              >
                Ok
              </Button>
              <Button
                inGroupStyle={false}
                isStyleless
                isReverse
                className=" text-[black] border hover:border-black border-gray-500 "
                onClick={(e) => {
                  e.stopPropagation();
                  setShowGoSignInModal(false);
                }}
              >
                Maybe later
              </Button>
            </div>
          }
        />
      )}
    </div>
  );
}
export default memo(DesktopMenu);
