import { useField } from "formik";
import classnames from "classnames";
import { ITextareaProps, Textarea, Label } from "components";
import { FormikInputContentQuality } from "components/FormikInputContentQuality";
import { IFeedbackArea } from "types";
import { twMerge } from "tailwind-merge";

interface IProps extends Omit<ITextareaProps, "classNames"> {
  classNames?: {
    inputContainer?: string;
    input?: string;
    error?: string;
    icon?: string;
    label?: string;
  };
  className?: string;
  withErrorMessage?: boolean;
  checkAIContentQuality?: boolean;
  AISuggestionProblemArea?: IFeedbackArea;
  AISuggestionFieldName?: string;
  showOnlyCustomMessage?: boolean;
  customRef?: React.MutableRefObject<HTMLTextAreaElement | null>;
  name: string;
  label?: string;
  icon?: string;
  message?: React.ReactNode;
}

function TextareaField({
  classNames,
  name,
  className,
  label,
  withErrorMessage = true,
  showOnlyCustomMessage,
  checkAIContentQuality,
  AISuggestionProblemArea,
  AISuggestionFieldName,
  icon,
  message,
  ...rest
}: IProps) {
  const [field, form] = useField(name);
  const errorMessage = form.touched && form.error;
  const showError = withErrorMessage && !!errorMessage;
  const fieldIsTouched = !!form.touched;

  return (
    <div className={classnames(className, " flex flex-col  relative gap-1")}>
      <div className="flex flex-row justify-between">
        {label && (
          <Label className={rest.readOnly ? "font-bold " : ""} name={label} />
        )}
        {!fieldIsTouched && checkAIContentQuality && AISuggestionFieldName && (
          <FormikInputContentQuality
            name={name}
            label={label}
            suggestionFormikFieldName={AISuggestionFieldName}
            AISuggestionProblemArea={AISuggestionProblemArea}
            message={message}
          />
        )}
      </div>

      <div
        className={twMerge(
          classnames(classNames?.inputContainer, {
            "mb-5": !showError && !message && !rest.readOnly,
          })
        )}
      >
        {icon && (
          <div className={twMerge(classnames(classNames?.icon, " "))}>
            <img src={icon} alt="input-icon" />
          </div>
        )}
        <Textarea
          className={twMerge(
            classnames(
              classNames?.input,
              { "!px-0": rest.readOnly },
              "placeholder-gray-400 text-sm font-light"
            )
          )}
          {...field}
          {...rest}
          isError={showError}
          classNames={{ wrapper: "mt-1" }}
        />
      </div>
      {!showOnlyCustomMessage && (showError || message) && (
        <div
          className={twMerge(
            classnames(classNames?.error, "  font-light text-sm", {
              "text-red-400": showError,
            })
          )}
        >
          {errorMessage || message}
        </div>
      )}
      {showOnlyCustomMessage && message && (
        <div
          className={twMerge(
            classnames(classNames?.error, "  font-light text-sm")
          )}
        >
          {message}
        </div>
      )}
    </div>
  );
}

export default TextareaField;
