import { Formik } from "formik";
import { memo, useCallback, useRef } from "react";

import validationSchema from "./IssueForm.schema";
import { useInitialState, useSubmitHandler } from "./IssueForm.hooks";
import IssueForm, { IIssueFormProps } from "./IssueForm.component";
import { IValues } from "./IssueForm.types";
import { useIssueRatingCategories } from "hooks";

interface IProps extends Partial<IIssueFormProps> {
  onSuccess: (id?: string) => void;
}

function IssueFormContainer({
  className,
  onSuccess,
  onClose,
  showIssueFields = true,
  showRatings = true,
}: IProps) {
  const { data: categoriesData } = useIssueRatingCategories();
  const categories = categoriesData?.data?.map((i) => i.category);
  const similarIssuesRef = useRef<boolean>(false);
  const suggestionsRef = useRef<boolean>(false);

  const onCloseCallback = useCallback(() => {
    similarIssuesRef.current = false;
    suggestionsRef.current = false;
    if (onClose) {
      onClose();
    }
  }, [onClose]);

  return (
    <Formik<IValues>
      enableReinitialize
      initialValues={useInitialState()}
      validationSchema={validationSchema(
        { showIssueFields, showRatings },
        categories
      )}
      onSubmit={useSubmitHandler(onSuccess, {
        showIssueFields,
        showRatings,
        proceedWithSimilar: similarIssuesRef.current,
        proceedWithSuggestions: suggestionsRef.current,
      })}
    >
      <IssueForm
        className={className}
        onClose={onCloseCallback}
        similarIssuesRef={similarIssuesRef}
        suggestionsRef={suggestionsRef}
        showIssueFields={showIssueFields}
        showRatings={showRatings}
        categories={categoriesData?.data}
      />
    </Formik>
  );
}

export default memo(IssueFormContainer);
